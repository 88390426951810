import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// MUI Dark Theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#bb86fc",
    },
    background: {
      default: "#1e1e1e",
      paper: "#2c2c2c",
    },
    text: {
      primary: "#e0e0e0",
      secondary: "#aaaaaa",
    },
  },
});

// Commodity Tablosu Verisi
const commodityData = [
  { commodity: "LFISH", description: "Live Fish" },
  { commodity: "LAQUAFISH", description: "Live Aquarium Fish" },
  { commodity: "WORM", description: "Live Worm" },
  { commodity: "LARVA", description: "Larva" },
  { commodity: "LEECH", description: "Live Leech" },
  { commodity: "GSNAIL", description: "Grand Snail" },
  { commodity: "LCRAB", description: "Live Crab" },
  { commodity: "LCORAL", description: "Live Coral" },
  { commodity: "WSNAIL", description: "Water Snail" },
  { commodity: "WSNAKE", description: "Water Snakes" },
  { commodity: "INSECTS", description: "Live Insects" },
  { commodity: "MUSSEL", description: "Live Mussels" },
  { commodity: "SCORPION", description: "Live Scorpion" },
  { commodity: "SHRIMP", description: "Live Shrimps" },
];

// Uçuşlarda Kabul Edilmeyen Hayvan ve Nesneler
const bannedItems = `
Animals and objects not accepted on our flights include:
- Laboratory/Experimental animals
- Dead animals
- Live animals carrying infectious diseases
- Pregnant animals
- Kittens/puppies under ten weeks old
- Kittens/puppies not weaned from their mother's milk and nursing mothers
- All poisonous animals (e.g., some species of scorpions, frogs, snakes, spiders, etc.)
- Shark fins
- Any part, object, or residue (including hunting trophies and ivory, etc.) of live animals listed in Chapter 6 of the IATA Live Animals Regulations Book, in CITES List I

Banned Dog Breeds:
- American Staffordshire Terrier
- American Pit Bull Terrier
- Dogo Argentino
- Fila Brasileiro
- Japanese Tosa
- Pitbull Terrier
- American Bully
- Crossbreeds derived from the above dog breeds
`;

// Hayvan Taşıma Verisi
const animalData = [
  {
    species: "Chick",
    weightRange: "0.018-0.091",
    tempRange: "21-29",
    idealTemp: "-",
    mainDeckForward: "4,250",
    mainDeckAft: "4,450",
    lowerDeckForward: "1,600",
    lowerDeckAft: "1,100",
    bulkCarriage: "Not Carried",
  },
  {
    species: "Chicken",
    weightRange: "0.6-2",
    tempRange: "16-32",
    idealTemp: "25",
    mainDeckForward: "9,750",
    mainDeckAft: "10,300",
    lowerDeckForward: "3,650",
    lowerDeckAft: "1,800",
    bulkCarriage: "500",
  },
  {
    species: "Dog",
    weightRange: "0-68",
    tempRange: "10-29",
    idealTemp: "-",
    mainDeckForward: "10,230",
    mainDeckAft: "10,550",
    lowerDeckForward: "5,550",
    lowerDeckAft: "1,450",
    bulkCarriage: "400",
  },
  {
    species: "Monkey",
    weightRange: "0-35",
    tempRange: "18-35",
    idealTemp: "-",
    mainDeckForward: "10,250",
    mainDeckAft: "10,550",
    lowerDeckForward: "5,550",
    lowerDeckAft: "1,450",
    bulkCarriage: "400",
  },
  {
    species: "Little Birds",
    weightRange: "0.02-0.15",
    tempRange: "18-35",
    idealTemp: "-",
    mainDeckForward: "4,250",
    mainDeckAft: "4,450",
    lowerDeckForward: "1,600",
    lowerDeckAft: "1,100",
    bulkCarriage: "650",
  },
  {
    species: "Large Bird",
    weightRange: "0.16-5",
    tempRange: "18-35",
    idealTemp: "-",
    mainDeckForward: "13,200",
    mainDeckAft: "13,600",
    lowerDeckForward: "5,900",
    lowerDeckAft: "1,900",
    bulkCarriage: "500",
  },
  {
    species: "Cat",
    weightRange: "0-10",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "10,250",
    mainDeckAft: "10,550",
    lowerDeckForward: "5,550",
    lowerDeckAft: "1,450",
    bulkCarriage: "400",
  },
  {
    species: "Horse",
    weightRange: "91-454",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "24,950",
    mainDeckAft: "25,850",
    lowerDeckForward: "9,000",
    lowerDeckAft: "3,600",
    bulkCarriage: "Not Carried",
  },
  {
    species: "Other Animals (0-5 kg)",
    weightRange: "0-5",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "10,250",
    mainDeckAft: "10,550",
    lowerDeckForward: "5,550",
    lowerDeckAft: "1,450",
    bulkCarriage: "400",
  },
  {
    species: "Other Animals (6-15 kg)",
    weightRange: "6-15",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "9,800",
    mainDeckAft: "10,350",
    lowerDeckForward: "3,650",
    lowerDeckAft: "1,900",
    bulkCarriage: "500",
  },
  {
    species: "Other Animals (16-50 kg)",
    weightRange: "16-50",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "12,000",
    mainDeckAft: "12,750",
    lowerDeckForward: "4,450",
    lowerDeckAft: "1,800",
    bulkCarriage: "500",
  },
  {
    species: "Other Animals (51-100 kg)",
    weightRange: "51-100",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "15,300",
    mainDeckAft: "16,200",
    lowerDeckForward: "5,700",
    lowerDeckAft: "2,400",
    bulkCarriage: "Not Carried",
  },
  {
    species: "Other Animals (101-200 kg)",
    weightRange: "101-200",
    tempRange: "10-27",
    idealTemp: "-",
    mainDeckForward: "25,200",
    mainDeckAft: "26,200",
    lowerDeckForward: "9,400",
    lowerDeckAft: "3,600",
    bulkCarriage: "Not Carried",
  },
];

const AnimalCards = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Airbus 330-200F Animal Transport Information
        </Typography>

        {/* Hayvan Kartları */}
        <Grid container spacing={2}>
          {animalData.map((animal, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {animal.species}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Weight Range:</strong> {animal.weightRange} kg
                  </Typography>
                  <Typography variant="body2">
                    <strong>Temperature Range:</strong> {animal.tempRange} °C
                  </Typography>
                  <Typography variant="body2">
                    <strong>Ideal Temperature:</strong> {animal.idealTemp} °C
                  </Typography>
                  <Typography variant="body2">
                    <strong>Main Deck (Forward):</strong> {animal.mainDeckForward} kg
                  </Typography>
                  <Typography variant="body2">
                    <strong>Main Deck (Aft):</strong> {animal.mainDeckAft} kg
                  </Typography>
                  <Typography variant="body2">
                    <strong>Lower Deck (Forward):</strong> {animal.lowerDeckForward} kg
                  </Typography>
                  <Typography variant="body2">
                    <strong>Lower Deck (Aft):</strong> {animal.lowerDeckAft} kg
                  </Typography>
                  <Typography variant="body2">
                    <strong>Bulk Carriage:</strong> {animal.bulkCarriage}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Commodity Tablosu */}
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              No Ventilation Condition and Limit-Free Loadable Commodities
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Commodity</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commodityData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.commodity}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>

        {/* Kabul Edilmeyen Hayvanlar ve Nesneler */}
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Animals and Objects Not Accepted on Our Flights
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {bannedItems}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AnimalCards;
