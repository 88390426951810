import React, { useState, useEffect } from 'react';
import DataDisplay from '../components/Floating330_Display';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Cal330 = () => {
  const [weight, setWeight] = useState(''); // Palet ağırlığı
  const [maxKg, setMaxKg] = useState(11340); // Maksimum kilo
  const [selectedPosition, setSelectedPosition] = useState(null); // Seçilen pozisyon
  const [tableData, setTableData] = useState([]); // Hesaplanan tablo verileri
  const [highlightedRow, setHighlightedRow] = useState(null); // En uygun satır

  const MySwal = withReactContent(Swal); // Swal için kullanılacak

  // Yeni pozisyon seçildiğinde eski sonuçları ve ağırlığı temizlemek
  const handleBoxClick = (item) => {
    setMaxKg(item.maxkg);
    setSelectedPosition(item);
    setWeight(''); // Yeni pozisyon seçildiğinde eski ağırlığı temizle
    setHighlightedRow(null); // Sonuçları temizle

    // Swal popup for weight entry
    MySwal.fire({
      title: `Enter the pallet weight (max ${item.maxkg} Kg for ${item.position_name})`,
      input: 'number',
      inputAttributes: {
        min: 0,
        max: item.maxkg,
        step: 1
      },
      background: '#1e1e1e', // Dark mode background
      color: '#ffffff',      // White text color for dark mode
      showCancelButton: true,
      confirmButtonText: 'Submit',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter a weight!';
        } else if (value > item.maxkg) {
          return `Maximum weight is ${item.maxkg} kg!`;
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setWeight(result.value); // Kilo girişini kaydet
      }
    });
  };

  // Ağırlık değiştiğinde tabloyu güncelle
  useEffect(() => {
    const updateTable = () => {
      if (weight) {
        const table = [
          { frame: 'C20-C38', harm: '14.459-25.059', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 0.9) / 994), rightSide: Math.ceil((weight * 0.9) / 994), upSide: Math.ceil((weight * 1.5) / 3568) },
          { frame: 'C38-C53.3', harm: '25.059-37.268', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 0.9) / 994), rightSide: Math.ceil((weight * 0.9) / 994), upSide: Math.ceil((weight * 1.6) / 3568) },
          { frame: 'C53.3-C54', harm: '37.268-40.978', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.1) / 994), rightSide: Math.ceil((weight * 1.1) / 994), upSide: Math.ceil((weight * 1.8) / 3568) },
          { frame: 'C54-C56', harm: '40.978-42.038', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.1) / 994), rightSide: Math.ceil((weight * 1.1) / 994), upSide: Math.ceil((weight * 1.9) / 3568) },
          { frame: 'C56-C70', harm: '42.038-49.458', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.7) / 994), rightSide: Math.ceil((weight * 1.7) / 994), upSide: Math.ceil((weight * 2.7) / 3568) },
          { frame: 'C70-C79', harm: '49.458-54.684', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.9) / 994), rightSide: Math.ceil((weight * 1.9) / 994), upSide: Math.ceil((weight * 3.3) / 3568) },
          { frame: 'BULK', harm: '-', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil((weight * 1.5) / 2752), leftSide: Math.ceil((weight * 1.4) / 994), rightSide: Math.ceil((weight * 1.4) / 994), upSide: Math.ceil((weight * 3.4) / 3568) },
        ];

        setTableData(table);
      } else {
        setTableData([]); // Eğer ağırlık boşsa tabloyu temizle
      }
    };

    updateTable();
  }, [weight]);

  // State güncellendikten sonra sonucu göstermek için `useEffect`
  useEffect(() => {
    if (weight && selectedPosition && highlightedRow !== null) {
      showResultPopup(); // İlk denemede gösterim sağlamak için weight güncellenir güncellenmez modal açılır
    }
  }, [weight, selectedPosition, highlightedRow]); // weight, selectedPosition veya highlightedRow değiştiğinde çalışır

  useEffect(() => {
    if (selectedPosition) {
      const getOverlap = (rangeStart, rangeEnd, posStart, posEnd) => {
        const overlapStart = Math.max(rangeStart, posStart);
        const overlapEnd = Math.min(rangeEnd, posEnd);
        return Math.max(0, overlapEnd - overlapStart);
      };

      const [selectedStart, selectedEnd] = [selectedPosition.harmstart, selectedPosition.harmend];
      let maxOverlap = 0;
      let bestRow = null;

      tableData.forEach((row, index) => {
        const [rowStart, rowEnd] = row.harm.split('-').map(Number);
        const overlap = getOverlap(rowStart, rowEnd, selectedStart, selectedEnd);
        if (overlap > maxOverlap || (overlap === maxOverlap && selectedStart >= rowStart && selectedEnd <= rowEnd)) {
          maxOverlap = overlap;
          bestRow = index;
        }
      });

      setHighlightedRow(bestRow);
    }
  }, [selectedPosition, tableData]);

  const showResultPopup = () => {
    if (highlightedRow !== null && tableData.length > 0) {
        const selectedRow = tableData[highlightedRow];
        const totalLoad = selectedRow.forward + selectedRow.aft + selectedRow.leftSide + selectedRow.rightSide + selectedRow.upSide;

        MySwal.fire({
            title: 'Calculation Result',
            html: `
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 15px; padding: 10px;">
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Frame</h4>
                        <p>${selectedRow.frame}</p>
                    </div>
            
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Forward Load (FX)</h4>
                        <p>${selectedRow.forward}</p>
                    </div>
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Aft Load (FX)</h4>
                        <p>${selectedRow.aft}</p>
                    </div>
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Left Side Load (FY)</h4>
                        <p>${selectedRow.leftSide}</p>
                    </div>
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Right Side Load (FY)</h4>
                        <p>${selectedRow.rightSide}</p>
                    </div>
                    <div class="card" style="background: linear-gradient(135deg, #333, #444); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Up Side Load (FZ)</h4>
                        <p>${selectedRow.upSide}</p>
                    </div>
                    <div class="card" style="grid-column: span 3; background: linear-gradient(135deg, #444, #555); color: #fff; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);">
                        <h4>Total</h4>
                        <p>${totalLoad}</p>
                    </div>
                </div>
            `,
            background: '#1e1e1e', // Dark mode background
            color: '#ffffff',      // White text color for dark mode
            confirmButtonText: 'Close',
        });
    }
};

  return (
    <div className="table-page segments-page">
      <div className="container">
        <div className="wrap-title">
          <div className="contents left"></div>
        </div>
        <div className="wrap-content b-shadow table-responsive">
        <p style={{ textAlign: 'center' }}><strong>Select Pallet Position</strong></p>

          <DataDisplay onBoxClick={handleBoxClick} />
          <br /><br />
        </div>
      </div>
    </div>
  );
};

export default Cal330;
