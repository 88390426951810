// src/components/UNSearchComponent.js

import React, { useState } from 'react';
import { dgr } from '../datas/DgrData'; // DgrData.js dosyasını içe aktarıyoruz
import {
  Box,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search'; // Search ikonunu içe aktarıyoruz

const UNSearchComponent = () => {
  // State yönetimi ile arama girdisi, arama sonuçları ve arama işlemi durumu saklanıyor
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false); // Arama işlemi yapılıp yapılmadığını kontrol eder

  // MUI tema ve media query kullanımı
  const theme = useTheme();


  // Arama işlemi için kullanılan fonksiyon
  const handleSearch = () => {
    const results = dgr.filter((item) => item["UN No"] && item["UN No"].toString() === searchInput);

    // IMP1 ve IMP2 değerlerini birleştir
    const mergedResults = results.map((item) => {
      if (item["IMP2"] && item["IMP2"] !== 'null') {
        return {
          ...item,
          "IMP1": item["IMP1"] ? `${item["IMP1"]}, ${item["IMP2"]}` : item["IMP2"],
        };
      }
      return item;
    });

    setSearchResults(mergedResults);
    setSearchPerformed(true); // Arama yapıldı olarak durumu güncelle
  };

  // Girdi değişikliği kontrolü
  const handleInputChange = (event) => {
    const { value } = event.target;
    // Sadece 0-9 arasındaki rakamlar ve maksimum 4 karakter
    if (/^\d{0,4}$/.test(value)) {
      setSearchInput(value);
    }
  };

  // Gizlemek istediğimiz sütunlar
  const hiddenColumns = [
    "SORT", "RevisionFlag", "S.P.", "ProperShipName", "DESC", "TechNameflag", 
    "AppAFlag", "REF", "NOS", "Hazard Label", "Pkg Inst", "Pkg Inst2", "EQ", 
    "ERG Code", "IMP2", "Ltd Qty PG", "Ltd Max Net Qty"
  ];

  // Başlık değişikliklerini tanımlayan nesne
  const columnHeaderMappings = {
    "Max Net Qty": "Passenger Aircraft Max.",
    "Max Net": "Cargo Aircraft Max.",
    "IMP1" : "SCC",
    "PSN  Desc" : "Desc.",
    "PG" :  "Packaging Group",
    "UN No": "UN Number"

  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, bgcolor: '#1e1e1e', borderRadius: 2, p: 3, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ bgcolor: '#2e2e2e', color: 'white', borderRadius: 2, mb: 4, p: 3 }}>
     
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Enter UN Number"
              value={searchInput}
              onChange={handleInputChange} // Girdi değişikliği kontrolü ekleniyor
              inputProps={{ maxLength: 4 }} // Maksimum 4 karakter
              sx={{ bgcolor: '#424242', borderRadius: 1, input: { color: 'white' }, label: { color: 'white' } }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={handleSearch}
              startIcon={<SearchIcon />} // Search ikonu ekliyoruz
              sx={{
                height: '100%',
                borderRadius: 2,
                fontWeight: 'bold',
                bgcolor: '#66bb6a',
                ':hover': { bgcolor: '#388e3c' },
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                fontSize: '16px',
                textTransform: 'none',
                // Buton disabled (devre dışı) durumdayken stil
                '&.Mui-disabled': {
                  bgcolor: '#616161', // Gri tonlarında bir arka plan rengi
                  color: '#e0e0e0', // Açık gri metin rengi
                },
              }}
              disabled={searchInput.length !== 4} // 4 hane değilse butonu devre dışı bırak
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Kart Yapısı ile Bilgilerin Gösterimi */}
      {searchPerformed && searchResults.length === 0 ? (
        <Typography variant="h6" sx={{ mt: 4, textAlign: 'center', color: '#4caf50', fontStyle: 'italic' }}>
          No matching results found. Please try a different UN No.
        </Typography>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {searchResults.map((result, index) => (
            <Grid item xs={12} md={6} sx={{ mb: index === searchResults.length - 1 ? 6 : 2 }} key={index}>
              <Card sx={{ bgcolor: '#2e2e2e', color: 'white', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' }}>
                <CardContent>
                  {Object.entries(result)
                    .filter(([key]) => !hiddenColumns.includes(key)) // Gizlemek istemediğimiz sütunları hariç tut
                    .map(([key, value], i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: '1px solid #424242',
                          p: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: 
                              key === "Max Net Qty" ? 
                              (value === "Forbidden" ? '#9e9e9e' : '#4caf50') : 
                              '#4caf50',
                          }}
                        >
                          {columnHeaderMappings[key] ? columnHeaderMappings[key] : key}
                        </Typography>
                        <Typography
                          sx={{
                            color: value === "Forbidden" ? 'red' : 'inherit',
                            fontWeight: value === "Forbidden" ? 'bold' : 'normal',
                          }}
                        >
                          {value !== null && value !== 'null' ? value.toString() : '-'}
                        </Typography>
                      </Box>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default UNSearchComponent;
