import React, { useState, useEffect, useRef } from 'react';
import KilitMd from '../components/KilitMd';
import Swal from 'sweetalert2';

const AllForms = () => {
  const [weight, setWeight] = useState('');
  const [maxKg, setMaxKg] = useState(11340);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const weightInputRef = useRef(null);

  useEffect(() => {
    const updateTable = () => {
      if (weight) {
        const table = [
          { frame: 'C20-C38', harm: '14.459-25.059', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 0.9) / 994), rightSide: Math.ceil((weight * 0.9) / 994), upSide: Math.ceil((weight * 1.5) / 3568) },
          { frame: 'C38-C53.3', harm: '25.059-37.268', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 0.9) / 994), rightSide: Math.ceil((weight * 0.9) / 994), upSide: Math.ceil((weight * 1.6) / 3568) },
          { frame: 'C53.3-C54', harm: '37.268-40.978', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.1) / 994), rightSide: Math.ceil((weight * 1.1) / 994), upSide: Math.ceil((weight * 1.8) / 3568) },
          { frame: 'C54-C56', harm: '40.978-42.038', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.1) / 994), rightSide: Math.ceil((weight * 1.1) / 994), upSide: Math.ceil((weight * 1.9) / 3568) },
          { frame: 'C56-C70', harm: '42.038-49.458', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.7) / 994), rightSide: Math.ceil((weight * 1.7) / 994), upSide: Math.ceil((weight * 2.7) / 3568) },
          { frame: 'C70-C79', harm: '49.458-54.684', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil(weight / 2752), leftSide: Math.ceil((weight * 1.9) / 994), rightSide: Math.ceil((weight * 1.9) / 994), upSide: Math.ceil((weight * 3.3) / 3568) },
          { frame: 'BULK', harm: '-', forward: Math.ceil((weight * 1.5) / 2752), aft: Math.ceil((weight * 1.5) / 2752), leftSide: Math.ceil((weight * 1.4) / 994), rightSide: Math.ceil((weight * 1.4) / 994), upSide: Math.ceil((weight * 3.4) / 3568) },
        ];

        setTableData(table);
      } else {
        setTableData([]);
      }
    };

    updateTable();
  }, [weight]);

  useEffect(() => {
    if (selectedPosition) {
      const getOverlap = (rangeStart, rangeEnd, posStart, posEnd) => {
        const overlapStart = Math.max(rangeStart, posStart);
        const overlapEnd = Math.min(rangeEnd, posEnd);
        return Math.max(0, overlapEnd - overlapStart);
      };

      const [selectedStart, selectedEnd] = [selectedPosition.harmstart, selectedPosition.harmend];
      let maxOverlap = 0;
      let bestRow = null;

      tableData.forEach((row, index) => {
        const [rowStart, rowEnd] = row.harm.split('-').map(Number);
        const overlap = getOverlap(rowStart, rowEnd, selectedStart, selectedEnd);
        if (overlap > maxOverlap || (overlap === maxOverlap && selectedStart >= rowStart && selectedEnd <= rowEnd)) {
          maxOverlap = overlap;
          bestRow = index;
        }
      });

      setHighlightedRow(bestRow);
    }
  }, [selectedPosition, tableData]);

  const handleBoxClick = (item) => {
    setMaxKg(item.maxkg);
    setSelectedPosition(item);
    setWeight(''); // Clear weight input when a new box is selected

    setTimeout(() => {
      
    }, 0);
};

  return (
    <div>
      <div className="table-page segments-page">
        <div className="container">
          <div className="wrap-title">
            <div className="contents left"></div>
          </div>
          <div className="wrap-content b-shadow table-responsive">
           
            <div>
            
              <KilitMd onBoxClick={handleBoxClick} />
        
              
              <br /><br />
              <br /><br />
              <br /><br />
           
            </div>
        <br />
            

          </div>
        </div>
      </div>
    </div>
  );
};

export default AllForms;
