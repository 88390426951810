import React, { useState,useEffect } from 'react';
import MobileDetect from 'mobile-detect';


const darkTheme = {
    backgroundColor: '#121212',
    color: '#ffffff',
    borderColor: '#333333',
    transition: 'all 0.3s ease-in-out',
};

const data1 = [
    { id: 1, position_name: 'A', maxkg: 2826, harms: 0, harme: 223.4, harmstart: 14.648, harmend: 16.883 },
    { id: 2, position_name: 'B', maxkg: 3123, harms: 226, harme: 449.6, harmstart: 16.908, harmend: 19.144 },
    { id: 3, position_name: 'C', maxkg: 3391, harms: 452.1, harme: 675.6, harmstart: 19.169, harmend: 21.404 },
    { id: 4, position_name: 'D', maxkg: 3391, harms: 678.2, harme: 901.7, harmstart: 21.430, harmend: 23.665 },
    { id: 5, position_name: 'E', maxkg: 4687, harms: 904.2, harme: 1127.7, harmstart: 23.690, harmend: 25.925 },
    { id: 6, position_name: 'F', maxkg: 6033, harms: 1130.3, harme: 1353.8, harmstart: 25.951, harmend: 28.186 },
    { id: 7, position_name: 'G', maxkg: 6033, harms: 1356.3, harme: 1579.9, harmstart: 28.211, harmend: 30.447 },
    { id: 8, position_name: 'H', maxkg: 6033, harms: 1582.4, harme: 1805.9, harmstart: 30.472, harmend: 32.707 },
    { id: 9, position_name: 'J', maxkg: 6033, harms: 1808.5, harme: 2032, harmstart: 32.733, harmend: 34.968 },
    { id: 10, position_name: 'K', maxkg: 5945, harms: 2034.5, harme: 2258, harmstart: 34.993, harmend: 37.228 },
    { id: 11, position_name: 'L', maxkg: 4037, harms: 2260.6, harme: 2484.1, harmstart: 37.254, harmend: 39.489 },
    { id: 12, position_name: 'M', maxkg: 4037, harms: 2486.6, harme: 2710.2, harmstart: 39.514, harmend: 41.750 },
    { id: 13, position_name: 'P', maxkg: 3725, harms: 2712.7, harme: 2936.2, harmstart: 41.775, harmend: 44.010 },
    { id: 14, position_name: 'R', maxkg: 3714, harms: 2979.4, harme: 3202.9, harmstart: 44.042, harmend: 46.677 },
    { id: 15, position_name: 'S', maxkg: 3714, harms: 3225.8, harme: 3449.3, harmstart: 46.703, harmend: 49.141 },
    { id: 16, position_name: 'T', maxkg: 3059, harms: 3472.2, harme: 3695.7, harmstart: 49.166, harmend: 51.605 },
    { id: 17, position_name: 'U', maxkg: 2541, harms: 3718.5, harme: 3942.1, harmstart: 51.630, harmend: 54.069 }
];

const data2 = [
    { id: 18, position_name: 'AA', maxkg: 3080, harms: 0, harme: 243.8, harmstart: 14.648, harmend: 17.086 },
    { id: 19, position_name: 'BB', maxkg: 3428, harms: 246.4, harme: 490.2, harmstart: 17.112, harmend: 19.550 },
    { id: 20, position_name: 'CC', maxkg: 3696, harms: 492.7, harme: 736.6, harmstart: 19.575, harmend: 22.014 },
    { id: 21, position_name: 'DD', maxkg: 3696, harms: 739.1, harme: 983, harmstart: 22.039, harmend: 24.478 },
    { id: 22, position_name: 'EE', maxkg: 5670, harms: 985.5, harme: 1229.3, harmstart: 24.503, harmend: 26.941 },
    { id: 23, position_name: 'FF', maxkg: 5670, harms: 1231.9, harme: 1475.7, harmstart: 26.966, harmend: 29.403 },
    { id: 24, position_name: 'GG', maxkg: 5670, harms: 1478.3, harme: 1722.1, harmstart: 29.431, harmend: 31.869 },
    { id: 25, position_name: 'HH', maxkg: 5670, harms: 1724.6, harme: 1968.5, harmstart: 31.894, harmend: 34.332 },
    { id: 26, position_name: 'JJ', maxkg: 5670, harms: 1971, harme: 2214.9, harmstart: 34.358, harmend: 36.797 },
    { id: 27, position_name: 'KK', maxkg: 4822, harms: 2219.9, harme: 2463.8, harmstart: 36.847, harmend: 39.286 },
    { id: 28, position_name: 'LL', maxkg: 4400, harms: 2466.3, harme: 2710.2, harmstart: 39.311, harmend: 41.750 },
    { id: 29, position_name: 'MM', maxkg: 4091, harms: 2712.7, harme: 2956.5, harmstart: 41.775, harmend: 44.213 },
    { id: 30, position_name: 'PP', maxkg: 4048, harms: 2959.1, harme: 3202.9, harmstart: 44.239, harmend: 46.677 },
    { id: 31, position_name: 'RR', maxkg: 4048, harms: 3205.5, harme: 3449.3, harmstart: 46.703, harmend: 49.141 },
    { id: 32, position_name: 'SS', maxkg: 3389, harms: 3451.8, harme: 3695.7, harmstart: 49.166, harmend: 51.605 },
    { id: 33, position_name: 'TT', maxkg: 2809, harms: 3698.2, harme: 3942.1, harmstart: 51.630, harmend: 54.069 }
];

const data3 = [
    { id: 34, position_name: 'AB', maxkg: 3429, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823 },
    { id: 35, position_name: 'BC', maxkg: 3941, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023 },
    { id: 36, position_name: 'CE', maxkg: 4001, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224 },
    { id: 37, position_name: 'EF', maxkg: 6804, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424 },
    { id: 38, position_name: 'FH', maxkg: 6804, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624 },
    { id: 39, position_name: 'HJ', maxkg: 6804, harms: 1600.2, harme: 1917.7, harmstart: 30.650, harmend: 33.825 },
    { id: 40, position_name: 'JK', maxkg: 6804, harms: 1920.2, harme: 2237.7, harmstart: 33.850, harmend: 37.025 },
    { id: 41, position_name: 'KM', maxkg: 4868, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226 },
    { id: 42, position_name: 'MP', maxkg: 4445, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451 }
];

const data4 = [
    { id: 43, position_name: 'CFR', maxkg: 7729, harms: 647.1, harme: 1144.9, harmstart: 21.119, harmend: 26.097 },
    { id: 44, position_name: 'FJR', maxkg: 10668, harms: 1265.5, harme: 1763.4, harmstart: 27.303, harmend: 32.282 },
    { id: 45, position_name: 'JLR', maxkg: 10668, harms: 1776.8, harme: 2274.6, harmstart: 32.416, harmend: 37.394 },
    { id: 46, position_name: 'LPR', maxkg: 6236, harms: 2287.9, harme: 2785.7, harmstart: 37.527, harmend: 42.505 }
];

const data5 = [
    { id: 47, position_name: 'CFG', maxkg: 10602, harms: 644.1, harme: 1250, harmstart: 21.089, harmend: 27.148 },
    { id: 48, position_name: 'FJG', maxkg: 11340, harms: 1262.7, harme: 1868.5, harmstart: 27.275, harmend: 33.333 },
    { id: 49, position_name: 'JLG', maxkg: 11340, harms: 1881.1, harme: 2486.9, harmstart: 33.459, harmend: 39.517 }
];



const containerWidth = 1000; // Set the container width in pixels

const DataDisplay = ({ onBoxClick }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
const [screenHeight, setScreenHeight] = useState(window.innerHeight);
const [leftScale, setLeftScale] = useState(1);
const [widthScale, setWidthScale] = useState(1);
const myScale = 1.76; // Sabit ölçek değeri
const myScaleh = 1.2; // Sabit ölçek değeri
const myScaleh2 = 1.2; // Sabit ölçek değeri
const heightScale2 = screenHeight / 1080; 
const heightScale = screenHeight / 1080; 


const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
const md = new MobileDetect(userAgent);

// Cihaz türünü kontrol etme
//const isMobile = md.mobile(); // Mobil cihaz mı? (Telefon veya Tablet)
const isPhone = md.phone();   // Sadece telefon mu?
//const isTablet = md.tablet();  // Sadece tablet mi?
//const isDesktop = !isMobile;   // Masaüstü cihaz mı?
//console.log(isPhone);


useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
  
      // Ekran genişliğine ve yüksekliğine göre dinamik ölçekleme yapıyoruz
      setLeftScale(window.innerWidth / 1920);
      setWidthScale(window.innerWidth / 1920);
    };
  
    // Sayfa ilk açıldığında handleResize'i çağırıyoruz
    handleResize();
  
    // `resize` event listener'ı ekleyip kaldırma
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    // Combine all data arrays to find the maximum harme value
    const allData = [...data1];
    const maxHarme = Math.max(...allData.map(item => item.harme));
    const maxHarms = Math.max(...allData.map(item => item.harms));

    
    // Recalculate scale based on the updated maxHarme and maxHarms values
    const scaleHarme = containerWidth / maxHarme;
    const scaleHarms = containerWidth / maxHarms;

    const getBoxStyle = (item) => {
        let backgroundColor = '#333333';
        if (selectedPosition && selectedPosition.id === item.id) {
            backgroundColor = '#84ab20';
        } else if ([].includes(item.position_name)) {
           backgroundColor = '#333333'; // Varsayılan arka plan rengi
        } else if (['R', 'S', 'T', 'U', 'PP', 'RR', 'SS', 'TT'].includes(item.position_name)) {
            backgroundColor = '#000000';
        }

        const mobileHeightScale = screenHeight / 667; // Örnek bir ölçek değeri, temel genişliği 375px olarak kabul ediyoruz
    const mobileScale  = screenWidth / 375; // Örnek bir yükseklik ölçeği, temel yüksekliği 667px olarak kabul ediyoruz
    const mytScale = 2.4;

    let borderColor = '#666666'; // Varsayılan çerçeve rengi
    let textColor = '#ffffff'; // Varsayılan yazı rengi
    let zIndex = 1; // Varsayılan z-index değeri
    if (isPhone !== null) {
        const mobileHeightScale = screenHeight / 667; // Örnek bir ölçek değeri, temel genişliği 375px olarak kabul ediyoruz
        const mobileScale  = screenWidth / 375; // Örnek bir yükseklik ölçeği, temel yüksekliği 667px olarak kabul ediyoruz
        const mytScale = 2.4;
           // top: `${item.harms * scaleHarms * mobileHeightScale}px`, // Ekran yüksekliğine göre dinamik top değeri
        //left: `${item.harme * scaleHarme * mobileScale}px`, // Ekran genişliğine göre dinamik left değeri
        //width: `${(item.harme - item.harms) * scaleHarme * mobileScale}px`, // Dinamik width değeri
        //height: `${(item.heightx) * scaleHarms * mobileHeightScale}px`, // Dinamik height değeri
    
        
        return {
          ...darkTheme,
          position: 'absolute',
          top: `${item.harms * scaleHarms * mobileHeightScale/ mytScale}px`,
          left: `${item.top}`,
          height:  `${(item.harme - item.harms) * scaleHarme * mobileScale / mytScale}px`,
          width: `${(item.heightx) * scaleHarms * mobileHeightScale/mytScale}px`,
          textAlign: 'center',
          border: `2px solid ${borderColor}`,
          minWidth: '64px',
          padding: '5px',
          boxSizing: 'border-box',
          backgroundColor,
          color: textColor,
          transition: 'all 0.3s ease-in-out',
          fontSize: '14px', // `isPhone` için fontSize 14px olarak ayarlandı
          fontWeight: '600',
          fontFamily: 'Roboto, Arial, sans-serif',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex,
        };
      }
    
      // Desktop
      return {
        ...darkTheme,
        position: 'absolute',
        top: item.top,
        left: `${(item.harms * scaleHarms * leftScale * myScale)+20}px`, // Ekran genişliğine göre ölçeklendirilmiş left değeri
        width: `${(item.harme - item.harms) * scaleHarme * widthScale * myScale}px`, // Ekran genişliğine göre ölçeklendirilmiş width değeri
        height: `${(item.heightx) * scaleHarms * heightScale * myScaleh}px`, // heightScale ve diğer ölçek faktörleri kullanılarak hesaplanır.
        textAlign: 'center',
        border: `2px solid ${borderColor}`,
        boxSizing: 'border-box',
        padding: '10px',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor, // Set background color based on position_name
        zIndex,
      };
    
        };

    const handleBoxClick = (item) => {
        // Check if the position is in the restricted lists
        if (
            ['R', 'S', 'T', 'U', 'PP', 'RR', 'SS', 'TT'].includes(item.position_name)
        ) {
            return; // Do nothing if the position is restricted
        }

        setSelectedPosition(item);
        onBoxClick(item);
    };
    const baseHeightScale = screenWidth / 375 /2; // Ekran yüksekliğine göre dinamik bir ölçek oluşturuyoruz (örnek olarak 667px referans alındı)
const baseWidthScale = screenWidth / 375 /2;   // Ekran genişliğine göre dinamik bir ölçek oluşturuyoruz (örnek olarak 375px referans alındı)


const baseTop1 = isPhone ? 25 * baseHeightScale : 15 * myScaleh2 * heightScale2;
const baseTop2 = isPhone ? 165 * baseHeightScale : 110 * myScaleh2 * heightScale2;
const baseTop3 = isPhone ? 305 * baseHeightScale : 205 * myScaleh2 * heightScale2;
const baseTop4 = isPhone ? 435 * baseHeightScale : 310 * myScaleh2 * heightScale2;
const baseTop5 = isPhone ? 565 * baseHeightScale : 430 * myScaleh2 * heightScale2;
const baseTop6 = isPhone ? 520 * baseHeightScale : 560 * myScaleh2 * heightScale2;
const baseTop7 = isPhone ? 625 * baseHeightScale : 640 * myScaleh2 * heightScale2;
   

    return (
        <div style={{ position: 'relative', height: '400px' }}>
            {data1.map(item => (
                <div
                    key={item.id}
                    style={getBoxStyle({ ...item, top:  `${baseTop1}px`, })}
                    onClick={() => handleBoxClick(item)}
                >
                    <p><strong>{item.position_name}</strong></p>
                </div>
            ))}
            {data2.map(item => (
                <div
                    key={item.id}
                    style={getBoxStyle({ ...item, top:  `${baseTop2}px`, })}
                    onClick={() => handleBoxClick(item)}
                >
                    <p><strong>{item.position_name}</strong></p>
                </div>
            ))}
            {data3.map(item => (
                <div
                    key={item.id}
                    style={getBoxStyle({ ...item, top:  `${baseTop3}px`, })}
                    onClick={() => handleBoxClick(item)}
                >
                    <p><strong>{item.position_name}</strong></p>
                </div>
            ))}
            {data4.map(item => (
                <div
                    key={item.id}
                    style={getBoxStyle({ ...item, top:  `${baseTop4}px`, })}
                    onClick={() => handleBoxClick(item)}
                >
                    <p><strong>{item.position_name}</strong></p>
                </div>
            ))}
            {data5.map(item => (
                <div
                    key={item.id}
                    style={getBoxStyle({ ...item, top:  `${baseTop5}px`, })}
                    onClick={() => handleBoxClick(item)}
                >
                    <p><strong>{item.position_name}</strong></p>
                </div>
            ))}

<div style={{ position: 'fixed', bottom: '80px', right: '20px', width: '400px', height: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '10px', borderRadius: '10px', color: '#fff' }}>
<p style={{ margin: 0 }}>
        <span style={{ backgroundColor: '#000', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle', border: '2px solid white' }}></span>
        Lack of tie down points!
    </p>
    <p style={{ margin: 0 }}>
        <span style={{ backgroundColor: '#84ab20', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }}></span>
        Selected Position
    </p>
</div>
        </div>
    );
};

export default DataDisplay;