import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function Footer() {
  const [value, setValue] = useState(0); // BottomNavigation for state

  // Theme settings
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0D47A1', // Primary color (constant blue for the bottom nav bar)
      },
      secondary: {
        main: '#FFC107', // Secondary color (active icon color - Yellow)
      },
      text: {
        primary: '#ffffff', // Default text color (White)
      },
    },
    typography: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 14, // Default font size
    },
  });

  return (
    <ThemeProvider theme={theme}>
     <Box 
  sx={{ 
    width: '100%', 
    position: 'fixed', 
    bottom: 0, 
    left: 0, 
    zIndex: 1300, 
    marginTop: '20px',  /* Creates space between footer and content above */
    height: '60px', /* Ensure fixed height for the footer */
  }}
>
        {/* Bottom Navigation Bar */}
        <BottomNavigation
          sx={{
            background: 'linear-gradient(45deg, #0D0D0D, #2B2B2B)', // Gradient background
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.2)', // Slight shadow effect
            zIndex: 1300, // z-index for top visibility
          }}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          {/* Home Icon */}
          <BottomNavigationAction
            label="Home"
            icon={<RestoreIcon sx={{ fontSize: 28 }} />} // Icon size increased
            sx={{
              '&.Mui-selected': {
                color: '#1e88e5', // Selected state blue color
              },
              color: theme.palette.text.primary, // Default color (White)
            }}
          />

          {/* Favorites Icon */}
          <BottomNavigationAction
            label="Favorites"
            icon={<FavoriteIcon sx={{ fontSize: 28 }} />} // Icon size increased
            sx={{
              '&.Mui-selected': {
                color: theme.palette.secondary.main, // Active icon and text color (Yellow)
              },
              color: theme.palette.text.primary, // Default color (White)
            }}
          />

          {/* Profile Icon */}
          <BottomNavigationAction
            label="Profile"
            icon={<LocationOnIcon sx={{ fontSize: 28 }} />} // Icon size increased
            sx={{
              '&.Mui-selected': {
                color: theme.palette.secondary.main, // Active icon and text color (Yellow)
              },
              color: theme.palette.text.primary, // Default color (White)
            }}
          />
        </BottomNavigation>
      </Box>
    </ThemeProvider>
  );
}

export default Footer;
