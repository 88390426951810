import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Paper, Box } from '@mui/material';
import { Height, WidthFull, Straighten, Balance, Info } from '@mui/icons-material';
import { data1, data2, data3, data4, data5, data6, data7 } from '../datas/kilitData';
import { data30 } from '../datas/lenghtData';

const CalculationTool = () => {
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [length, setLength] = useState('');
  const [weight, setWeight] = useState('');
  const [result, setResult] = useState(null);

  const loadingTypeInfo = {
    data1: 'Single loading',
    data2: 'Single loading',
    data3: 'Center loading',
    data4: 'Center loading',
    data5: 'Center loading',
    data6: 'Side By Side loading',
    data7: 'Side By Side loading',
  };

  const palletTypeInfo = {
    data1: 'PAG pallet',
    data2: 'PMC Pallet',
    data3: 'PMC Pallet',
    data4: 'PRA pallet',
    data5: 'PGA pallet',
    data6: 'PMC or PAG pallet',
    data7: 'PMC or PAG pallet',
  };

  // Convert cm values to meters
  const convertToMeters = value => {
    if (!value || isNaN(value)) return undefined;
    return value / 100;
  };

  // Check if a given value is valid
  const isValidInput = value => value !== '' && value !== undefined && !isNaN(parseFloat(value));

  // Find the nearest value (greater than or equal) from data30
  const findNearestValue = (value, key) => {
    if (value === '' || value === undefined || isNaN(value)) return undefined;
    const sortedValues = [...new Set(data30.map(item => item[key]))].sort((a, b) => a - b);
    return sortedValues.find(val => val >= value); // Only consider values greater than or equal to input
  };

  // Find suitable positions based on the weight
  const findWeightPositions = weightValue => {
    const allData = { data1, data2, data3, data4, data5, data6, data7 };
    let suitableTypes = [];

    Object.keys(allData).forEach(key => {
      allData[key].forEach(item => {
        if (item.maxkg >= weightValue) {
          suitableTypes.push({ type: loadingTypeInfo[key], pallet: palletTypeInfo[key], source: key });
        }
      });
    });

    return suitableTypes;
  };

  // Group loading types and merge repeating entries
  const groupLoadingTypes = types => {
    const grouped = {};
    types.forEach(type => {
      const key = `${type.type} - ${type.pallet}`;
      if (grouped[key]) {
        grouped[key] += 1;
      } else {
        grouped[key] = 1;
      }
    });
    return Object.keys(grouped).map(key => `${key} (x${grouped[key]})`);
  };

  // Create a message with suitable loading types
  const createLoadingTypeMessage = (types, heightValue) => {
    const groupedTypes = groupLoadingTypes(types);
    groupedTypes.forEach((type, index) => {
      if (heightValue !== undefined && heightValue <= 1.1) {
        if (type.includes('PMC or PAG pallet')) {
          groupedTypes[index] = type.replace('PMC or PAG pallet', 'PMC pallet or PAG pallet');
        }
      } else if (heightValue !== undefined && heightValue <= 1.6) {
        if (type.includes('PMC or PAG pallet')) {
          groupedTypes[index] = type.replace('PMC or PAG pallet', 'PAG pallet');
        }
      }
    });
    return groupedTypes.length > 0 ? `Suitable Loading Types:\n${groupedTypes.join('\n')}` : 'No suitable loading types found for the given values.';
  };

  const calculate = () => {
    let nearestHeight, nearestWidth, nearestLength;
    let matchedData;
    let loadingTypes = [];
  
    // Input değerlerini metreye çeviriyoruz ve geçerli olup olmadıklarını kontrol ediyoruz.
    const heightInMeters = isValidInput(height) ? convertToMeters(parseFloat(height)) : undefined;
    const widthInMeters = isValidInput(width) ? convertToMeters(parseFloat(width)) : undefined;
    const lengthInMeters = isValidInput(length) ? convertToMeters(parseFloat(length)) : undefined;
  
    // Geçerli değerleri bulma
    if (heightInMeters !== undefined && !isNaN(heightInMeters)) nearestHeight = findNearestValue(heightInMeters, 'height');
    if (widthInMeters !== undefined && !isNaN(widthInMeters)) nearestWidth = findNearestValue(widthInMeters, 'width');
    if (lengthInMeters !== undefined && !isNaN(lengthInMeters)) nearestLength = findNearestValue(lengthInMeters, 'length');
  
    // Girilen input değerlerinin dolu olup olmadığını basitçe kontrol etme
    const isHeightValid = height !== '' && height !== undefined && !isNaN(parseFloat(height));
    const isWidthValid = width !== '' && width !== undefined && !isNaN(parseFloat(width));
    const isLengthValid = length !== '' && length !== undefined && !isNaN(parseFloat(length));
  
    // En az iki input dolu mu kontrolü
    if ([isHeightValid, isWidthValid, isLengthValid].filter(Boolean).length < 2) {
      setResult('Please enter at least two valid values.');
      return; // Hatalı durum, fonksiyonu burada sonlandırıyoruz
    }
  
    // Eğer Weight bilgisi varsa uygun pozisyonları buluyoruz
    if (isValidInput(weight)) {
      loadingTypes = findWeightPositions(parseFloat(weight));
    }
  
    // En az iki geçerli input varsa ve tüm hesaplama işlemleri doğruysa
    if (isHeightValid && isWidthValid && !isLengthValid) {
      matchedData = data30.find(item => item.height === nearestHeight && item.width === nearestWidth);
      const lengthValue = matchedData ? matchedData.length * 100 : undefined; // Convert to cm
      setResult(matchedData ? `Suitable Length: ${lengthValue.toFixed(2)} cm` : 'No suitable length found for the given values.');
    } else if (isHeightValid && isLengthValid && !isWidthValid) {
      matchedData = data30.find(item => item.height === nearestHeight && item.length === nearestLength);
      const widthValue = matchedData ? matchedData.width * 100 : undefined; // Convert to cm
      setResult(matchedData ? `Suitable Width: ${widthValue.toFixed(2)} cm` : 'No suitable width found for the given values.');
    } else if (isWidthValid && isLengthValid && !isHeightValid) {
      matchedData = data30.find(item => item.width === nearestWidth && item.length === nearestLength);
      const heightValue = matchedData ? matchedData.height * 100 : undefined; // Convert to cm
      setResult(matchedData ? `Suitable Height: ${heightValue.toFixed(2)} cm` : 'No suitable height found for the given values.');
    } else if (isHeightValid && isWidthValid && isLengthValid) {
      matchedData = data30.find(
        item => item.height === nearestHeight && item.width === nearestWidth && item.length === nearestLength
      );
      setResult(matchedData ? 'The given values are suitable.' : 'No suitable position found for the given values.');
    }
  
    // Eğer hiçbir eşleşme yoksa uygun pozisyon bulunamadı mesajı veriyoruz
    if (!matchedData) {
      setResult('No suitable position found for the given values.');
    } else if (loadingTypes.length > 0) {
      const message = createLoadingTypeMessage(loadingTypes, nearestHeight);
      setResult(prev => `${prev}\n${message}`);
    }
  };
  

  return (
    <Container maxWidth="md" sx={{ marginTop: '50px' }}>
      <Paper elevation={4} sx={{ padding: '30px', backgroundColor: '#212121', color: '#fff' }}>
        <Grid container alignItems="center">
          <Info sx={{ marginRight: 1, color: '#1976d2' }} />
          <Typography variant="h6" component="p" gutterBottom align="left" sx={{ marginBottom: '20px' }}>
            Usage:
          </Typography>
        </Grid>
        <Typography component="p" gutterBottom align="left" sx={{ marginBottom: '20px' }}>
          - Weight information is optional. If provided, the loading capability will be evaluated based on the maximum allowed values for positions. <br />
          - At least 2 out of Height, Width, and Length information should be provided. If all values are given, suitability is checked. If only 2 are provided, the maximum value for the missing dimension will be displayed.
        </Typography>

        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Height (cm)"
              value={height}
              onChange={e => setHeight(e.target.value)}
              InputProps={{
                startAdornment: <Height sx={{ marginRight: 1, color: '#1976d2' }} />,
                sx: { input: { color: '#fff' } },
              }}
              variant="outlined"
              sx={{ input: { color: '#fff' }, label: { color: '#fff' } }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Width (cm)"
              value={width}
              onChange={e => setWidth(e.target.value)}
              InputProps={{
                startAdornment: <WidthFull sx={{ marginRight: 1, color: '#1976d2' }} />,
                sx: { input: { color: '#fff' } },
              }}
              variant="outlined"
              sx={{ input: { color: '#fff' }, label: { color: '#fff' } }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Length (cm)"
              value={length}
              onChange={e => setLength(e.target.value)}
              InputProps={{
                startAdornment: <Straighten sx={{ marginRight: 1, color: '#1976d2' }} />,
                sx: { input: { color: '#fff' } },
              }}
              variant="outlined"
              sx={{ input: { color: '#fff' }, label: { color: '#fff' } }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Weight (kg)"
              value={weight}
              onChange={e => setWeight(e.target.value)}
              InputProps={{
                startAdornment: <Balance sx={{ marginRight: 1, color: '#1976d2' }} />,
                sx: { input: { color: '#fff' } },
              }}
              variant="outlined"
              sx={{ input: { color: '#fff' }, label: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={calculate}
              fullWidth
              sx={{ padding: '10px 0', fontSize: '16px', backgroundColor: '#0277bd' }}
            >
              Calculate
            </Button>
          </Grid>

          {result && (
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: '20px',
                  borderRadius: '8px',
                  backgroundColor: '#424242',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                <Typography variant="h6" sx={{ whiteSpace: 'pre-line' }}>
                  {result}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default CalculationTool;
