// src/serviceWorker.js
const serviceWorkerGlobalScope = self; // eslint-disable-next-line no-restricted-globals

serviceWorkerGlobalScope.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open('my-pwa-cache').then((cache) => {
      return cache.addAll(['/']);
    })
  );
});

serviceWorkerGlobalScope.addEventListener('fetch', (event) => {
  event.respondWith(
    caches.match(event.request).then((response) => {
      return response || fetch(event.request);
    })
  );
});

// Güncelleme algılandığında Service Worker'ı otomatik olarak güncelle
serviceWorkerGlobalScope.addEventListener('updatefound', () => {
  const installingWorker = serviceWorkerGlobalScope.installing;
  if (installingWorker) {
    installingWorker.onstatechange = () => {
      if (installingWorker.state === 'installed' && serviceWorkerGlobalScope.controller) {
        // Eski Service Worker kontrolcüsü varsa, yeni sürümü aktive et
        serviceWorkerGlobalScope.skipWaiting();
        console.log('New version installed. Refreshing page...');
        serviceWorkerGlobalScope.clients.matchAll({ type: 'window' }).then((clients) => {
          clients.forEach((client) => {
            client.navigate(client.url);
          });
        });
      }
    };
  }
});

export default function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}
