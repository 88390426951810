const data1 = [
    { id: 1, position_name: 'A', maxkg: 2826, harms: 0, harme: 223.4, harmstart: 14.648, harmend: 16.883, friends: "[AA],[AB],[ABR],[ABL]", heightx: '317.5', legend_name: "legend01",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -15, top: -1, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2105', latch2: '0', related: "AA, ABR" },
            { wi: '10px', he: '10px',  left: -15, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2105', latch2: '0', related: "AA, AB, ABR" }, 
            { wi: '10px', he: '10px',  left: -15, top: 31, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2105', latch2: '0', related: "AA, AB, ABR" }, 
            { wi: '10px', he: '10px',  left: -15, top: 46, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2105', latch2: '0', related: "AA, AB, ABR ABL" },  
            { wi: '10px', he: '10px',  left: -15, top: 61, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-12001-XZ', latch1: '2105', latch2: '0', related: "AA, AB, ABL" },  
            { wi: '10px', he: '10px',  left: -15, top: 76, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-22001-XZ', latch1: '2105', latch2: '0', related: "AA, AB, ABL" },  
            { wi: '10px', he: '10px',  left: -15, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-52001-XZ', latch1: '2105', latch2: '0', related: "AA, ABL" },  
          
            // XZ AFT
            {  wi: '15px', he: '10px',  left: 60, top: -1, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-52500-XZ', latch1: '2405', latch2: '0', related: "B" },
            {  wi: '15px', he: '10px',  left: 60, top: 16, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-22500-XZ', latch1: '2405', latch2: '0', related: "B" }, 
            {  wi: '15px', he: '10px',  left: 60, top: 34, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-12500-XZ', latch1: '2405', latch2: '0', related: "B" },  
            {  wi: '15px', he: '10px',  left: 60, top: 56, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-12500-XZ', latch1: '2405', latch2: '0', related: "B" },  
            {  wi: '15px', he: '10px',  left: 60, top: 74, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-24250-XZ', latch1: '2405', latch2: '0', related: "B" },  
            {  wi: '15px', he: '10px',  left: 60, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-52500-XZ', latch1: '2405', latch2: '0', related: "B" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -1, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '2825', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px', left: 14, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ', latch1: '2825', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px', left: 30, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ', latch1: '2825', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px', left: 46, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ', latch1: '2825', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px',left: -1, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62100-YZ', latch1: '1915', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px', left: 14, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62200-YZ', latch1: '1915', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px', left: 30, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62300-YZ', latch1: '1915', latch2: '0', related: "AA" },  // 53
            { wi: '10px', he: '10px',left: 46, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62400-YZ', latch1: '1915', latch2: '0', related: "AA" }  // 53
            
        ]
    },
    { id: 2, position_name: 'B', maxkg: 3123, harms: 226, harme: 449.6, harmstart: 16.908, harmend: 19.144, friends: "[AA],[AB],[ABR],[ABL],[BB],[BC],[BCR],[BCL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: 1, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23100-XZ', latch1: '2162', latch2: '0', related: "C" },
            { wi: '10px', he: '10px',  left: 1, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13100-XZ', latch1: '2162', latch2: '0', related: "C" }, 
     
            { wi: '10px', he: '10px',  left: 1, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13100-XZ', latch1: '2162', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: 1, top: 80, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23100 -XZ', latch1: '2162', latch2: '0', related: "C" },  
          
            // XZ FWD
            {  wi: '15px', he: '10px',  left: -82, top: -1, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-52500-XZ', latch1: '3123', latch2: '0', related: "A" },
            {  wi: '15px', he: '10px',  left: -82, top: 16, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-22500-XZ', latch1: '3123', latch2: '0', related: "A" }, 
            {  wi: '15px', he: '10px',  left: -82, top: 34, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-12500-XZ', latch1: '3123', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -82, top: 56, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-12500-XZ', latch1: '3123', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -82, top: 74, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-24250-XZ', latch1: '3123', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -82, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-52500-XZ', latch1: '3123', latch2: '0', related: "A" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -60, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62500-YZ', latch1: '3123', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -47, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62600-YZ', latch1: '3123', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -34, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62900-YZ', latch1: '3123', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -20, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63000-YZ', latch1: '3123', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px',left:  -60, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62500-YZ', latch1: '2654', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -47, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62600-YZ', latch1: '2654', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -34, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62900-YZ', latch1: '2654', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px',left:  -20, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63000-YZ', latch1: '2654', latch2: '0', related: "BB" }  // 53
            
        ]
    },
    { id: 3, position_name: 'C', maxkg: 3391, harms: 452.1, harme: 675.6, harmstart: 19.169, harmend: 21.404, friends: "[BB],[CC],[BC],[CE],[CFR],[CFG],[BCR],[BCL],[CER],[CEL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -61, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23501-XZ', latch1: '2162', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -61, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13501-XZ', latch1: '2162', latch2: '0', related: "D" }, 
     
            { wi: '10px', he: '10px',  left: -61, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13501-XZ', latch1: '2162', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -61, top: 80, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23501-XZ', latch1: '2162', latch2: '0', related: "D" },  
          
            // XZ FWD
            { wi: '10px', he: '10px',  left: -138, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23100-XZ', latch1: '1662', latch2: '0', related: "B" },
            { wi: '10px', he: '10px',  left: -138, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13100-XZ', latch1: '1662', latch2: '0', related: "B" }, 
     
            { wi: '10px', he: '10px',  left: -138, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13100-XZ', latch1: '1662', latch2: '0', related: "B" },  
            { wi: '10px', he: '10px',  left: -138, top: 80, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23100 -XZ', latch1: '1662', latch2: '0', related: "B" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -130, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63100-YZ', latch1: '2494', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -115, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63200-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -100, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63300-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -85, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63400-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -70, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63500-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53


            { wi: '10px', he: '10px',left:  -130, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63100-YZ', latch1: '2494', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -115, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63200-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -100, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63300-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px',left:  -85, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63400-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px',left:  -70, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63500-YZ', latch1: '2494', latch2: '0', related: "CC" }  // 53
            
        ]
    },
    { id: 4, position_name: 'D', maxkg: 3391, harms: 678.2, harme: 901.7, harmstart: 21.430, harmend: 23.665, friends: "[CC],[DD],[CFR],[CFG],[CE],[CER],[CEL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -121, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53721-XZ', latch1: '3243', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -121, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23721-XZ', latch1: '3243', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -121, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13721-XZ', latch1: '3243', latch2: '0', related: "E" }, 
     
            { wi: '10px', he: '10px',  left: -121, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13721-XZ', latch1: '3243', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -121, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23721-XZ', latch1: '3243', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -121, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53721-XZ', latch1: '3243', latch2: '0', related: "E" },  
          
            // XZ FWD
            { wi: '10px', he: '10px',  left: -198, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23501-XZ', latch1: '1662', latch2: '0', related: "C" },
            { wi: '10px', he: '10px',  left: -198, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13501-XZ', latch1: '1662', latch2: '0', related: "C" }, 
     
            { wi: '10px', he: '10px',  left: -198, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13501-XZ', latch1: '1662', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: -198, top: 80, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23501-XZ', latch1: '1662', latch2: '0', related: "C" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -180, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63600-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -165, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63700-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -150, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63710-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -135, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63720-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
       

            { wi: '10px', he: '10px',left:  -180, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63600-YZ', latch1: '2494', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -165, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63700-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -150, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63710-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px',left:  -135, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63720-YZ', latch1: '2494', latch2: '0', related: "DD" },  // 53
        
        ]
    },
    { id: 5, position_name: 'E', maxkg: 4687, harms: 904.2, harme: 1127.7, harmstart: 23.690, harmend: 25.925, friends: "[DD],[EE],[CFR],[CFG],[CE],[EF],[CER],[CEL],[EFR],[EFL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -259, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53721-XZ', latch1: '2509', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -259, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23721-XZ', latch1: '2509', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -259, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13721-XZ', latch1: '2509', latch2: '0', related: "D" }, 
     
            { wi: '10px', he: '10px',  left: -259, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13721-XZ', latch1: '2509', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -259, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23721-XZ', latch1: '2509', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -259, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53721-XZ', latch1: '2509', latch2: '0', related: "D" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -182, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53901-XZ', latch1: '3243', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -182, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23901-XZ', latch1: '3243', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -182, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13901-XZ', latch1: '3243', latch2: '0', related: "F" }, 
     
            { wi: '10px', he: '10px',  left: -182, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13901-XZ', latch1: '3243', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -182, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23901-XZ', latch1: '3243', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -182, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53901-XZ', latch1: '3243', latch2: '0', related: "F" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -243, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63730-YZ', latch1: '4352', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -227, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63740-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -212, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63800-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -198, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63900-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
       

            { wi: '10px', he: '10px',left:  -243, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63730-YZ', latch1: '4352', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -227, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63740-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -212, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63800-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px',left:  -198, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63900-YZ', latch1: '4352', latch2: '0', related: "EE" },  // 53
        
        ]
    },
    { id: 6, position_name: 'F', maxkg: 6033, harms: 1130.3, harme: 1353.8, harmstart: 25.951, harmend: 28.186, friends: "[EE],[FF],[CFR],[CFG],[FJR],[FJG],[EF],[FH],[EFR],[EFL],[FHR],[FHL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -320, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53901-XZ', latch1: '2509', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -320, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23901-XZ', latch1: '2509', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -320, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13901-XZ', latch1: '2509', latch2: '0', related: "E" }, 
     
            { wi: '10px', he: '10px',  left: -320, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13901-XZ', latch1: '2509', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -320, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23901-XZ', latch1: '2509', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -320, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53901-XZ', latch1: '2509', latch2: '0', related: "E" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -243, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54020-XZ', latch1: '3243', latch2: '0', related: "G" },
            { wi: '10px', he: '10px',  left: -243, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24020-XZ', latch1: '3243', latch2: '0', related: "G" },
            { wi: '10px', he: '10px',  left: -243, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14020-XZ', latch1: '3243', latch2: '0', related: "G" }, 
     
            { wi: '10px', he: '10px',  left: -243, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14020-XZ', latch1: '3243', latch2: '0', related: "G" },  
            { wi: '10px', he: '10px',  left: -243, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24020-XZ', latch1: '3243', latch2: '0', related: "G" },  
            { wi: '10px', he: '10px',  left: -243, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54020-XZ', latch1: '3243', latch2: '0', related: "G" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -308, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63910-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -292, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63920-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -276, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64001-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -260, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64010-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
       

            { wi: '10px', he: '10px',left:  -306, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63910-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -290, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63920-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -274, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64001-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px',left: -258, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64010-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
        
        ]
    },
    { id: 7, position_name: 'G', maxkg: 6033, harms: 1356.3, harme: 1579.9, harmstart: 28.211, harmend: 30.447, friends: "[FF],[GG],[FJR],[FJG],[FH],[FHR],[FHL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -381, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54020-XZ', latch1: '2509', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -381, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24020-XZ', latch1: '2509', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -381, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14020-XZ', latch1: '2509', latch2: '0', related: "F" }, 
     
            { wi: '10px', he: '10px',  left: -381, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14020-XZ', latch1: '2509', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -381, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24020-XZ', latch1: '2509', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -381, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54020-XZ', latch1: '2509', latch2: '0', related: "F" },  
          
            // XZ AFT
            { wi: '15px', he: '10px',  left: -304, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-54301-XZ', latch1: '2405', latch2: '0', related: "H" },
            { wi: '15px', he: '10px',  left: -304, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-24301-XZ', latch1: '2405', latch2: '0', related: "H" },
            { wi: '15px', he: '10px',  left: -304, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-14301-XZ', latch1: '2405', latch2: '0', related: "H" }, 
     
            { wi: '15px', he: '10px',  left: -304, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-14301-XZ', latch1: '2405', latch2: '0', related: "H" },  
            { wi: '15px', he: '10px',  left: -304, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-24301-XZ', latch1: '2405', latch2: '0', related: "H" },  
            { wi: '15px', he: '10px',  left: -304, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-54301-XZ', latch1: '2405', latch2: '0', related: "H" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -368, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64020-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -355, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64030-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -342, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64100-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -329, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64200-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -316, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64300-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
       

            { wi: '10px', he: '10px',left:  -368, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64020-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -355, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64030-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -342, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64100-YZ', latch1: '4152', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px',left: -329, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64200-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px',left: -316, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64300-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
        
        ]
    },
    { id: 8, position_name: 'H', maxkg: 6033, harms: 1582.4, harme: 1805.9, harmstart: 30.472, harmend: 32.707, friends: "[GG],[HH],[FH],[HJ],[FJR],[FJG],[JLR],[HJ],[HJR],[HJL],[FHR],[FHL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -365, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54701-XZ', latch1: '3243', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -365, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24701-XZ', latch1: '3243', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -365, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14701-XZ', latch1: '3243', latch2: '0', related: "J" }, 
     
            { wi: '10px', he: '10px',  left: -365, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14701-XZ', latch1: '3243', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -365, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24701-XZ', latch1: '3243', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -365, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54701-XZ', latch1: '3243', latch2: '0', related: "J" },  
          
            // XZ FWD
            { wi: '15px', he: '10px',  left: -448, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-54301-XZ', latch1: '4266', latch2: '0', related: "G" },
            { wi: '15px', he: '10px',  left: -448, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-24301-XZ', latch1: '4266', latch2: '0', related: "G" },
            { wi: '15px', he: '10px',  left: -448, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-14301-XZ', latch1: '4266', latch2: '0', related: "G" }, 
     
            { wi: '15px', he: '10px',  left: -448, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-14301-XZ', latch1: '4266', latch2: '0', related: "G" },  
            { wi: '15px', he: '10px',  left: -448, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-24301-XZ', latch1: '4266', latch2: '0', related: "G" },  
            { wi: '15px', he: '10px',  left: -448, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-54301-XZ', latch1: '4266', latch2: '0', related: "G" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -425, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64400-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -410, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64500-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -395, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64600-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -380, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64700-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
          

            { wi: '10px', he: '10px',left:  -425, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64400-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -410, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64500-YZ', latch1: '4152', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -395, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64600-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px',left: -380, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64700-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
          
        ]
    },
    { id: 9, position_name: 'J', maxkg: 6033, harms: 1808.5, harme: 2032, harmstart: 32.733, harmend: 34.968, friends: "[HH],[JJ],[JLR],[JLG],[HJ],[JK],[HJR],[HJL],[JKR],[JKL]", heightx: '317.5' , legend_name: "legend03",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -425, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55101-XZ', latch1: '3243', latch2: '0', related: "K" },
           
            { wi: '10px', he: '10px',  left: -425, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25101-XZ', latch1: '3243', latch2: '0', related: "K" },
            { wi: '10px', he: '10px',  left: -425, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15101-XZ', latch1: '3243', latch2: '0', related: "K" }, 
     
            { wi: '10px', he: '10px',  left: -425, top: 59, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15101-XZ', latch1: '3243', latch2: '0', related: "K" },  
            { wi: '10px', he: '10px',  left: -425, top: 76, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25101-XZ', latch1: '3243', latch2: '0', related: "K" },  
            { wi: '10px', he: '10px',  left: -425, top: 93, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55101-XZ', latch1: '3243', latch2: '0', related: "K" },
           
            // XZ FWD
            { wi: '10px', he: '10px',  left: -503, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54701-XZ', latch1: '2509', latch2: '0', related: "H" },
           
            { wi: '10px', he: '10px',  left: -503, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24701-XZ', latch1: '2509', latch2: '0', related: "H" },
            { wi: '10px', he: '10px',  left: -503, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14701-XZ', latch1: '2509', latch2: '0', related: "H" }, 
     
            { wi: '10px', he: '10px',  left: -503, top: 59, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14701-XZ', latch1: '2509', latch2: '0', related: "H" },  
            { wi: '10px', he: '10px',  left: -503, top: 76, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24701-XZ', latch1: '2509', latch2: '0', related: "H" },  
            { wi: '10px', he: '10px',  left: -503, top: 93, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54701-XZ', latch1: '2509', latch2: '0', related: "H" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -485, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64800-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -470, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64900-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -455, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65000-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -440, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65100-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
           
            { wi: '10px', he: '10px',left:  -485, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64800-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -470, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64900-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -455, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65000-YZ', latch1: '4152', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px',left:  -440, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65100-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
          
        ]
    },
    { id: 10, position_name: 'K', maxkg: 5945, harms: 2034.5, harme: 2258, harmstart: 34.993, harmend: 37.228, friends: "[JJ],[KK],[JLR],[JLG],[JK],[KM],[JKR],[JKL],[KMR],[KML]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -563, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55101-XZ', latch1: '2509', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -563, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25101-XZ', latch1: '2509', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -563, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15101-XZ', latch1: '2509', latch2: '0', related: "J" }, 
     
            { wi: '10px', he: '10px',  left: -563, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15101-XZ', latch1: '2509', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -563, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25101-XZ', latch1: '2509', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -563, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55101-XZ', latch1: '2509', latch2: '0', related: "J" },  
          
            // XZ AFT
            { wi: '15px', he: '10px',  left: -485, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-55323-XZ', latch1: '2405', latch2: '0', related: "L" },
            { wi: '15px', he: '10px',  left: -485, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-25323-XZ', latch1: '2405', latch2: '0', related: "L" },
            { wi: '15px', he: '10px',  left: -485, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-15323-XZ', latch1: '2405', latch2: '0', related: "L" }, 
     
            { wi: '15px', he: '10px',  left: -485, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-15323-XZ', latch1: '2405', latch2: '0', related: "L" },  
            { wi: '15px', he: '10px',  left: -485, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-25323-XZ', latch1: '2405', latch2: '0', related: "L" },  
            { wi: '15px', he: '10px',  left: -485, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-55323-XZ', latch1: '2405', latch2: '0', related: "L" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -545, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65200-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -532, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65300-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -519, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65310-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -505, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65320-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
          

            { wi: '10px', he: '10px',left:  -545, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65200-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -532, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65300-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -519, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65310-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px',left: -505, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65320-YZ', latch1: '4152', latch2: '0', related: "JJ" },  // 53

        ]
    },
    { id: 11, position_name: 'L', maxkg: 4037, harms: 2260.6, harme: 2484.1, harmstart: 37.254, harmend: 39.489, friends: "[KK],[LL],[LPR],[JLG],[KM],[KMR],[KML],[JLR]" , heightx: '317.5', legend_name: "legend04",
        boxes: [
            // XZ FWD
            { wi: '15px', he: '10px',  left: -630, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-55323-XZ', latch1: '4037', latch2: '0', related: "K" },
            { wi: '15px', he: '10px',  left: -630, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-25323-XZ', latch1: '4037', latch2: '0', related: "K" },
            { wi: '15px', he: '10px',  left: -630, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-15323-XZ', latch1: '4037', latch2: '0', related: "K" }, 
     
            { wi: '15px', he: '10px',  left: -630, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-15323-XZ', latch1: '4037', latch2: '0', related: "K" },  
            { wi: '15px', he: '10px',  left: -630, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-25323-XZ', latch1: '4037', latch2: '0', related: "K" },  
            { wi: '15px', he: '10px',  left: -630, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-55323-XZ', latch1: '4037', latch2: '0', related: "K" },  
          
            // XZ AFT
            { wi: '15px', he: '10px',  left: -546, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-55370-XZ', latch1: '2405', latch2: '0', related: "M" },
            { wi: '15px', he: '10px',  left: -546, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-25370-XZ', latch1: '2405', latch2: '0', related: "M" },
            { wi: '15px', he: '10px',  left: -546, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-15370-XZ', latch1: '2405', latch2: '0', related: "M" }, 
     
            { wi: '15px', he: '10px',  left: -546, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-15370-XZ', latch1: '2405', latch2: '0', related: "M" },  
            { wi: '15px', he: '10px',  left: -546, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-25370-XZ', latch1: '2405', latch2: '0', related: "M" },  
            { wi: '15px', he: '10px',  left: -546, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-55370-XZ', latch1: '2405', latch2: '0', related: "M" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -615, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65330-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -602, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65340-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -588, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65350-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -574, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65360-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -560, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65370-YZ', latch1: '3588', latch2: '0', related: "-" },  // 53
          

            { wi: '10px', he: '10px',left:  -615, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65330-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -602, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65340-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -588, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65350-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px',left: -574, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65360-YZ', latch1: '3588', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px',left: -560, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65370-YZ', latch1: '3588', latch2: '0', related: "-" },  // 53

        ]
    },
    { id: 12, position_name: 'M', maxkg: 4037, harms: 2486.6, harme: 2710.2, harmstart: 39.514, harmend: 41.750, friends: "[LL],[LPR],[KM],[MP],[KMR],[KML],[MPR],[MPL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -608, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55501-XZ', latch1: '3243', latch2: '0', related: "P" },
            { wi: '10px', he: '10px',  left: -608, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25501-XZ', latch1: '3243', latch2: '0', related: "P" },
            { wi: '10px', he: '10px',  left: -608, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15501-XZ', latch1: '3243', latch2: '0', related: "P" }, 
     
            { wi: '10px', he: '10px',  left: -608, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15501-XZ', latch1: '3243', latch2: '0', related: "P" },  
            { wi: '10px', he: '10px',  left: -608, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25501-XZ', latch1: '3243', latch2: '0', related: "P" },  
            { wi: '10px', he: '10px',  left: -608, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55501-XZ', latch1: '3243', latch2: '0', related: "P" },  
          
            // XZ FWD
            { wi: '15px', he: '10px',  left: -690, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-55370-XZ', latch1: '4037', latch2: '0', related: "L,LL" },
            { wi: '15px', he: '10px',  left: -690, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-25370-XZ', latch1: '4037', latch2: '0', related: "L,LL" },
            { wi: '15px', he: '10px',  left: -690, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-15370-XZ', latch1: '4037', latch2: '0', related: "L,LL" }, 
     
            { wi: '15px', he: '10px',  left: -690, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-15370-XZ', latch1: '4037', latch2: '0', related: "L,LL" },  
            { wi: '15px', he: '10px',  left: -690, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-25370-XZ', latch1: '4037', latch2: '0', related: "L,LL" },  
            { wi: '15px', he: '10px',  left: -690, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-55370-XZ', latch1: '4037', latch2: '0', related: "L,LL" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -670, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65380-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -655, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65390-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -640, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65400-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -625, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65500-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
          

            { wi: '10px', he: '10px',left:  -670, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65380-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -655, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65390-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -640, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65400-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px',left: -625, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65500-YZ', latch1: '3421', latch2: '0', related: "LL" },  // 53

        ]
    },
    { id: 13, position_name: 'P', maxkg: 3725, harms: 2712.7, harme: 2936.2, harmstart: 41.775, harmend: 44.010, friends: "[MM],[LPR],[MP],[MPR],[MPL],[PRR],[PRL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -746, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55501-XZ', latch1: '2418', latch2: '0', related: "M" },
            { wi: '10px', he: '10px',  left: -746, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25501-XZ', latch1: '2418', latch2: '0', related: "M" },
            { wi: '10px', he: '10px',  left: -746, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15501-XZ', latch1: '2418', latch2: '0', related: "M" }, 
     
            { wi: '10px', he: '10px',  left: -746, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15501-XZ', latch1: '2418', latch2: '0', related: "M" },  
            { wi: '10px', he: '10px',  left: -746, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25501-XZ', latch1: '2418', latch2: '0', related: "M" },  
            { wi: '10px', he: '10px',  left: -746, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55501-XZ', latch1: '2418', latch2: '0', related: "M" },  
          
            // XZ AFT
            { wi: '15px', he: '10px',  left: -668, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-56000-XZ', latch1: '1729', latch2: '0', related: "R" },
            { wi: '15px', he: '10px',  left: -668, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-26000-XZ', latch1: '1729', latch2: '0', related: "R" },
            { wi: '15px', he: '10px',  left: -668, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-16000-XZ', latch1: '1729', latch2: '0', related: "R" }, 
     
            { wi: '15px', he: '10px',  left: -668, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-16000-XZ', latch1: '1729', latch2: '0', related: "R" },  
            { wi: '15px', he: '10px',  left: -668, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-26000-XZ', latch1: '1729', latch2: '0', related: "R" },  
            { wi: '15px', he: '10px',  left: -668, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-56000-XZ', latch1: '1729', latch2: '0', related: "R" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -730, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65600-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -715, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65700-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -700, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65800-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -685, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65900-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
          

            { wi: '10px', he: '10px',left:  -730, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65600-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -715, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65700-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -700, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65800-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px',left: -685, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65900-YZ', latch1: '2267', latch2: '0', related: "MM" },  // 53

        ]
    },
    { id: 14, position_name: 'R', maxkg: 3714, harms: 2979.4, harme: 3202.9, harmstart: 44.042, harmend: 46.677, friends: "[PP],[PRR],[PRL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ FWD
            { wi: '15px', he: '10px',  left: -822, top: 0, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-56000-XZ', latch1: '1710', latch2: '0', related: "P" },
            { wi: '15px', he: '10px',  left: -822, top: 18, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-26000-XZ', latch1: '1710', latch2: '0', related: "P" },
            { wi: '15px', he: '10px',  left: -822, top: 36, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-RH-16000-XZ', latch1: '1710', latch2: '0', related: "P" }, 
     
            { wi: '15px', he: '10px',  left: -822, top: 55, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-16000-XZ', latch1: '1710', latch2: '0', related: "P" },  
            { wi: '15px', he: '10px',  left: -822, top: 73, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-26000-XZ', latch1: '1710', latch2: '0', related: "P" },  
            { wi: '15px', he: '10px',  left: -822, top: 92, color: '#979d2d',border: '#fff', latchType: 'XZ-Triple Latch', latchCode: 'MD-M-LH-56000-XZ', latch1: '1710', latch2: '0', related: "P" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -741, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-RH-56401-XZ', latch1: '2907', latch2: '0', related: "S" },
           
            { wi: '15px', he: '10px',  left: -741, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-26402-XZ', latch1: '2907', latch2: '0', related: "S" },
            { wi: '15px', he: '10px',  left: -741, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-16402-XZ', latch1: '2907', latch2: '0', related: "S" },
            { wi: '15px', he: '10px',  left: -741, top: 45, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-AD-06402-XZ', latch1: '2907', latch2: '0', related: "S" }, 
     
            { wi: '15px', he: '10px',  left: -741, top: 61, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-16402-XZ', latch1: '2907', latch2: '0', related: "S" },  
            { wi: '15px', he: '10px',  left: -741, top: 77, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-26402-XZ', latch1: '2907', latch2: '0', related: "S" },  
            { wi: '10px', he: '10px',  left: -741, top: 94, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-LH-56401-XZ', latch1: '2907', latch2: '0', related: "S" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -800, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66100-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px', left: -785, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66200-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px', left: -770, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66300-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px', left: -755, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66400-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
          

            { wi: '10px', he: '10px',left:  -800, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66100-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px', left: -785, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66200-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px', left: -770, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66300-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
            { wi: '10px', he: '10px',left: -755, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66400-YZ', latch1: '2267', latch2: '0', related: "PP" },  // 53
          
        ]
    },
    { id: 15, position_name: 'S', maxkg: 3714, harms: 3225.8, harme: 3449.3, harmstart: 46.703, harmend: 49.141, friends: "[RR]", heightx: '317.5' , legend_name: "legend04",
        boxes: [
            // XZ FWD
            { wi: '15px', he: '10px',  left: -890, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-26402-XZ', latch1: '3333', latch2: '0', related: "R" },
            { wi: '15px', he: '10px',  left: -890, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-16402-XZ', latch1: '3333', latch2: '0', related: "R" },
            { wi: '15px', he: '10px',  left: -890, top: 45, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-AD-06402-XZ', latch1: '3333', latch2: '0', related: "R" }, 
     
            { wi: '15px', he: '10px',  left: -890, top: 61, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-16402-XZ', latch1: '3333', latch2: '0', related: "R" },  
            { wi: '15px', he: '10px',  left: -890, top: 77, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-26402-XZ', latch1: '3333', latch2: '0', related: "R" },  

            // XZ AFT
            { wi: '15px', he: '10px',  left: -806, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-26901-XZ', latch1: '1938', latch2: '0', related: "T" },
            { wi: '15px', he: '10px',  left: -806, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-169011-XZ', latch1: '1938', latch2: '0', related: "T" },
            { wi: '15px', he: '10px',  left: -806, top: 45, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-AD-06901-XZ', latch1: '1938', latch2: '0', related: "T" }, 
     
            { wi: '15px', he: '10px',  left: -806, top: 61, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-16901-XZ', latch1: '1938', latch2: '0', related: "T" },  
            { wi: '15px', he: '10px',  left: -806, top: 77, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-26901-XZ', latch1: '1938', latch2: '0', related: "T" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -867, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66600-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px', left: -852, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66700-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px', left: -837, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66800-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px', left: -822, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66900-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
          

            { wi: '10px', he: '10px',left:  -867, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66600-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px', left: -852, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66700-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px', left: -837, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66800-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
            { wi: '10px', he: '10px',left: -822, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66900-YZ', latch1: '2367', latch2: '0', related: "RR" },  // 53
          
        ]
    },
    { id: 16, position_name: 'T', maxkg: 3059, harms: 3472.2, harme: 3695.7, harmstart: 49.166, harmend: 51.605, friends: "[SS]", heightx: '317.5' , legend_name: "legend04",
        boxes: [
            // XZ FWD
            { wi: '15px', he: '10px',  left: -955, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-26901-XZ', latch1: '3059', latch2: '0', related: "S" },
            { wi: '15px', he: '10px',  left: -955, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-169011-XZ', latch1: '3059', latch2: '0', related: "S" },
            { wi: '15px', he: '10px',  left: -955, top: 45, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-AD-06901-XZ', latch1: '3059', latch2: '0', related: "S" }, 
     
            { wi: '15px', he: '10px',  left: -955, top: 61, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-16901-XZ', latch1: '3059', latch2: '0', related: "S" },  
            { wi: '15px', he: '10px',  left: -955, top: 77, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-26901-XZ', latch1: '3059', latch2: '0', related: "S" },  

            // XZ AFT
            { wi: '15px', he: '10px',  left: -873, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-27401-XZ', latch1: '1603', latch2: '0', related: "U" },
            { wi: '15px', he: '10px',  left: -873, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-17401-XZ', latch1: '1603', latch2: '0', related: "U" },
            { wi: '15px', he: '10px',  left: -873, top: 60, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-17401-XZ', latch1: '1603', latch2: '0', related: "U" }, 
     
            { wi: '15px', he: '10px',  left: -873, top: 75, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-27401-XZ', latch1: '1603', latch2: '0', related: "U" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -935, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67000-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px', left: -920, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67100-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px', left: -905, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67200-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px', left: -890, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67300-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
          

            { wi: '10px', he: '10px',left:  -935, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67000-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px', left: -920, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67100-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px', left: -905, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67200-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
            { wi: '10px', he: '10px',left: -890, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67300-YZ', latch1: '2063', latch2: '0', related: "SS" },  // 53
          
        ]
    },
    { id: 17, position_name: 'U', maxkg: 2541, harms: 3718.5, harme: 3942.1, harmstart: 51.630, harmend: 54.069, friends: "[TT]", heightx: '317.5', legend_name: "legend01",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -939, top: 14, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch(End Stop)', latchCode: 'MD-M-RH-37800-XZ', latch1: '1609', latch2: '0', related: "S" },
            { wi: '10px', he: '10px',  left: -939, top: 30, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch(End Stop)', latchCode: 'MD-M-RH-17800-XZ', latch1: '1609', latch2: '0', related: "S" },
            { wi: '10px', he: '10px',  left: -939, top: 45, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch(End Stop)', latchCode: 'MD-M-AD-07800-XZ', latch1: '1609', latch2: '0', related: "S" }, 
     
            { wi: '10px', he: '10px',  left: -939, top: 61, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch(End Stop)', latchCode: 'MD-M-LH-17800-XZ', latch1: '1609', latch2: '0', related: "S" },  
            { wi: '10px', he: '10px',  left: -939, top: 77, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch(End Stop)', latchCode: 'MD-M-LH-37800-XZ', latch1: '1609', latch2: '0', related: "S" },  

            // XZ FWD
            { wi: '15px', he: '10px',  left: -1021, top: 14, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-27401-XZ', latch1: '2540', latch2: '0', related: "T" },
            { wi: '15px', he: '10px',  left: -1021, top: 30, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-17401-XZ', latch1: '2540', latch2: '0', related: "T" },
            { wi: '15px', he: '10px',  left: -1021, top: 60, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-17401-XZ', latch1: '2540', latch2: '0', related: "T" }, 
     
            { wi: '15px', he: '10px',  left: -1021, top: 75, color: '#979d2d',border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-27401-XZ', latch1: '2540', latch2: '0', related: "T" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -1000, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67500-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
            { wi: '10px', he: '10px', left: -985, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67600-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
            { wi: '10px', he: '10px', left: -970, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67700-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
       

            { wi: '10px', he: '10px',left:  -1000, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67500-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
            { wi: '10px', he: '10px', left: -985, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67600-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
            { wi: '10px', he: '10px', left: -970, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67700-YZ', latch1: '0', latch2: '0', related: "TT" },  // 53
         
        ]
    },
];

const data2 = [
    { id: 18, position_name: 'AA', maxkg: 3080, harms: 0, harme: 243.8, harmstart: 14.648, harmend: 17.086, friends: "[A],[B],[AB],[ABR],[ABL]", heightx: '317.5', legend_name: "legend01",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -17, top: -1, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0', related: "A, ABR" },
            { wi: '10px', he: '10px',  left: -17, top: 15, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0', related: "A, AB, ABR" }, 
            { wi: '10px', he: '10px',  left: -17, top: 31, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0', related: "A, AB, ABR" }, 
            { wi: '10px', he: '10px',  left: -17, top: 46, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0', related: "A, AB, ABR ABL" },  
            { wi: '10px', he: '10px',  left: -17, top: 61, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-12001-XZ', latch1: '2297', latch2: '0', related: "A, AB, ABL" },  
            { wi: '10px', he: '10px',  left: -17, top: 76, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-22001-XZ', latch1: '2297', latch2: '0', related: "A, AB, ABL" },  
            { wi: '10px', he: '10px',  left: -17, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-52001-XZ', latch1: '2297', latch2: '0', related: "A, ABL" },  
          
            // XZ AFT
            {  wi: '15px', he: '10px',  left: 66, top: -1, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-52401-XZ', latch1: '2624', latch2: '0', related: "BB" },
            {  wi: '15px', he: '10px',  left: 66, top: 16, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-22401-XZ', latch1: '2624', latch2: '0', related: "BB" }, 
            {  wi: '15px', he: '10px',  left: 66, top: 34, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-12401-XZ', latch1: '2624', latch2: '0', related: "BB" },  
            {  wi: '15px', he: '10px',  left: 66, top: 56, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-12401-XZ', latch1: '2624', latch2: '0', related: "BB" },  
            {  wi: '15px', he: '10px',  left: 66, top: 74, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-22401-XZ', latch1: '2624', latch2: '0', related: "BB" },  
            {  wi: '15px', he: '10px',  left: 66, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-52401-XZ', latch1: '2624', latch2: '0', related: "BB" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -1, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px', left: 14, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ', latch1: '3079', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px', left: 30, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ', latch1: '3079', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px', left: 46, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ', latch1: '3079', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px',left: -1, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62100-YZ', latch1: '1824', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px', left: 14, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62200-YZ', latch1: '1824', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px', left: 30, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62300-YZ', latch1: '1824', latch2: '0', related: "A" },  // 53
            { wi: '10px', he: '10px',left: 46, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62400-YZ', latch1: '1824', latch2: '0', related: "A" }  // 53
            
        ]
    },
    { id: 19, position_name: 'BB', maxkg: 3428, harms: 246.4, harme: 490.2, harmstart: 17.112, harmend: 19.550, friends: "[B],[C],[AB],[BC],[BCR],[BCL]", heightx: '317.5', legend_name: "legend02",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: 2, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53101-XZ', latch1: '3427', latch2: '0', related: "C" },
          
            { wi: '10px', he: '10px',  left: 2, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23101-XZ', latch1: '3427', latch2: '0', related: "C" },
            { wi: '10px', he: '10px',  left: 2, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13101-XZ', latch1: '3427', latch2: '0', related: "C" }, 
     
            { wi: '10px', he: '10px',  left: 2, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13101-XZ', latch1: '3427', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: 2, top: 76, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23101-XZ', latch1: '3427', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: 2, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53101-XZ', latch1: '3427', latch2: '0', related: "C" },  
          
            // XZ FWD
            {  wi: '15px', he: '10px',  left: -88, top: -1, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-52401-XZ', latch1: '3427', latch2: '0', related: "A" },
            {  wi: '15px', he: '10px',  left: -88, top: 16, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-22401-XZ', latch1: '3427', latch2: '0', related: "A" }, 
            {  wi: '15px', he: '10px',  left: -88, top: 34, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-12401-XZ', latch1: '3427', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -88, top: 56, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-12401-XZ', latch1: '3427', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -88, top: 74, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-22401-XZ', latch1: '3427', latch2: '0', related: "A" },  
            {  wi: '15px', he: '10px',  left: -88, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-52401-XZ', latch1: '3427', latch2: '0', related: "A" },  

            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -67, top: -15, color: '#005967', border: '#47e6ff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62500-YZ', latch1: 'No reduction', latch2: 'No reduction with MD-M-LH-62500-YZ', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -54, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62600-YZ', latch1: '3427', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -41, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62900-YZ', latch1: '3427', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -27, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63000-YZ', latch1: '3427', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -13, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63100-YZ', latch1: '3427', latch2: '0', related: "BB" },  // 53


            { wi: '10px', he: '10px',left:  -67, top: 109, color: '#005967', border: '#47e6ff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62500-YZ', latch1: 'No reduction', latch2: 'No reduction with MD-M-RH-62500-YZ', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -54, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62600-YZ', latch1: '2918', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -41, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62900-YZ', latch1: '2918', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px',left:  -27, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63000-YZ', latch1: '2918', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px',left:  -13, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63100-YZ', latch1: '2918', latch2: '0', related: "BB" }  // 53
            
        ]
    },
    { id: 20, position_name: 'CC', maxkg: 3696, harms: 492.7, harme: 736.6, harmstart: 19.575, harmend: 22.014, friends: "[C],[D],[BC],[CE],[CFR],[CFG],[BCR],[BCL],[CER],[CEL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -66, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53601-XZ', latch1: '3511', latch2: '0', related: "D" },
          
            { wi: '10px', he: '10px',  left: -66, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23601-XZ', latch1: '3511', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -66, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13601-XZ', latch1: '3511', latch2: '0', related: "D" }, 
     
            { wi: '10px', he: '10px',  left: -66, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13601-XZ', latch1: '3511', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -66, top: 78, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23601-XZ', latch1: '3511', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -66, top: 95, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53601-XZ', latch1: '3511', latch2: '0', related: "D" },  
          
            // XZ FWD
            { wi: '10px', he: '10px',  left: -149, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53101-XZ', latch1: '2737', latch2: '0', related: "B" },
           
            { wi: '10px', he: '10px',  left: -149, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23101-XZ', latch1: '2737', latch2: '0', related: "B" },
            { wi: '10px', he: '10px',  left: -149, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13101-XZ', latch1: '2737', latch2: '0', related: "B" }, 
     
            { wi: '10px', he: '10px',  left: -149, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13101-XZ', latch1: '2737', latch2: '0', related: "B" },  
            { wi: '10px', he: '10px',  left: -149, top: 78, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23101-XZ', latch1: '2737', latch2: '0', related: "B" },  
            { wi: '10px', he: '10px',  left: -149, top: 95, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53101-XZ', latch1: '2737', latch2: '0', related: "B" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -137, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63200-YZ', latch1: '3695', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -121, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63300-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -106, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63400-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -91, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63500-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -76, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63600-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53


            { wi: '10px', he: '10px',left:  -137, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63200-YZ', latch1: '3695', latch2: '0', related: "BB" },  // 53
            { wi: '10px', he: '10px', left: -121, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63300-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -106, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63400-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px',left:  -91, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63500-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px',left:  -76, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63600-YZ', latch1: '3695', latch2: '0', related: "CC" }  // 53
            
        ]
    },
    { id: 21, position_name: 'DD', maxkg: 3696, harms: 739.1, harme: 983, harmstart: 22.039, harmend: 24.478, friends: "[D],[E],[CFR],[CFG],[CE],[CER],[CEL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ AFT
            { wi: '10px', he: '10px',  left: -133, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53740-XZ', latch1: '3511', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -133, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23740-XZ', latch1: '3511', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -133, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13740-XZ', latch1: '3511', latch2: '0', related: "E" }, 
     
            { wi: '10px', he: '10px',  left: -133, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13740-XZ', latch1: '3511', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -133, top: 78, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23740-XZ', latch1: '3511', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -133, top: 95, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53740-XZ', latch1: '3511', latch2: '0', related: "E" },  
          
            // XZ FWD
            { wi: '10px', he: '10px',  left: -216, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53601-XZ', latch1: '2737', latch2: '0', related: "C" },
            { wi: '10px', he: '10px',  left: -216, top: 17, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23601-XZ', latch1: '2737', latch2: '0', related: "C" },
            { wi: '10px', he: '10px',  left: -216, top: 35, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13601-XZ', latch1: '2737', latch2: '0', related: "C" }, 
            { wi: '10px', he: '10px',  left: -216, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13601-XZ', latch1: '2737', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: -216, top: 78, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23601-XZ', latch1: '2737', latch2: '0', related: "C" },  
            { wi: '10px', he: '10px',  left: -216, top: 95, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53601-XZ', latch1: '2737', latch2: '0', related: "C" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -200, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63700-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -185, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63710-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -170, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63720-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -155, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63730-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
       

            { wi: '10px', he: '10px',left:  -200, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63700-YZ', latch1: '3695', latch2: '0', related: "CC" },  // 53
            { wi: '10px', he: '10px', left: -185, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63710-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -170, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63720-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
            { wi: '10px', he: '10px',left:  -155, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63730-YZ', latch1: '3695', latch2: '0', related: "DD" },  // 53
        
        ]
    },
    { id: 22, position_name: 'EE', maxkg: 5670, harms: 985.5, harme: 1229.3, harmstart: 24.503, harmend: 26.941, friends: "[E],[F],[CFR],[CFG],[CE],[EF],[CER],[CEL],[EFR],[EFL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -282, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53740-XZ', latch1: '2737', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -282, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23740-XZ', latch1: '2737', latch2: '0', related: "D" },
            { wi: '10px', he: '10px',  left: -282, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13740-XZ', latch1: '2737', latch2: '0', related: "D" }, 
     
            { wi: '10px', he: '10px',  left: -282, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13740-XZ', latch1: '2737', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -282, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23740-XZ', latch1: '2737', latch2: '0', related: "D" },  
            { wi: '10px', he: '10px',  left: -282, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53740-XZ', latch1: '2737', latch2: '0', related: "D" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -197, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53921-XZ', latch1: '3511', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -197, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23921-XZ', latch1: '3511', latch2: '0', related: "F" },
            { wi: '10px', he: '10px',  left: -197, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13921-XZ', latch1: '3511', latch2: '0', related: "F" }, 
     
            { wi: '10px', he: '10px',  left: -197, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13921-XZ', latch1: '3511', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -197, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23921-XZ', latch1: '3511', latch2: '0', related: "F" },  
            { wi: '10px', he: '10px',  left: -197, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53921-XZ', latch1: '3511', latch2: '0', related: "F" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -266, top: -15, color: '#005967', border: '#47e6ff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63740-YZ', latch1: 'No reduction', latch2: 'No reduction with MD-M-LH-63740-YZ', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -252, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63800-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -238, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63900-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -224, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63910-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -210, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-63920-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
       

            { wi: '10px', he: '10px',left:  -266, top: 109, color: '#005967', border: '#47e6ff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63740-YZ', latch1: 'No reduction', latch2: 'No reduction with MD-M-RH-63740-YZ', related: "DD" },  // 53
            { wi: '10px', he: '10px', left: -252, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63800-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -238, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63900-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px',left:  -224, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63910-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px',left:  -210, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-63920-YZ', latch1: '4152', latch2: '0', related: "EE" },  // 53
        
        ]
    },
    { id: 23, position_name: 'FF', maxkg: 5670, harms: 1231.9, harme: 1475.7, harmstart: 26.966, harmend: 29.403, friends: "[F],[G],[CFR],[CFG],[FJR],[FJG],[EF],[FH],[EFR],[EFL],[FHR],[FHL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -349, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-53921-XZ', latch1: '2737', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -349, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-23921-XZ', latch1: '2737', latch2: '0', related: "E" },
            { wi: '10px', he: '10px',  left: -349, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-13921-XZ', latch1: '2737', latch2: '0', related: "E" }, 
     
            { wi: '10px', he: '10px',  left: -349, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-13921-XZ', latch1: '2737', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -349, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-23921-XZ', latch1: '2737', latch2: '0', related: "E" },  
            { wi: '10px', he: '10px',  left: -349, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-53921-XZ', latch1: '2737', latch2: '0', related: "E" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -265, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54101-XZ', latch1: '3511', latch2: '0', related: "G" },
            { wi: '10px', he: '10px',  left: -265, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24101-XZ', latch1: '3511', latch2: '0', related: "G" },
            { wi: '10px', he: '10px',  left: -265, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14101-XZ', latch1: '3511', latch2: '0', related: "G" }, 
     
            { wi: '10px', he: '10px',  left: -265, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14101-XZ', latch1: '3511', latch2: '0', related: "G" },  
            { wi: '10px', he: '10px',  left: -265, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24101-XZ', latch1: '3511', latch2: '0', related: "G" },  
            { wi: '10px', he: '10px',  left: -265, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54101-XZ', latch1: '3511', latch2: '0', related: "G" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -333, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64001-YZ', latch1: '5669', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -320, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64010-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -307, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64020-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -294, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64030-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -281, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64100-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
       

            { wi: '10px', he: '10px',left:  -333, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64001-YZ', latch1: '5669', latch2: '0', related: "EE" },  // 53
            { wi: '10px', he: '10px', left: -320, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64010-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -307, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64020-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px',left: -294, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64030-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px',left: -281, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64100-YZ', latch1: '5669', latch2: '0', related: "FF" },  // 53
        
        ]
    },
    { id: 24, position_name: 'GG', maxkg: 5670, harms: 1478.3, harme: 1722.1, harmstart: 29.431, harmend: 31.869, friends: "[G],[H],[FJR],[FJG],[FH],[FHR],[FHL]", heightx: '317.5' , legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -414, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54101-XZ', latch1: '2737', latch2: '0', related: "FF" },
            { wi: '10px', he: '10px',  left: -414, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24101-XZ', latch1: '2737', latch2: '0', related: "FF" },
            { wi: '10px', he: '10px',  left: -414, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14101-XZ', latch1: '2737', latch2: '0', related: "FF" }, 
     
            { wi: '10px', he: '10px',  left: -414, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14101-XZ', latch1: '2737', latch2: '0', related: "FF" },  
            { wi: '10px', he: '10px',  left: -414, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24101-XZ', latch1: '2737', latch2: '0', related: "FF" },  
            { wi: '10px', he: '10px',  left: -414, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54101-XZ', latch1: '2737', latch2: '0', related: "FF" },  
          
            // XZ AFT
            { wi: '10px', he: '10px',  left: -331, top: 0, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-RH-54600-XZ', latch1: '3511', latch2: '0', related: "H" },
            { wi: '10px', he: '10px',  left: -331, top: 18, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-RH-24600-XZ', latch1: '3511', latch2: '0', related: "H" },
            { wi: '10px', he: '10px',  left: -331, top: 36, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-RH-14600-XZ', latch1: '3511', latch2: '0', related: "H" }, 
     
            { wi: '10px', he: '10px',  left: -331, top: 55, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-LH-14600-XZ', latch1: '3511', latch2: '0', related: "H" },  
            { wi: '10px', he: '10px',  left: -331, top: 73, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-LH-24600-XZ', latch1: '3511', latch2: '0', related: "H" },  
            { wi: '10px', he: '10px',  left: -331, top: 92, color: '#ff7f00',border: '#fff', latchType: 'XZ Latch', latchCode: 'MD-M-LH-54600-XZ', latch1: '3511', latch2: '0', related: "H" },  
          
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -395, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64200-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -381, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64300-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -367, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64400-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -353, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64500-YZ', latch1: '4232', latch2: '0', related: "GG" },  // 53
       

            { wi: '10px', he: '10px',left:  -395, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64200-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -381, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64300-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px', left: -367, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64400-YZ', latch1: '4232', latch2: '0', related: "FF" },  // 53
            { wi: '10px', he: '10px',left: -353, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64500-YZ', latch1: '4232', latch2: '0', related: "GG" },  // 53
      
        ]
    },
    { id: 25, position_name: 'HH', maxkg: 5670, harms: 1724.6, harme: 1968.5, harmstart: 31.894, harmend: 34.332, friends: "[H],[J],[FH],[HJ],[FJR],[FJG],[JLR],[HJ],[HJR],[HJL],[FHR],[FHL]", heightx: '317.5', legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -482, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-54600-XZ', latch1: '3511', latch2: '0', related: "GG" },
            { wi: '10px', he: '10px',  left: -482, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-24600-XZ', latch1: '3511', latch2: '0', related: "GG" },
            { wi: '10px', he: '10px',  left: -482, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-14600-XZ', latch1: '3511', latch2: '0', related: "GG" }, 
         
            { wi: '10px', he: '10px',  left: -482, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-14600-XZ', latch1: '3511', latch2: '0', related: "GG" },  
            { wi: '10px', he: '10px',  left: -482, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-24600-XZ', latch1: '3511', latch2: '0', related: "GG" },  
            { wi: '10px', he: '10px',  left: -482, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-54600-XZ', latch1: '3511', latch2: '0', related: "GG" },  
              
            // XZ AFT
            { wi: '10px', he: '10px',  left: -396, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55001-XZ', latch1: '3511', latch2: '0', related: "I" },
            { wi: '10px', he: '10px',  left: -396, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25001-XZ', latch1: '3511', latch2: '0', related: "I" },
            { wi: '10px', he: '10px',  left: -396, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15001-XZ', latch1: '3511', latch2: '0', related: "I" }, 
         
            { wi: '10px', he: '10px',  left: -396, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15001-XZ', latch1: '3511', latch2: '0', related: "I" },  
            { wi: '10px', he: '10px',  left: -396, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25001-XZ', latch1: '3511', latch2: '0', related: "I" },  
            { wi: '10px', he: '10px',  left: -396, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55001-XZ', latch1: '3511', latch2: '0', related: "I" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -466, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64600-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -452, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64700-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -438, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64800-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -424, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-64900-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -410, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65000-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
    
            { wi: '10px', he: '10px',left:  -466, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64600-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -452, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64700-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px', left: -438, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64800-YZ', latch1: '5669', latch2: '0', related: "GG" },  // 53
            { wi: '10px', he: '10px',left:  -424, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-64900-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px',left:  -410, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65000-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
        ]
    },
    { id: 26, position_name: 'JJ', maxkg: 5670, harms: 1971, harme: 2214.9, harmstart: 34.358, harmend: 36.797, friends: "[J],[K],[JLR],[JLG],[HJ],[JK],[HJR],[HJL],[JKR],[JKL]", heightx: '317.5', 
        legend_name: "legend03",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -546, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55001-XZ', latch1: '3511', latch2: '0', related: "HH" },
            { wi: '10px', he: '10px',  left: -546, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25001-XZ', latch1: '3511', latch2: '0', related: "HH" },
            { wi: '10px', he: '10px',  left: -546, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15001-XZ', latch1: '3511', latch2: '0', related: "HH" }, 
         
            { wi: '10px', he: '10px',  left: -546, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15001-XZ', latch1: '3511', latch2: '0', related: "HH" },  
            { wi: '10px', he: '10px',  left: -546, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25001-XZ', latch1: '3511', latch2: '0', related: "HH" },  
            { wi: '10px', he: '10px',  left: -546, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55001-XZ', latch1: '3511', latch2: '0', related: "HH" },  
              
            // XZ AFT
            { wi: '10px', he: '10px',  left: -462, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55321-XZ', latch1: '3330', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -462, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25321-XZ', latch1: '3330', latch2: '0', related: "J" },
            { wi: '10px', he: '10px',  left: -462, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15321-XZ', latch1: '3330', latch2: '0', related: "J" }, 
         
            { wi: '10px', he: '10px',  left: -462, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15321-XZ', latch1: '3330', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -462, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25321-XZ', latch1: '3330', latch2: '0', related: "J" },  
            { wi: '10px', he: '10px',  left: -462, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55321-XZ', latch1: '3330', latch2: '0', related: "J" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -531, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65100-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -517, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65200-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -503, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65300-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -489, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65310-YZ', latch1: '5669', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -475, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65320-YZ', latch1: '5669', latch2: '0', related: "JJ" },  // 53
    
            { wi: '10px', he: '10px',left:  -531, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65100-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -517, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65200-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px', left: -503, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65300-YZ', latch1: '5669', latch2: '0', related: "HH" },  // 53
            { wi: '10px', he: '10px',left:  -489, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65310-YZ', latch1: '5669', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px',left:  -475, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65320-YZ', latch1: '5669', latch2: '0', related: "JJ" },  // 53
        ]
    },
    { id: 27, position_name: 'KK', maxkg: 4822, harms: 2219.9, harme: 2463.8, harmstart: 36.847, harmend: 39.286, friends: "[K],[L],[LPR],[JLG],[KM],[KMR],[KML],[JLR]", heightx: '317.5', legend_name: "legend01",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -615, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55321-XZ', latch1: '3155', latch2: '0', related: "JJ" },
            { wi: '10px', he: '10px',  left: -615, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25321-XZ', latch1: '3155', latch2: '0', related: "JJ" },
            { wi: '10px', he: '10px',  left: -615, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15321-XZ', latch1: '3155', latch2: '0', related: "JJ" }, 
         
            { wi: '10px', he: '10px',  left: -615, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15321-XZ', latch1: '3155', latch2: '0', related: "JJ" },  
            { wi: '10px', he: '10px',  left: -615, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25321-XZ', latch1: '3155', latch2: '0', related: "JJ" },  
            { wi: '10px', he: '10px',  left: -615, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55321-XZ', latch1: '3155', latch2: '0', related: "JJ" },  
              
            // XZ AFT
            { wi: '15px', he: '10px',  left: -529, top: 0, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-55371-XZ', latch1: '2624', latch2: '0', related: "K" },
            { wi: '15px', he: '10px',  left: -529, top: 18, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-25731-XZ', latch1: '2624', latch2: '0', related: "K" },
            { wi: '15px', he: '10px',  left: -529, top: 36, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-15371-XZ', latch1: '2624', latch2: '0', related: "K" }, 
         
            { wi: '15px', he: '10px',  left: -529, top: 55, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-15371-XZ', latch1: '2624', latch2: '0', related: "K" },  
            { wi: '15px', he: '10px',  left: -529, top: 73, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-25371-XZ', latch1: '2624', latch2: '0', related: "K" },  
            { wi: '15px', he: '10px',  left: -529, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-55371-XZ', latch1: '2624', latch2: '0', related: "K" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -595, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65330-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -581, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65340-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -567, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65350-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -553, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65360-YZ', latch1: '3728', latch2: '0', related: "KK" },  // 53
           
            { wi: '10px', he: '10px',left:  -595, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65330-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -581, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65340-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px', left: -567, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65350-YZ', latch1: '3728', latch2: '0', related: "JJ" },  // 53
            { wi: '10px', he: '10px',left:  -553, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65360-YZ', latch1: '3728', latch2: '0', related: "KK" },  // 53
       ]
    },
    { id: 28, position_name: 'LL', maxkg: 4400, harms: 2466.3, harme: 2710.2, harmstart: 39.311, harmend: 41.750, friends: "[L],[M],[LPR],[KM],[MP],[KMR],[KML],[MPR],[MPL]", heightx: '317.5', 
        legend_name: "legend01",
        boxes: [
            // XZ FWD
            { wi: '15px', he: '10px',  left: -684, top: 0, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55370-XZ', latch1: '4399', latch2: '0', related: "KK" },
            { wi: '15px', he: '10px',  left: -684, top: 18, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25370-XZ', latch1: '4399', latch2: '0', related: "KK" },
            { wi: '15px', he: '10px',  left: -684, top: 36, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15370-XZ', latch1: '4399', latch2: '0', related: "KK" }, 
         
            { wi: '15px', he: '10px',  left: -684, top: 55, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15370-XZ', latch1: '4399', latch2: '0', related: "KK" },  
            { wi: '15px', he: '10px',  left: -684, top: 73, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25370-XZ', latch1: '4399', latch2: '0', related: "KK" },  
            { wi: '15px', he: '10px',  left: -684, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55370-XZ', latch1: '4399', latch2: '0', related: "KK" },  
              
            // XZ AFT
            { wi: '10px', he: '10px',  left: -595, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55501-XZ', latch1: '3511', latch2: '0', related: "L" },
            { wi: '10px', he: '10px',  left: -595, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25501-XZ', latch1: '3511', latch2: '0', related: "L" },
            { wi: '10px', he: '10px',  left: -595, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15501-XZ', latch1: '3511', latch2: '0', related: "L" }, 
         
            { wi: '10px', he: '10px',  left: -595, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15501-XZ', latch1: '3511', latch2: '0', related: "L" },  
            { wi: '10px', he: '10px',  left: -595, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25501-XZ', latch1: '3511', latch2: '0', related: "L" },  
            { wi: '10px', he: '10px',  left: -595, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55501-XZ', latch1: '3511', latch2: '0', related: "L" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -659, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65380-YZ', latch1: '5669', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -645, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65390-YZ', latch1: '5669', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -631, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65400-YZ', latch1: '5669', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -617, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65500-YZ', latch1: '5669', latch2: '0', related: "LL" },  // 53
        
            { wi: '10px', he: '10px',left:  -659, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65380-YZ', latch1: '4399', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -645, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65390-YZ', latch1: '4399', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px', left: -631, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65400-YZ', latch1: '4399', latch2: '0', related: "KK" },  // 53
            { wi: '10px', he: '10px',left:  -617, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65500-YZ', latch1: '4399', latch2: '0', related: "LL" },  // 53
       ]
    },
    { id: 29, position_name: 'MM', maxkg: 4091, harms: 2712.7, harme: 2956.5, harmstart: 41.775, harmend: 44.213, friends: "[P],[LPR],[MP],[MPR],[MPL],[PRR],[PRL]", heightx: '317.5',  legend_name: "legend01",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -747, top: 0, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-55501-XZ', latch1: '2638', latch2: '0', related: "LL" },
            { wi: '10px', he: '10px',  left: -747, top: 18, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-25501-XZ', latch1: '2638', latch2: '0', related: "LL" },
            { wi: '10px', he: '10px',  left: -747, top: 36, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-15501-XZ', latch1: '2638', latch2: '0', related: "LL" }, 
         
            { wi: '10px', he: '10px',  left: -747, top: 55, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-15501-XZ', latch1: '2638', latch2: '0', related: "LL" },  
            { wi: '10px', he: '10px',  left: -747, top: 73, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-25501-XZ', latch1: '2638', latch2: '0', related: "LL" },  
            { wi: '10px', he: '10px',  left: -747, top: 92, color: '#ff7f00', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-55501-XZ', latch1: '2638', latch2: '0', related: "LL" },  
              
            // XZ AFT
            { wi: '15px', he: '10px',  left: -662, top: 0, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-RH-56000-XZ', latch1: '1886', latch2: '0', related: "M" },
            { wi: '15px', he: '10px',  left: -662, top: 18, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-RH-26000-XZ', latch1: '1886', latch2: '0', related: "M" },
            { wi: '15px', he: '10px',  left: -662, top: 36, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-RH-16000-XZ', latch1: '1886', latch2: '0', related: "M" }, 
         
            { wi: '15px', he: '10px',  left: -662, top: 55, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-LH-16000-XZ', latch1: '1886', latch2: '0', related: "M" },  
            { wi: '15px', he: '10px',  left: -662, top: 73, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-LH-26000-XZ', latch1: '1886', latch2: '0', related: "M" },  
            { wi: '15px', he: '10px',  left: -662, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ Triple-Latch', latchCode: 'MD-M-LH-56000-XZ', latch1: '1886', latch2: '0', related: "M" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -723, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65600-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -709, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65700-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -695, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65800-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -681, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-65900-YZ', latch1: '3390', latch2: '0', related: "MM" },  // 53


            { wi: '10px', he: '10px',left:  -723, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65600-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -709, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65700-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px', left: -695, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65800-YZ', latch1: '3390', latch2: '0', related: "LL" },  // 53
            { wi: '10px', he: '10px',left:  -681, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-65900-YZ', latch1: '3390', latch2: '0', related: "MM" },  // 53
       ]
    },
    { id: 30, position_name: 'PP', maxkg: 4048, harms: 2959.1, harme: 3202.9, harmstart: 44.239, harmend: 46.677, friends: "[R],[PRR],[PRL]", heightx: '317.5',legend_name: "legend04",
        boxes: [
            // XZ FWD
            { wi: '10px', he: '10px',  left: -815, top: 0, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-56100-XZ', latch1: '3511', latch2: '0', related: "MM" },
            { wi: '10px', he: '10px',  left: -815, top: 18, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-26100-XZ', latch1: '3511', latch2: '0', related: "MM" },
            { wi: '10px', he: '10px',  left: -815, top: 36, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-16100-XZ', latch1: '3511', latch2: '0', related: "MM" }, 
         
            { wi: '10px', he: '10px',  left: -815, top: 55, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-16100-XZ', latch1: '3511', latch2: '0', related: "MM" },  
            { wi: '10px', he: '10px',  left: -815, top: 73, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-26100-XZ', latch1: '3511', latch2: '0', related: "MM" },  
            { wi: '10px', he: '10px',  left: -815, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-56100-XZ', latch1: '3511', latch2: '0', related: "MM" },  
              
            // XZ AFT
            { wi: '10px', he: '10px',  left: -726, top: 0, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-56201-XZ', latch1: '3330', latch2: '0', related: "O" },
            { wi: '10px', he: '10px',  left: -726, top: 18, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-26201-XZ', latch1: '3330', latch2: '0', related: "O" },
            { wi: '10px', he: '10px',  left: -726, top: 36, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-RH-16201-XZ', latch1: '3330', latch2: '0', related: "O" }, 
         
            { wi: '10px', he: '10px',  left: -726, top: 55, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-16201-XZ', latch1: '3330', latch2: '0', related: "O" },  
            { wi: '10px', he: '10px',  left: -726, top: 73, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-26201-XZ', latch1: '3330', latch2: '0', related: "O" },  
            { wi: '10px', he: '10px',  left: -726, top: 92, color: '#979d2d', border: '#fff', latchType: 'XZ-Latch', latchCode: 'MD-M-LH-56201-XZ', latch1: '3330', latch2: '0', related: "O" },  
              
            // YZ LEFT RIGHT
            { wi: '10px', he: '10px', left: -792, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-66900-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -778, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67000-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -764, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67100-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -750, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-67110-YZ', latch1: '5669', latch2: '0', related: "PP" },  // 53
        
            { wi: '10px', he: '10px',left:  -792, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-66900-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -778, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67000-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px', left: -764, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67100-YZ', latch1: '5669', latch2: '0', related: "MM" },  // 53
            { wi: '10px', he: '10px',left:  -750, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-67110-YZ', latch1: '5669', latch2: '0', related: "PP" },  // 53
      ]
    },
    { id: 31, position_name: 'RR', maxkg: 4048, harms: 3205.5, harme: 3449.3, harmstart: 46.703, harmend: 49.141, friends: "[S]", heightx: '317.5' },
    { id: 32, position_name: 'SS', maxkg: 3389, harms: 3451.8, harme: 3695.7, harmstart: 49.166, harmend: 51.605, friends: "[T]", heightx: '317.5' },
    { id: 33, position_name: 'TT', maxkg: 2809, harms: 3698.2, harme: 3942.1, harmstart: 51.630, harmend: 54.069, friends: "[U]", heightx: '317.5' }
];


const data3 = [
    { id: 34, group: 'center', position_name: 'AB', maxkg: 3429, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[B],[AA],[BB],[ABR],[ABL]", legend_name: "legend01", heightx: '244',
        boxes: [
            { wi: '10px', he: '10px',  left: -15, top: 5, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABR" },
            { wi: '10px', he: '10px',  left: -15, top: 30, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 8
            { wi: '10px', he: '10px',  left: -15, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 17
            { wi: '10px', he: '10px',  left: -15, top: 85, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR ABL" },  // 26
     

            { wi: '10px', he: '10px',  left: 138, top: 5, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABR" },
            { wi: '10px', he: '10px',  left: 138, top: 30, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 8
            { wi: '10px', he: '10px',  left: 138, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 17
            { wi: '10px', he: '10px',  left: 138, top: 85, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR ABL" },  // 26
     

            { wi: '10px', he: '10px', left: 5, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 29, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 50, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 71, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 96, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 120, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
           
           
            { wi: '10px', he: '10px', left: 5, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 29, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 50, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 71, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 96, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 120, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
           
        ]
    },
    { id: 35, position_name: 'BC', maxkg: 3941, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[C],[BB],[CC],[BCR],[BCL]", heightx: '244' },
    { id: 36, position_name: 'CE', maxkg: 4001, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CER],[CEL]", heightx: '244' },
    { id: 37, position_name: 'EF', maxkg: 6804, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[CFR],[CFG],[EFR],[EFL],[FJR],[FJG]", heightx: '244' },
    { id: 38, position_name: 'FH', maxkg: 6804, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[FJR],[FJG],[FHR],[FHL]", heightx: '244' },
    { id: 39, position_name: 'HJ', maxkg: 6804, harms: 1600.2, harme: 1917.7, harmstart: 30.65, harmend: 33.825, friends: "[H],[J],[GG],[HH],[FJR],[FJG],[JLR],[JLG],[HJR],[HJL]", heightx: '244' },
    { id: 40, position_name: 'JK', maxkg: 6804, harms: 1920.2, harme: 2237.7, harmstart: 33.85, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JLG],[JKR],[JKL]", heightx: '244' },
    { id: 41, position_name: 'KM', maxkg: 4868, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[JLG],[LPR],[KMR],[KML]", heightx: '244' },
    { id: 42, position_name: 'MP', maxkg: 4445, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MPR],[MPL]", heightx: '244' }
];

const data4 = [
    { id: 43, position_name: 'CFR', maxkg: 7729, harms: 647.1, harme: 1144.9, harmstart: 21.119, harmend: 26.097, friends: "[C],[D],[E],[DD],[EE],[FF],[CE],[CFG],[CER],[CEL]", heightx: '317.5',
        boxes: [
            { left: -5, top: -1, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABR" },
            { left: -5, top: -1 + 9, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 8
            { left: -5, top: -1 + 9 * 2, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 17
            { left: -5, top: -1 + 9 * 3, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR ABL" },  // 26
            { left: -5, top: -1 + 9 * 4, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABL" },  // 35
            { left: -5, top: -1 + 9 * 5, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABL" },  // 44
            { left: -5, top: -1 + 9 * 6, color: '#ff7f00', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-LH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABL" },  // 53
          

            { left: 61, top: -1, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-52401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B , BB"  },
            { left: 61, top: -1 + 9, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-22401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B, BB" },  // 8
            { left: 61, top: -1 + 9 * 2, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-RH-12401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B, BB" },  // 17
            { left: 61, top: 33, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-12401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B , BB" },  // 26
            { left: 61, top: 44, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-22401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B, BB" },  // 35
            { left: 61, top: 53, color: '#ffc107', latchType: 'XZ-Double Latch', latchCode: 'MD-M-LH-52401-XZ', latch1: '2624', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, B, BB" },  // 44


            { left: 2, top: -5, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 17, top: -5, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 33, top: -5, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 49, top: -5, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 2, top: 57, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62100-YZ', latch1: '1824', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 17, top: 57, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62200-YZ', latch1: '1824', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 33, top: 57, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62300-YZ', latch1: '1824', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" },  // 53
            { left: 49, top: 57, color: '#17a2b8', latchType: 'YZ-Latch', latchCode: 'MD-M-LH-62400-YZ', latch1: '1824', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A" }  // 53
            
        ]
    },
    { id: 44, position_name: 'FJR', maxkg: 10668, harms: 1265.5, harme: 1763.4, harmstart: 27.303, harmend: 32.282, friends: "[F],[G],[H],[FF],[GG],[HH],[EE],[EF],[EFR],[EFL],[FH],[FHR],[FHL]", heightx: '317.5' },
    { id: 45, position_name: 'JLR', maxkg: 10668, harms: 1776.8, harme: 2274.6, harmstart: 32.416, harmend: 37.394, friends: "[H],[J],[K],[HH],[JJ],[KK],[JLG],[JK],[HJR],[HJL],[JKR],[JKL]", heightx: '317.5' },
    { id: 46, position_name: 'LPR', maxkg: 6236, harms: 2287.9, harme: 2785.7, harmstart: 37.527, harmend: 42.505, friends: "[K],[L],[M],[LL],[MM],[KK],[L],[KM],[MP],[KMR],[KML]", heightx: '317.5' }
];

const data5 = [
    { id: 47, position_name: 'CFG', maxkg: 10602, harms: 644.1, harme: 1250, harmstart: 21.089, harmend: 27.148, friends: "[C],[D],[E],[DD],[EE],[FF],[CE],[CFR],[CER],[CEL]", heightx: '317.5' },
    { id: 48, position_name: 'FJG', maxkg: 11340, harms: 1262.7, harme: 1868.5, harmstart: 27.275, harmend: 33.333, friends: "[F],[G],[H],[FF],[GG],[HH],[EE],[EF],[EFR],[EFL],[FH],[FHR],[FHL]", heightx: '317.5' },
    { id: 49, position_name: 'JLG', maxkg: 11340, harms: 1881.1, harme: 2486.9, harmstart: 33.459, harmend: 39.517, friends: "[H],[J],[K],[HH],[JJ],[KK],[JLR],[JK],[HJR],[HJL],[JKR],[JKL]", heightx: '317.5' }
];

const data6 = [
    { id: 340, group:'center', position_name: 'ABL', maxkg: 2000, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[AA],[AB],[ABR]", legend_name: "legend01", heightx: '224',
        boxes: [
            { wi: '10px', he: '10px',  left: -15, top: 5, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABR" },
            { wi: '10px', he: '10px',  left: -15, top: 30, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 8
            { wi: '10px', he: '10px',  left: -15, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 17
            { wi: '10px', he: '10px',  left: -15, top: 85, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR ABL" },  // 26
     

            { wi: '10px', he: '10px',  left: 138, top: 5, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-52001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, ABR" },
            { wi: '10px', he: '10px',  left: 138, top: 30, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-22001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 8
            { wi: '10px', he: '10px',  left: 138, top: 60, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-RH-12001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR" },  // 17
            { wi: '10px', he: '10px',  left: 138, top: 85, color: '#ff7f00',border: '#fff', latchType: 'XZ-Latch (End Stop)', latchCode: 'MD-M-AD-02001-XZ', latch1: '2297', latch2: '0 | The affected ULD position must remain unoccupied.', related: "A, AA, AB, ABR ABL" },  // 26
     

            { wi: '10px', he: '10px', left: 5, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 29, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 50, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 71, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 96, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 120, top: -15, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
           
           
            { wi: '10px', he: '10px', left: 5, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62100-YZ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 29, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62200-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 50, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62300-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 71, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 96, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
            { wi: '10px', he: '10px', left: 120, top: 109, color: '#17a2b8', border: '#fff', latchType: 'YZ-Latch', latchCode: 'MD-M-RH-62400-YZ ', latch1: '3079', latch2: '0 | The affected ULD position must remain unoccupied.', related: "-" },  // 53
           
        ]
    },
    { id: 350, position_name: 'BCL', maxkg: 2358, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[BB],[C],[CC],[BC],[BCR]", heightx: '224' },
    { id: 360, position_name: 'CEL', maxkg: 2400, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CER]", heightx: '224' },
    { id: 370, position_name: 'EFL', maxkg: 3825, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[EFR],[EFL],[FH]", heightx: '224' },
    { id: 380, position_name: 'FHL', maxkg: 4321, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[HH],[FHR],[FHL]", heightx: '224' },
    { id: 390, position_name: 'HJL', maxkg: 4321, harms: 1600.2, harme: 1917.7, harmstart: 30.650, harmend: 33.825, friends: "[H],[J],[GG],[HH],[JLR],[JLG],[HJR]", heightx: '224' },
    { id: 400, position_name: 'JKL', maxkg: 4321, harms: 1920.2, harme: 2237.7, harmstart: 33.850, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JK],[HJR],[HJL],[JKR]", heightx: '224' },
    { id: 410, position_name: 'KML', maxkg: 2964, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[LPR],[KMR]", heightx: '224' },
    { id: 420, position_name: 'MPL', maxkg: 2756, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MPR]", heightx: '224' },
    { id: 430, position_name: 'PRL', maxkg: 2629, harms: 2885.4, harme: 3202.9, harmstart: 43.502, harmend: 46.677, friends: "[P],[R],[MM],[LPR],[PRR]", heightx: '224' }
];

const data7 = [
    { id: 3400, position_name: 'ABR', maxkg: 2000, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[B],[AA],[BB],[AB]", heightx: '224' },
    { id: 3500, position_name: 'BCR', maxkg: 2358, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[C],[BB],[CC],[BC]", heightx: '224' },
    { id: 3600, position_name: 'CER', maxkg: 2400, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CE]", heightx: '224' },
    { id: 3700, position_name: 'EFR', maxkg: 3825, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[CFR],[CFG],[EF]", heightx: '224' },
    { id: 3800, position_name: 'FHR', maxkg: 4321, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[FJR],[FJG],[FH]", heightx: '224' },
    { id: 3900, position_name: 'HJR', maxkg: 4321, harms: 1600.2, harme: 1917.7, harmstart: 30.65, harmend: 33.825, friends: "[H],[J],[GG],[HH],[FJR],[FJG],[JLR],[JLG],[HJ]", heightx: '224' },
    { id: 4000, position_name: 'JKR', maxkg: 4321, harms: 1920.2, harme: 2237.7, harmstart: 33.85, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JLG],[JK]", heightx: '224' },
    { id: 4100, position_name: 'KMR', maxkg: 2964, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[JLG],[LPR],[KM]", heightx: '224' },
    { id: 4200, position_name: 'MPR', maxkg: 2756, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MP]", heightx: '224' },
    { id: 4300, position_name: 'PRR', maxkg: 2629, harms: 2885.4, harme: 3202.9, harmstart: 43.502, harmend: 46.677, friends: "[P],[R],[MM],[PP]", heightx: '224' }
];

export { data1, data2, data3, data4, data5, data6, data7 };