import React, { useContext,useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import MobileDetect from 'mobile-detect';
import { data1, data2, data3, data4, data5, data6, data7 } from '../datas/kilitData';
import { AppContext } from './AppContext';

const darkTheme = {
    backgroundColor: '#121212',
    color: '#ffffff',
    borderColor: '#333333',
    transition: 'all 0.3s ease-in-out',
};

const containerWidth = 1000; // Set the container width in pixels


const KilitMd = ({ onBoxClick }) => {
    const [selectedPositions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showLegend, setShowLegend] = useState(true); // `Legend`'in gösterilip gösterilmeyeceğini kontrol eden state
    const [showInfo, setShowInfo] = useState(false); // `Latch Information`'ın gösterilip gösterilmediğini kontrol eden state
  
    const { setBackPath, setSubtitle, setOnBackClick, setHasSelection } = useContext(AppContext); // `setHasSelection` fonksiyonunu ekliyoruz


    useEffect(() => {
        setOnBackClick(() => handleBackClick); // handleBackClick fonksiyonunu context'e ekliyoruz
        return () => {
          setOnBackClick(null); // Bileşen unmount edildiğinde onBackClick fonksiyonunu sıfırlıyoruz
          setHasSelection(false); // Seçim durumunu sıfırlıyoruz
        };
      }, []);
    
    

    const allData = [...data1, ...data2, ...data3, ...data4, ...data5, ...data6, ...data7];
    const maxHarme = Math.max(...allData.map(item => item.harme));
    const maxHarms = Math.max(...allData.map(item => item.harms));

    const scaleHarme = containerWidth / maxHarme;
    const scaleHarms = containerWidth / maxHarms;

    const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
const md = new MobileDetect(userAgent);

// Cihaz türünü kontrol etme
//const isMobile = md.mobile(); // Mobil cihaz mı? (Telefon veya Tablet)
const isPhone = md.phone();   // Sadece telefon mu?
//const isTablet = md.tablet();  // Sadece tablet mi?
//const isDesktop = !isMobile;   // Masaüstü cihaz mı?
//console.log(isPhone);


const [screenWidth, setScreenWidth] = useState(window.innerWidth);
const [screenHeight, setScreenHeight] = useState(window.innerHeight);
const [leftScale, setLeftScale] = useState(1);
const [widthScale, setWidthScale] = useState(1);
const myScale = 1.76; // Sabit ölçek değeri
const myScaleh = 1.2; // Sabit ölçek değeri
const myScaleh2 = 1.2; // Sabit ölçek değeri
const heightScale2 = screenHeight / 1080; 
const heightScale = screenHeight / 1080; 


    const renderBoxes = (item) => {
        return item.boxes.map((box, index) => (
            <div
                key={index}
                style={{
                    position: 'absolute',
                    top: `${box.top}px`,
                    borderRadius:'2px',
                    left: `${item.harms * scaleHarms + box.left}px`,
                    borderColor : selectedBox && selectedBox === box ? '#a1f7a1' : box.border, // Varsayılan çerçeve rengi
                    border: `1px solid`,
                    width: selectedBox && selectedBox === box ? box.wi : box.wi,
                    height: selectedBox && selectedBox === box ? box.he : box.he,
                    backgroundColor: selectedBox && selectedBox === box ? '#005700' : box.color,
                    zIndex: 5,
                }}
                onClick={(e) => {
                    e.stopPropagation(); // Stop click event propagation
                    setSelectedBox(box);
                }} 
            />
        ));
    };


    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
          setScreenHeight(window.innerHeight);
      
          // Ekran genişliğine ve yüksekliğine göre dinamik ölçekleme yapıyoruz
          setLeftScale(window.innerWidth / 1920);
          setWidthScale(window.innerWidth / 1920);
        };
      
        // Sayfa ilk açıldığında handleResize'i çağırıyoruz
        handleResize();
      
        // `resize` event listener'ı ekleyip kaldırma
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    
    const getBoxStyle = (item) => {

        const itemFriends = parseFriends(item.friends);
        let backgroundColor = '#333333'; // Varsayılan arka plan rengi
        let borderColor = '#666666'; // Varsayılan çerçeve rengi
        let textColor = '#ffffff'; // Varsayılan yazı rengi
        let zIndex = 1; // Varsayılan z-index değeri
    
        if (selectedItem && selectedItem.position_name !== item.position_name) {
            return { display: 'none' };
        }
    
   

        if (selectedItem && selectedItem.position_name === item.position_name  && selectedItem.group === 'center') {
            return {
               
                position: 'absolute',
                top: '35%',
                left: '50%',
                transform: 'translate(-50%, -50%) scale(2)',
                width: `135px`,
                textAlign: 'center',
                border: '1px solid #555', // Daha koyu bir sınır rengi
                padding: '10px',
                boxSizing: 'border-box',
                backgroundColor: '#333', // Koyu arkaplan rengi
                color: '#fff', // Beyaz yazı rengi
                fontWeight: 'bold', // Yazı kalınlığı arttırıldı
                fontSize: '24px', // Yazı boyutu büyütüldü
                zIndex: 4,
                transition: 'transform 0.5s ease, background-color 0.5s ease', // Animasyon eklendi
               
            };
        }else if(selectedItem && selectedItem.position_name === item.position_name) {
            return {
               
                position: 'absolute',
                top: '35%',
                left: '50%',
                transform: 'translate(-50%, -50%) scale(2)',
                width: `${(item.harme - item.harms) * scaleHarme}px`,
                textAlign: 'center',
                border: '1px solid #555', // Daha koyu bir sınır rengi
                padding: '10px',
                boxSizing: 'border-box',
                backgroundColor: '#333', // Koyu arkaplan rengi
                color: '#fff', // Beyaz yazı rengi
                fontWeight: 'bold', // Yazı kalınlığı arttırıldı
                fontSize: '24px', // Yazı boyutu büyütüldü
                zIndex: 4,
                transition: 'transform 0.5s ease, background-color 0.5s ease', // Animasyon eklendi
            };
        }
    
        if (selectedPositions.includes(item.position_name)) {
            const index = selectedPositions.indexOf(item.position_name);
            backgroundColor = index === 0 ? '#adffad' : '#ffd452';
        } else if (selectedPositions.length > 0 && selectedPositions.some(pos => itemFriends.includes(pos))) {
            backgroundColor = '#ddd';
        }
    
        if (selectedBox && selectedBox.position_name === item.position_name) {
            backgroundColor = 'green';
        }

         // Pure Mobile
  if (isPhone !== null) {
    const mobileHeightScale = screenHeight / 667; // Örnek bir ölçek değeri, temel genişliği 375px olarak kabul ediyoruz
    const mobileScale  = screenWidth / 375; // Örnek bir yükseklik ölçeği, temel yüksekliği 667px olarak kabul ediyoruz
    const mytScale = 2.4;
       // top: `${item.harms * scaleHarms * mobileHeightScale}px`, // Ekran yüksekliğine göre dinamik top değeri
    //left: `${item.harme * scaleHarme * mobileScale}px`, // Ekran genişliğine göre dinamik left değeri
    //width: `${(item.harme - item.harms) * scaleHarme * mobileScale}px`, // Dinamik width değeri
    //height: `${(item.heightx) * scaleHarms * mobileHeightScale}px`, // Dinamik height değeri

    
    return {
      ...darkTheme,
      position: 'absolute',
      top: `${item.harms * scaleHarms * mobileHeightScale/ mytScale}px`,
      left: `${item.top}`,
      height:  `${(item.harme - item.harms) * scaleHarme * mobileScale / mytScale}px`,
      width: `${(item.heightx) * scaleHarms * mobileHeightScale/mytScale}px`,
      textAlign: 'center',
      border: `2px solid ${borderColor}`,
      minWidth: '44px',
      padding: '5px',
      boxSizing: 'border-box',
      backgroundColor,
      color: textColor,
      transition: 'all 0.3s ease-in-out',
      fontSize: '14px', // `isPhone` için fontSize 14px olarak ayarlandı
      fontWeight: '600',
      fontFamily: 'Roboto, Arial, sans-serif',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex,
    };
  }

  // Desktop
  return {
    ...darkTheme,
    position: 'absolute',
    top: item.top,
    left: `${(item.harms * scaleHarms * leftScale * myScale)+20}px`, // Ekran genişliğine göre ölçeklendirilmiş left değeri
    width: `${(item.harme - item.harms) * scaleHarme * widthScale * myScale}px`, // Ekran genişliğine göre ölçeklendirilmiş width değeri
    height: `${(item.heightx) * scaleHarms * heightScale * myScaleh}px`, // heightScale ve diğer ölçek faktörleri kullanılarak hesaplanır.
    textAlign: 'center',
    border: `2px solid ${borderColor}`,
    boxSizing: 'border-box',
    padding: '10px',
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor, // Set background color based on position_name
    zIndex,
  };

    };

    const parseFriends = (friends) => {
        return friends
            .replace(/[\[\]]/g, '') 
            .split(',') 
            .map(friend => friend.trim()); 
    };

    const [selectedBox, setSelectedBox] = useState(null);

    const handleBoxClick = (item) => {
        // Eğer herhangi bir box seçilmemişse veya seçilen box farklı bir item ise
        if (!selectedBox) {
          setSelectedItem(item); // Seçili öğeyi güncelle
          setSubtitle(`Position: ${item.position_name}`); // Başlığı güncelle
          setBackPath(null);
          setHasSelection(true);
          setShowInfo(false); // Info'yu aç

          setShowLegend(true); // Legend'ı gizle
        } else {
          // Eğer aynı kutu tekrar seçildiyse Info'yu tekrar aç ama Legend'ı gizle
          setShowInfo(true);
          setShowLegend(false);
        }
      };
    
    const handleInfoClose = () => {
        setShowInfo(false); // Info'yu kapat
        setSelectedBox(null); // Seçili kutuyu sıfırla
        setShowLegend(true); // Legend'ı tekrar göster
      };


      const handleBackClick = () => {
        // Seçili item ve kutuları sıfırlıyoruz
        setSelectedItem(null);
        setSelectedBox(null); // Seçili kutuyu sıfırla
        setSubtitle('');
        setBackPath(null);
        setHasSelection(false);
        setShowInfo(false); // Info'yu kapat
        setShowLegend(true); // Legend'ı tekrar göster (setShowLegend(true) yap)
      };

    
     
      useEffect(() => {
        if (selectedBox) {
          setShowInfo(true); // selectedBox güncellenirse Info'yu aç
        } else {
          setShowInfo(false); // selectedBox yoksa Info'yu kapat
        }
      }, [selectedBox]); // selectedBox değiştikçe çalıştır


      useEffect(() => {
        setShowLegend(!showInfo); // showInfo true ise showLegend false olur
      }, [showInfo]);


      useEffect(() => {
        if (showInfo) {
          setShowLegend(false); // showInfo açıkken showLegend gizle
        } else if (!selectedBox) {
          setShowLegend(true); // showInfo kapalı ve seçili kutu yoksa showLegend göster
        }
      }, [showInfo, selectedBox]);


      // Legend değerine göre içerik render etme
  const renderLegendItems = () => {
    if (!selectedItem) return null;

    switch (selectedItem.legend_name) {
      case 'legend01':
        return (
          <>
            <div style={legendItemStyle('#ff7f00')}>
              <div style={legendColorStyle('#ff7f00')} />
              <span>XZ-Latch (End Stop)</span>
            </div>
            <div style={legendItemStyle('#979d2d')}>
              <div style={legendColorStyle('#979d2d')} />
              <span>XZ-Double Latch</span>
            </div>
            <div style={legendItemStyle('#17a2b8')}>
              <div style={legendColorStyle('#17a2b8')} />
              <span>YZ-Latch</span>
            </div>
            <div style={legendItemStyle('#005700')}>
              <div style={legendColorStyle('#005700')} />
              <span>Selected Latch</span>
            </div>
          </>
        );
      case 'legend02':
        return (
          <>
           <div style={legendItemStyle('#ff7f00')}>
              <div style={legendColorStyle('#ff7f00')} />
              <span>XZ-Latch</span>
            </div>
            <div style={legendItemStyle('#979d2d')}>
              <div style={legendColorStyle('#979d2d')} />
              <span>XZ-Double Latch</span>
            </div>
            <div style={legendItemStyle('#17a2b8')}>
              <div style={legendColorStyle('#17a2b8')} />
              <span>YZ-Latch</span>
            </div>
            <div style={legendItemStyle('#005700')}>
              <div style={legendColorStyle('#005700')} />
              <span>Selected Latch</span>
            </div>
          </>
        );
        case 'legend03':
        return (
          <>
           <div style={legendItemStyle('#ff7f00')}>
              <div style={legendColorStyle('#ff7f00')} />
              <span>XZ-Latch</span>
            </div>
      
            <div style={legendItemStyle('#17a2b8')}>
              <div style={legendColorStyle('#17a2b8')} />
              <span>YZ-Latch</span>
            </div>
            <div style={legendItemStyle('#005700')}>
              <div style={legendColorStyle('#005700')} />
              <span>Selected Latch</span>
            </div>
          </>
        );
        case 'legend04':
        return (
          <>
        
            <div style={legendItemStyle('#979d2d')}>
              <div style={legendColorStyle('#979d2d')} />
              <span>XZ-Double Latch</span>
            </div>
            <div style={legendItemStyle('#17a2b8')}>
              <div style={legendColorStyle('#17a2b8')} />
              <span>YZ-Latch</span>
            </div>
            <div style={legendItemStyle('#005700')}>
              <div style={legendColorStyle('#005700')} />
              <span>Selected Latch</span>
            </div>
          </>
        );
      default:
        return (
          <>
             <div style={legendItemStyle('#ff7f00')}>
              <div style={legendColorStyle('#ff7f00')} />
              <span>XZ-Latch</span>
            </div>
        
            <div style={legendItemStyle('#17a2b8')}>
              <div style={legendColorStyle('#17a2b8')} />
              <span>YZ-Latch</span>
            </div>
            <div style={legendItemStyle('#005700')}>
              <div style={legendColorStyle('#005700')} />
              <span>Selected Latch</span>
            </div>
          </>
        );
    }
  };
  const legendContainerStyle = {
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', // Automatically adjust columns
    backgroundColor: '#2c2c2c',
    padding: '15px 20px',
    borderRadius: '8px',
    color: '#ffffff',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
    gap: '15px',
    zIndex: 10,
    width: 'auto',
    maxWidth: '300px', // Maximum width for the container
    overflow: 'visible', // Ensure content is not hidden
  };
  
  const legendItemStyle = (backgroundColor) => ({
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '5px 10px',
    backgroundColor: '#3a3a3a',
    borderRadius: '8px',
    gap: '10px',
    fontSize: '12px',
    minWidth: '80px', // Adjust minimum width for flexibility
    maxWidth: '100px', // Set maximum width for items
  });
  
  const legendColorStyle = (backgroundColor) => ({
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    backgroundColor,
    borderRadius: '3px',
  });
  
  // Media queries for mobile view adjustments
  const mobileStyle = `
    @media (max-width: 768px) {
      .legend-container {
        bottom: 20px; // Position closer to the bottom
        right: 10px; // Adjust position to keep it within view
        grid-template-columns: repeat(2, 1fr); // Use 2 columns for mobile view
        padding: 10px 15px;
        width: auto;
        max-width: 160px; // Set max-width for mobile
        gap: 10px;
      }
  
      .legend-item {
        padding: 8px 10px;
        font-size: 12px;
        min-width: 80px;
        max-width: 90px;
      }
  
      .legend-color {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
    }
  
    @media (max-width: 480px) {
      .legend-container {
        grid-template-columns: 1fr; // Use a single column for very small screens
      }
    }
  `;
  
  // Apply mobile styles
  const styleElement = document.createElement('style');
  styleElement.textContent = mobileStyle;
  document.head.appendChild(styleElement);
    

    const baseHeightScale = screenWidth / 375 /2; // Ekran yüksekliğine göre dinamik bir ölçek oluşturuyoruz (örnek olarak 667px referans alındı)
const baseWidthScale = screenWidth / 375 /2;   // Ekran genişliğine göre dinamik bir ölçek oluşturuyoruz (örnek olarak 375px referans alındı)


const baseTop1 = isPhone ? 15 * baseHeightScale : 15 * myScaleh2 * heightScale2;
const baseTop2 = isPhone ? 115 * baseHeightScale : 110 * myScaleh2 * heightScale2;
const baseTop3 = isPhone ? 215 * baseHeightScale : 205 * myScaleh2 * heightScale2;
const baseTop4 = isPhone ? 315 * baseHeightScale : 310 * myScaleh2 * heightScale2;
const baseTop5 = isPhone ? 415 * baseHeightScale : 430 * myScaleh2 * heightScale2;
const baseTop6 = isPhone ? 520 * baseHeightScale : 560 * myScaleh2 * heightScale2;
const baseTop7 = isPhone ? 625 * baseHeightScale : 640 * myScaleh2 * heightScale2;
   
    return (
        <div>
            {!selectedItem && (
    <div style={{ textAlign: 'center', marginTop: '5px', color: '#ccc' }}>
        Select a position to choose and identify broken or missing latches.
    </div>
)}
     
            <div style={{ position: 'relative', height: '500px' }}>
                {data1.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop1}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    {selectedItem && selectedItem.position_name === item.position_name && (
                        <div style={{ position: 'absolute', bottom: 0, right: 3, fontSize: "8px" }}>
                            <small>{item.maxkg} kg</small>
                        </div>
                    )}
                </div>
                
                ))}
                {data2.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop2}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                            <p><strong>{item.position_name}</strong></p>
                    {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    {selectedItem && selectedItem.position_name === item.position_name && (
                        <div style={{ position: 'absolute', bottom: 0, right: 3, fontSize: "8px" }}>
                            <small>{item.maxkg} kg</small>
                        </div>
                    )}
                </div>
            ))}
                {data3.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop3}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    {selectedItem && selectedItem.position_name === item.position_name && (
                        <div style={{ position: 'absolute', bottom: 0, right: 3, fontSize: "8px" }}>
                            <small>{item.maxkg} kg</small>
                        </div>
                    )}
                </div>
                ))}
                {data4.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop4}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    </div>
                ))}
                {data5.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop5}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    </div>
                ))}
                {data6.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop6}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    </div>
                ))}
                {data7.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top:  `${baseTop7}px`, })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                        {selectedItem && selectedItem.position_name === item.position_name && renderBoxes(item)}
                    </div>
                ))}
            </div>
    
    
            {selectedBox && showInfo &&(
    <div style={{ 
        position: 'fixed', 
        bottom: '100px', 
        right: '20px', 
        backgroundColor: '#2c2c2c', 
        color: '#ffffff', 
        padding: '20px', 
        borderRadius: '8px', 
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', 
        maxWidth: '350px',
        zIndex: 20, // z-index değeri artırıldı
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Info Icon burada */}
                <InfoIcon sx={{ color: '#ffc107', marginRight: '8px' }} />
                <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#ffc107', margin: 0 }}>Latch Information</p>
            </div>
            <IconButton 
                onClick={handleInfoClose}
                size="small" 
                sx={{ color: '#fff' }} 
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
        </div>
        <p style={{ margin: '0px 0' }}><strong>Latch Type:</strong> {selectedBox.latchType}</p>
        <p style={{ margin: '0px 0' }}><strong>Latch Code:</strong> {selectedBox.latchCode}</p>
        <p style={{ margin: '0px 0' }}><strong>1 Latch:</strong> {selectedBox.latch1} kg</p>
        <p style={{ margin: '0px 0' }}><strong>2 or More Latches:</strong> {selectedBox.latch2} kg</p>
        <p style={{ margin: '0px 0' }}><strong>Related ULD Positions:</strong> {selectedBox.related}</p>
    </div>
)}

 {selectedItem && showLegend && (
        <div style={legendContainerStyle}>
          {renderLegendItems()} {/* Dinamik içeriği göster */}
        </div>
      )}


        </div>
    );
};

export default KilitMd;