import React from 'react';
import { Card, CardContent, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const AccessibilityTableCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Dark tema renk tanımları
  const darkBackground = '#1e1e1e';
  const lightText = '#e0e0e0';
  const headerBackground = '#333';
  const borderColor = '#444';

  // Tablodaki veriler
  const tableData = [
    { class: 'CLASS 1', group: '', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'CLASS 2', group: '', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'CLASS 3', group: 'NO PG', subsidiaryRisk: 'With Subsidiary Risk', accessibility: 'Yes' },
    { class: 'CLASS 3', group: 'NO PG', subsidiaryRisk: 'No Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG I', subsidiaryRisk: 'With Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG I', subsidiaryRisk: 'No Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG II', subsidiaryRisk: 'With Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG II', subsidiaryRisk: 'No Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG III', subsidiaryRisk: 'With Subsidiary Risk (other than Class 8)', accessibility: 'Yes' },
    { class: 'CLASS 3', group: 'PG III', subsidiaryRisk: 'With Subsidiary Risk (Class 8)', accessibility: 'No' },
    { class: 'CLASS 3', group: 'PG III', subsidiaryRisk: 'No Subsidiary Risk', accessibility: 'No' },
    { class: 'CLASS 4', group: '', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'CLASS 5', group: '', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'DIVISION 6.1', group: 'With Subsidiary Risk (other than Class 3)', subsidiaryRisk: '', accessibility: 'Yes' },
    { class: 'DIVISION 6.1', group: 'With Subsidiary Risk (Class 3)', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'DIVISION 6.1', group: 'No Subsidiary Risk', subsidiaryRisk: '', accessibility: 'No' },
    { class: 'DIVISION 6.2', group: '', subsidiaryRisk: '', accessibility: 'Yes' },
    { class: 'CLASS 7', group: '', subsidiaryRisk: '', accessibility: 'Yes' },
    { class: 'CLASS 8', group: '', subsidiaryRisk: '', accessibility: 'Yes' },
    { class: 'CLASS 9', group: '', subsidiaryRisk: '', accessibility: 'Yes' },
  ];

  return (
    <Card
      sx={{
        backgroundColor: darkBackground,
        boxShadow: 6,
        borderRadius: 3,
        padding: isMobile ? 1 : 3,
        maxWidth: isMobile ? '95%' : 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: isMobile ? '75vh' : 'none', // Sadece mobilde kaydırma olacak
        overflowY: isMobile ? 'auto' : 'unset', // Masaüstü cihazlarda kaydırma devre dışı
        marginBottom: isMobile ? '10px' : '40px', // Masaüstü cihazlarda daha geniş alt boşluk
      }}
    >
      <CardContent>
      
        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
          {/* Başlıklar */}
          <Grid item xs={3} sx={{ backgroundColor: headerBackground, padding: 1, border: `1px solid ${borderColor}` }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', color: lightText, whiteSpace: 'normal', wordWrap: 'break-word' }}
            >
              Class / Division
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: headerBackground, padding: 1, border: `1px solid ${borderColor}` }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', color: lightText, whiteSpace: 'normal', wordWrap: 'break-word' }}
            >
              Packing Group
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: headerBackground, padding: 1, border: `1px solid ${borderColor}` }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', color: lightText, whiteSpace: 'normal', wordWrap: 'break-word' }}
            >
              Sub Risk
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: headerBackground, padding: 1, border: `1px solid ${borderColor}` }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: lightText,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                lineHeight: 'normal',
              }}
            >
              REQ
            </Typography>
          </Grid>
          {/* Veri Satırları */}
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <Grid item xs={3} sx={{ border: `1px solid ${borderColor}`, padding: 1 }}>
                <Typography variant="body2" sx={{ color: lightText }}>
                  {row.class}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ border: `1px solid ${borderColor}`, padding: 1 }}>
                <Typography variant="body2" sx={{ color: lightText }}>
                  {row.group}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ border: `1px solid ${borderColor}`, padding: 1 }}>
                <Typography variant="body2" sx={{ color: lightText }}>
                  {row.subsidiaryRisk}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ border: `1px solid ${borderColor}`, padding: 1 }}>
                {row.accessibility === 'Yes' ? (
                  <CheckCircleIcon sx={{ color: '#4caf50', fontSize: isMobile ? 20 : 30 }} />
                ) : (
                  <CancelIcon sx={{ color: '#f44336', fontSize: isMobile ? 20 : 30 }} />
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccessibilityTableCard;
