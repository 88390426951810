import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";

// Dark tema oluşturma
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#bb86fc",
    },
    background: {
      paper: "#1e1e1e",
    },
    text: {
      primary: "#e0e0e0",
      secondary: "#aaaaaa",
    },
  },
});

const DryIceTable = () => {
  const tableData = [
    { packs: "1 Pack", rate04: 7372, rate2: 1472 },
    { packs: "2 Pack NORM", rate04: 10531, rate2: 2103 },
    { packs: "2 Pack HIGH", rate04: 12090, rate2: 2414 },
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <TableContainer component={Paper} sx={{ margin: "20px", maxWidth: 600, mx: "auto", padding: 2 }}>
        <Typography variant="h6" align="center" gutterBottom>
          Max Carriage Limit of Dry Ice (kg)
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" gutterBottom>
          Operation with maximum 15 occupants in aircraft
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>A/C Type</TableCell>
              <TableCell align="center">Sublimation Rate of 0.4%</TableCell>
              <TableCell align="center">Sublimation Rate of 2%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>A330-200F ({row.packs})</TableCell>
                <TableCell align="center">{row.rate04}</TableCell>
                <TableCell align="center">{row.rate2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="caption" display="block" color="text.secondary" sx={{ marginTop: 2 }}>
          <strong>Note:</strong> For A330F, if one of the values for '2 Pack NORMAL' or '2 Pack HIGH' is selected,
          take into account that in case of a pack failure during flight, diversion may require.
        </Typography>
      </TableContainer>
    </ThemeProvider>
  );
};

export default DryIceTable;
