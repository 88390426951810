import React, { createContext, useState } from 'react';

// AppContext oluşturma
export const AppContext = createContext();

// AppContext Provider bileşeni
export const AppProvider = ({ children }) => {
  const [backPath, setBackPath] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [onBackClick, setOnBackClick] = useState(null); // Geri butonunu yönetmek için state ekliyoruz
  const [hasSelection, setHasSelection] = useState(false); // Yeni state eklendi

  return (
    <AppContext.Provider value={{ backPath, setBackPath, subtitle, setSubtitle, onBackClick, setOnBackClick, hasSelection, setHasSelection }}>
      {children}
    </AppContext.Provider>
  );
};
