import React from 'react';

const MyDeparture = (props) => {
  const { height, width } = props;

  return (
    <svg
      height={height || "22px"}
      width={width || "22px"}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 511.99 511.99"
      xmlSpace="preserve"
    >
      <path style={{ fill: '#434A54' }} d="M501.318,443.692H10.663c-5.875,0-10.656-4.766-10.656-10.656s4.781-10.672,10.656-10.672h490.655 c5.891,0,10.672,4.781,10.672,10.672S507.209,443.692,501.318,443.692z" />
      <path style={{ fill: '#5D9CE1' }} d="M510.224,115.32c-4.359-11.968-17.109-21.312-37.92-27.772c-13.938-4.32-26.719-5.898-27.25-5.961 c-1.672-0.203-3.359-0.008-4.938,0.57l-309.27,112.559l-52.982-24.71c-2.578-1.195-5.5-1.32-8.156-0.359L7.023,192.466 c-3.609,1.312-6.234,4.477-6.875,8.266c-0.641,3.797,0.828,7.64,3.828,10.054l121.183,97.864c1.906,1.562,4.281,2.375,6.688,2.375 c1.234,0,2.469-0.219,3.656-0.641l342.332-124.605c1.578-0.57,3-1.508,4.141-2.734c0.375-0.391,9.156-9.82,17.046-22.078 C510.803,142.64,514.584,127.281,510.224,115.32z" />
      <path style={{ opacity: 0.2, fill: '#FFFFFF', enableBackground: 'new' }} d="M510.224,115.32c-3.047-8.343-10.172-15.413-21.265-21.116 c4.233,11.93,0.438,27.21-11.281,45.428c-7.874,12.257-16.655,21.687-17.03,22.085c-1.156,1.219-2.562,2.156-4.141,2.727 L114.16,289.041c-1.188,0.438-2.406,0.641-3.641,0.641c-2.406,0-4.781-0.812-6.703-2.359L0.054,203.521 c0.266,2.812,1.656,5.445,3.922,7.265l121.183,97.864c1.906,1.562,4.281,2.375,6.688,2.375c1.234,0,2.469-0.219,3.656-0.641 l342.332-124.605c1.578-0.57,3-1.508,4.141-2.734c0.375-0.391,9.156-9.82,17.046-22.078 C510.803,142.64,514.584,127.281,510.224,115.32z" />
      <path style={{ fill: '#E6E9ED' }} d="M379.26,154.444L158.861,69.017c-2.406-0.93-5.078-0.961-7.5-0.078l-62.685,22.82 c-3.578,1.297-6.172,4.406-6.859,8.148c-0.656,3.742,0.719,7.555,3.625,10.016L222.25,225.762c1.953,1.656,4.406,2.523,6.89,2.523 c1.234,0,2.453-0.211,3.641-0.641l146.276-53.232c4.172-1.523,6.969-5.469,7.016-9.914S383.4,156.046,379.26,154.444z" />
    </svg>
  );
};

export default MyDeparture;
