import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Box, RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate'; // Importing Material-UI translate icon
import { styled } from '@mui/material/styles';

// Custom styled radio buttons with a polished look
const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#3b82f6', // Set the active (checked) color to a light blue
  },
  '&:hover': {
    backgroundColor: 'rgba(59, 130, 246, 0.1)', // Light blue hover effect
  },
}));

const PalletTypeSelector = () => {
  const [palletType, setPalletType] = useState('');
  const [vehicleWeight, setVehicleWeight] = useState('');
  const [language, setLanguage] = useState('en'); // Default language English

  // Pallet types and their associated logic
  const palletTypes = ['PAG', 'PMC', 'PMC-HD', 'COUPLER', 'PRA', 'PGA'];

  // Function to get the dynamic title based on weight and pallet type
  const getDynamicTitle = () => {
    if (!palletType || !vehicleWeight) {
      return '';
    }
  
    let weightText = '';
    let typeText = '';
  
    if (vehicleWeight <= 1700) {
      weightText = language === 'en' ? 'Weight ≤ 1700 Kg' : 'Ağırlık ≤ 1700 Kg';
      typeText = language === 'en' ? 'Very Light Vehicle and Wheelbase < 295 Cm' : 'Çok Hafif Araç Ve Aks Mesafesi <295 Cm';
    } else if (vehicleWeight > 1700 && vehicleWeight <= 2750) {
      weightText = language === 'en' ? '1700 Kg < Weight ≤ 2750 Kg' : '1700 Kg <Ağırlık ≤ 2750 Kg';
      typeText = language === 'en' ? 'Light Vehicle and Wheelbase < 295 Cm' : 'Hafif Araç Ve Aks Mesafesi <295 Cm';
    } else if (vehicleWeight > 2750 && vehicleWeight <= 4500) {
      weightText = language === 'en' ? '2750 Kg < Weight ≤ 4500 Kg' : '2750 Kg <Ağırlık ≤ 4500 Kg';
      typeText = language === 'en' ? 'Medium Weight Vehicle Or Wheelbase > 295 Cm' : 'Orta Ağır Araç Veya Dingil Mesafesi >295 Cm';
    } else if (vehicleWeight > 4500 && vehicleWeight <= 9000) {
      weightText = language === 'en' ? '4500 Kg <Weight ≤ 9000 Kg' : '4500 Kg <Ağırlık≤ 9000 Kg';
      typeText = language === 'en' ? 'Heavy Vehicle' : 'Ağır Araç';
    } else if (vehicleWeight > 9000) {
      weightText = language === 'en' ? 'Weight > 9000 Kg' : '9000 Kg <Ağırlık';
      typeText = language === 'en' ? 'Very Heavy Vehicle' : 'Çok Ağır Araç ';
    } else {
      weightText = language === 'en' ? 'Weight > 4500 Kg' : 'Ağırlık > 4500 Kg';
      typeText = language === 'en' ? 'Heavy Vehicle' : 'Ağır Araç';
    }
  

    // Combine the three lines of text
    return `${weightText}\n${typeText}`;
  };

  // Get the result dynamically
  const getResult = () => {
    if (!palletType || !vehicleWeight) {
      return language === 'en' ? 'Please select a pallet type and vehicle weight.' : 'Lütfen palet tipi ve araç ağırlığını seçin.';
    }
    const takozUsage = getTakozUsage();
    const kalaslama = getKalaslama();
    const baglama = getBaglama();

    return (
      <Card
        sx={{
          backgroundColor: '#1f2937',
          color: '#e5e7eb',
          padding: '20px',
          borderRadius: '12px',
          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.4)',
          marginBottom: '20px',
          position: 'relative', // Relative position for the icon in the corner
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#e5e7eb',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={toggleLanguage}
        >
          <TranslateIcon />
          <Typography variant="body2" sx={{ marginLeft: '5px', color: '#e5e7eb' }}>
            {language === 'en' ? 'TR' : 'ENG'}
          </Typography>
        </IconButton>

        {/* Dynamic Title */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '15px', marginTop: '30px', color: '#60a5fa' }}>
          {getDynamicTitle()}
        </Typography>

        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#60a5fa' }}>{language === 'en' ? 'Takoz Usage:' : 'Takoz Kullanımı:'}</Typography>
          <Typography variant="body2" sx={{ marginBottom: '15px' }}>{takozUsage}</Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#60a5fa' }}>{language === 'en' ? 'Kalaslama Requirement:' : 'Kalaslama Gerekliliği:'}</Typography>
          <Typography variant="body2" sx={{ marginBottom: '15px' }}>{kalaslama}</Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#60a5fa' }}>{language === 'en' ? 'Binding Method:' : 'Bağlama Yöntemi:'}</Typography>
          <Typography variant="body2">{baglama}</Typography>
        </CardContent>
      </Card>
    );
  };

  const getTakozUsage = () => {
    if (['PMC', 'PAG', 'PRA', 'PGA', 'PMC-HD'].includes(palletType) && vehicleWeight <= 1700) {
      return language === 'en'
        ? (
          <Typography variant="body2">
            Not compulsory to use.
          </Typography>
        ) : (
          <Typography variant="body2">
            Kullanım zorunluluğu bulunmamaktadır.
          </Typography>
        );
    } else if (
      ['PMC', 'PAG', 'PRA', 'PGA', 'PMC-HD'].includes(palletType) &&
      vehicleWeight > 1700 &&
      vehicleWeight <= 2750
    ) {
      return language === 'en'
        ? (
          <div>
            <Typography variant="body2" gutterBottom>
              The following method should be applied for wheel chocking:
            </Typography>
            <ul>
              <li>Apply 2 chocks in front and 2 in the back of the wheels.</li>
              <li>Each wheel must be chocked from either inside or outside.</li>
              <li>
                At least 4 chocks in total should be secured as shown in the image below.
              </li>
            </ul>
            <img
              src="/path/to/your/image.jpg"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
          </div>
        ) : (
          <div>
            <Typography variant="body2" gutterBottom>
              Takozlama aşağıdaki yöntemlerden birine göre yapılır:
            </Typography>
            <ul>
              <li>2 Ön 2 Arka yönde olacak şekilde (bkz. Şekil 107: Takozlama Örneği mavi veya
kırmızı gösterilen takozlar), tekerleklerin içinden veya dışından her tekere 1’er adet
kullanılmalıdır. Toplam en az 4 adet takoz sabitlenerek kullanılmalıdır.<br /><br />
<center><img
              src="/img/takoz4.png"
              alt="Takozlama Örneği"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center><br /><br />
</li>
              <li>1 Ön 1 Arka yönde olacak şekilde (bkz. Şekil 108: Takozlama Örneği mavi veya
kırmızı gösterilen takozlar), tekerleklerin içinden veya dışından her teker çiftine
1’er adet kullanılmalıdır. Toplam en az 2 adet takoz sabitlenerek kullanılmalıdır.<br /><br />
<center><img
              src="/img/takoz5.png"
              alt="Takozlama Örneği"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center><br /><br /></li>
              <strong>
                Takoz Özellikleri
              </strong><br />
              Takozlar, aracın teker büyüklüğüne göre seçilmelidir. Gerekli takoz ölçüleri DK.41.019 Unit Load
Device (ULD) Regulations 6. Operating Specification 6/11 Vehicles Carriage bölümünde yer
almaktadır. Takozun temas noktası yüksekliği (H) tekerleğin çapının (D) en az % 15’ i kadar
olmalıdır. 45° kesilmiş ahşap üçgen takozların (A tipi) minimum yüksekliği tekerleğin çapının (D) en
az % 20’ si kadar olacaktır. B, C, P ve T tipi metal takozların yüksekliği minimum tekerleğin çapının
(D) en az % 15’ i kadar olacaktır. Şekil-109: Takoz Özellikleri’ nde de uygun takoz kullanımı ile ilgili
bilgiler yer almaktadır. <br /><br />
<center><img
              src="/img/takoz6.png"
              alt="Takozlama Örneği"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center><br /><br />
            </ul>
           
          </div>
        );
    }
  
    return language === 'en'
      ? (
        <Typography variant="body2">
          Chock usage is required.
        </Typography>
      ) : (
        <Typography variant="body2">
          Takoz kullanımı zorunludur.
        </Typography>
      );
  };
  

  const getKalaslama = () => {
    if (['PMC'].includes(palletType) && vehicleWeight <= 1700) {
      return language === 'en'
        ? (
          <Typography variant="body2">
            Not compulsory to use.
          </Typography>
        ) : (
          <Typography variant="body2">
             <ul>
             <li>6 adet 225x10x10 cm kalas enlemesine kullanılmalıdır.</li>
             <li>Kalasların üzerine araç boyunca
her iki tarafa 2 adet tahta (Min.
2,5 cm kalınlığında) kullanılmalıdır.</li>
</ul><center><img
              src="/img/araba.png"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center>
          </Typography>
        );
    } else if (['PMC-HD','PGA','PRA'].includes(palletType) && vehicleWeight <= 1700) {
      return language === 'en'
        ? (
          <div>
            <Typography variant="body2" gutterBottom>
              The following method should be applied for wheel chocking:
            </Typography>
            <ul>
              <li>Apply 2 chocks in front and 2 in the back of the wheels.</li>
              <li>Each wheel must be chocked from either inside or outside.</li>
              <li>
                At least 4 chocks in total should be secured as shown in the image below.
              </li>
            </ul>
            <img
              src="/path/to/your/image.jpg"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
          </div>
        ) : (
          <Typography variant="body2">
             
             Palette kalas kullanım zorunluluğu
             bulunmamaktadır. 
          </Typography>
        );
      } else if (['PAG'].includes(palletType) && vehicleWeight <= 1700) {
        return language === 'en'
          ? (
            <div>
              <Typography variant="body2" gutterBottom>
                The following method should be applied for wheel chocking:
              </Typography>
              <ul>
                <li>Apply 2 chocks in front and 2 in the back of the wheels.</li>
                <li>Each wheel must be chocked from either inside or outside.</li>
                <li>
                  At least 4 chocks in total should be secured as shown in the image below.
                </li>
              </ul>
              <img
                src="/path/to/your/image.jpg"
                alt="Wheel Chocking Example"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              />
            </div>
          ) : (
            <Typography variant="body2">
               <ul>
               <li>6 adet 210x10x10 cm kalas enlemesine kullanılmalıdır.</li>
               <li>Kalasların üzerine araç boyunca
  her iki tarafa 2 adet tahta (Min.
  2,5 cm kalınlığında) kullanılmalıdır.</li>
  </ul><center><img
                src="/img/araba.png"
                alt="Wheel Chocking Example"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              /></center>
            </Typography>
          );
      }
  
    return language === 'en'
      ? (
        <Typography variant="body2">
          Chock usage is required.
        </Typography>
      ) : (
        <Typography variant="body2">
          Takoz kullanımı zorunludur.
        </Typography>
      );
  };

  const getBaglama = () => {
    if (['PMC','PAG','PMC-HD','PGA','PRA'].includes(palletType) && vehicleWeight <= 1700) {
      return language === 'en'
        ? (
          <Typography variant="body2">
            Not compulsory to use.
          </Typography>
        ) : (
          <Typography variant="body2">
            Araçlar palet üzerine sabitlenirken aşağıdaki bağlama usullerinden biri uygulanmalıdır. 

             <ul>
             <li><strong>A. Tekerlek içinden bağlama: </strong> <br />
Kayışlar, tekerleklerin içinden geçirilerek araç palete sabitlenir. Kayışlar ile sabitleme
yapılırken tek tekerlek için en az iki adet kayış kullanılmalıdır. (Her yönden
sabitlenecek şekilde) Şekil-110: Tekerlek İçinden Kayışlar ile Aracın Sabitlenmesi
Örnekleri görseli yer almaktadır.
Kayışların jantlara ve araca zarar vermemesi için uygun bir materyal (ped, karton vb.)
kullanılabilir.<br />
<center><img
                src="/img/sabit1.png"
                alt="Wheel Chocking Example"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              /></center> <br />
</li>
             <li><strong>B. Akslarından bağlama:</strong>    <br />
             
             
             Araçlar akslarından geçirilen kayışlar ile palete sabitlenir. Şekil-111: Aksların Kayış ile
Sabitlenmesi görseli eklenmiştir. Akslardan kayışlama yapılırken araca zarar
verilmemesine dikkat edilmelidir. Kayışlar ile sabitleme yapılırken tek tekerlek için en
az iki adet kayış kullanılmalıdır (Her yönden sabitlenecek şekilde). 
<br />     <center><img
              src="/img/sabit2.png"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center>    </li>

<li><strong>C. Araç bağlama kiti ile bağlama: </strong>    <br />
             
             
Tekerleklerin üzerinden bu iş için yapılmış özel kayışlar ile araç palete sabitlenebilir.
Özel kayışlar ile sabitleme yalnızca jantların içinden veya aksların üzerinden kayış
takılmasına izin verilmediği veya mümkün olmadığı durumlarda yapılır. Bu kayışlar, ULD
departmanı (ULD@THY.COM) aracılığıyla dört teker için set olarak temin edilir. Araç
bağlama kiti araç ağırlığı max 3000 Kg’ a kadar olan araç taşımalarında kullanılmalıdır.
Şekil-112A: Araç Bağlama Kiti ve Şekil-112B: Araç Bağlama Kiti görsellerinde kit ve
kullanım şekli yer almaktadır.



<br />     <center><img
              src="/img/sabit3.png"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            /></center>    </li>

</ul>
          </Typography>
        );
    } else if (['PMC-HD','PGA','PRA'].includes(palletType) && vehicleWeight <= 1700) {
      return language === 'en'
        ? (
          <div>
            <Typography variant="body2" gutterBottom>
              The following method should be applied for wheel chocking:
            </Typography>
            <ul>
              <li>Apply 2 chocks in front and 2 in the back of the wheels.</li>
              <li>Each wheel must be chocked from either inside or outside.</li>
              <li>
                At least 4 chocks in total should be secured as shown in the image below.
              </li>
            </ul>
            <img
              src="/path/to/your/image.jpg"
              alt="Wheel Chocking Example"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
          </div>
        ) : (
          <Typography variant="body2">
             
             Palette kalas kullanım zorunluluğu
             bulunmamaktadır. 
          </Typography>
        );
      } else if (['PAG'].includes(palletType) && vehicleWeight <= 1700) {
        return language === 'en'
          ? (
            <div>
              <Typography variant="body2" gutterBottom>
                The following method should be applied for wheel chocking:
              </Typography>
              <ul>
                <li>Apply 2 chocks in front and 2 in the back of the wheels.</li>
                <li>Each wheel must be chocked from either inside or outside.</li>
                <li>
                  At least 4 chocks in total should be secured as shown in the image below.
                </li>
              </ul>
              <img
                src="/path/to/your/image.jpg"
                alt="Wheel Chocking Example"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              />
            </div>
          ) : (
            <Typography variant="body2">
               <ul>
               <li>6 adet 210x10x10 cm kalas enlemesine kullanılmalıdır.</li>
               <li>Kalasların üzerine araç boyunca
  her iki tarafa 2 adet tahta (Min.
  2,5 cm kalınlığında) kullanılmalıdır.</li>
  </ul><center><img
                src="/img/araba.png"
                alt="Wheel Chocking Example"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              /></center>
            </Typography>
          );
      }
  
    return language === 'en'
      ? (
        <Typography variant="body2">
          Chock usage is required.
        </Typography>
      ) : (
        <Typography variant="body2">
          Takoz kullanımı zorunludur.
        </Typography>
      );
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'tr' : 'en');
  };

  return (
    <div style={{ backgroundColor: '#111827', color: '#e5e7eb', minHeight: '100vh', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      {/* Application Title */}
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '30px', color: '#60a5fa', fontWeight: 'bold' }}>
        {language === 'en' ? 'Pallet Type and Vehicle Weight Information' : 'Palet Tipi ve Araç Ağırlığına Göre Bilgiler'}
      </Typography>

      {/* Palet Tipi Kutuları */}
      <Typography variant="h6" sx={{ marginBottom: '10px', color: '#9ca3af', textAlign: 'center' }}>
        {language === 'en' ? 'Select Pallet Type:' : 'Palet Tipini Seçiniz:'}
      </Typography>
      <RadioGroup
        value={palletType}
        onChange={(e) => setPalletType(e.target.value)}
        row
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        {palletTypes.map((type) => (
          <Box
            key={type}
            sx={{
              backgroundColor: palletType === type ? '#3b82f6' : '#374151',
              color: palletType === type ? '#fff' : '#d1d5db',
              padding: '10px 15px', // Reduced size for smaller boxes
              borderRadius: '10px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              '&:hover': { backgroundColor: palletType === type ? '#2563eb' : '#4b5563' },
              transition: 'background-color 0.3s ease-in-out',
              minWidth: '100px', // Reduced width for smaller boxes
              textAlign: 'center',
              fontSize: '14px', // Slightly smaller font size
              fontWeight: '500',
            }}
            onClick={() => setPalletType(type)}
          >
            <FormControlLabel
              value={type}
              control={<StyledRadio sx={{ display: 'none' }} />}
              label={type}
              sx={{ margin: 0 }}
            />
          </Box>
        ))}
      </RadioGroup>

      {/* KG Bilgisi Girişi */}
      <TextField
        fullWidth
        label={language === 'en' ? 'Vehicle Weight (kg)' : 'Araç Ağırlığı (kg)'}
        type="number"
        variant="outlined"
        value={vehicleWeight}
        onChange={(e) => setVehicleWeight(e.target.value)}
        margin="normal"
        sx={{
          marginTop: '40px',
          backgroundColor: '#1f2937',
          color: '#e5e7eb',
          input: { color: '#e5e7eb' },
          label: { color: '#9ca3af' },
          fieldset: { borderColor: '#374151' },
        }}
      />

      {/* Dynamic Result */}
      <div style={{ marginTop: '20px' }}>
        {getResult()}
      </div>
    </div>
  );
};

export default PalletTypeSelector;
