import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Swal from 'sweetalert2'; // SweetAlert2 kütüphanesini import edin
import { data1, data2, data3, data4, data5, data6, data7 } from '../datas/harmData';

// Koyu Tema Oluşturma
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1e88e5',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e', // Paper bileşenleri için arka plan rengi
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontWeight: 600,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e', // Paper arka plan rengi
                    color: '#ffffff', // Metin rengi
                },
            },
        },
    },
});

const containerWidth = 1000; // Set the container width in pixels

const DataHarm = ({ onBoxClick }) => {
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [distance, setDistance] = useState(null);

    const allData = [...data1, ...data2, ...data3, ...data4, ...data5, ...data6, ...data7];
    const maxHarme = Math.max(...allData.map(item => item.harme));
    const maxHarms = Math.max(...allData.map(item => item.harms));

    const scaleHarme = containerWidth / maxHarme;
    const scaleHarms = containerWidth / maxHarms;

    const getBoxStyle = (item) => {
        let backgroundColor = '#333333'; // Varsayılan arka plan rengi
        let borderColor = '#666666'; // Varsayılan çerçeve rengi
        let textColor = '#ffffff'; // Varsayılan yazı rengi
        let zIndex = 1; // Varsayılan z-index değeri

        // Seçilen pozisyona göre renkleri ve çerçeve renklerini belirleyelim.
        if (selectedPositions[0] === item.position_name) {
            backgroundColor = '#66bb6a'; // İlk seçim (yeşil)
            borderColor = '#34c759'; // Seçili çerçeve rengi (yeşil)
            zIndex = 10; // İlk seçimin z-index değeri daha yüksek
            textColor = '#000000'; // Seçim yazı rengi
        } else if (selectedPositions[1] === item.position_name) {
            backgroundColor = '#ffa726'; // İkinci seçim (sarı)
            borderColor = '#ffcc00'; // Seçili çerçeve rengi (sarı)
            zIndex = 10; // İkinci seçimin z-index değeri daha yüksek
            textColor = '#000000'; // Seçim yazı rengi
        } else if (selectedPositions.some(pos => parseFriends(item.friends).includes(pos))) {
            backgroundColor = '#000000'; // Arkadaş pozisyonların arka plan rengi
            borderColor = 'rgba(255, 171, 171, 0.4)'; // Hafif kırmızı border rengi
            textColor = '#ffffff'; // Arkadaş pozisyonların yazı rengi
            zIndex = 2; // Arkadaş pozisyonların z-index değeri
        }

        return {
            position: 'absolute',
            top: `${item.harms * scaleHarms / 2}px`,
            left: `${item.top}`,
            height: `${(item.harme - item.harms) * scaleHarme / 2}px`,
            textAlign: 'center',
            border: `2px solid ${borderColor}`,
            minWidth: '44px',
            padding: '5px',
            boxSizing: 'border-box',
            backgroundColor,
            color: textColor, // Yazı rengi
            transition: 'all 0.3s ease-in-out',
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto, Arial, sans-serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex,
        };
    };

    const parseFriends = (friends) => {
        return friends
            .replace(/[\[\]]/g, '') // Köşeli parantezleri kaldır
            .split(',') // Virgül ile ayır
            .map(friend => friend.trim()); // Her bir öğeyi temizle
    };

    const handleBoxClick = (item) => {
        const itemFriends = parseFriends(item.friends);

        if (selectedPositions.length === 2) {
            setSelectedPositions([item.position_name]);
            setDistance(null);
            onBoxClick(item);
            return;
        }

        if (selectedPositions.includes(item.position_name) || selectedPositions.some(pos => itemFriends.includes(pos))) {
            return;
        }

        const newSelectedPositions = [...selectedPositions, item.position_name];

        if (newSelectedPositions.length === 2) {
            let firstPosition = allData.find(pos => pos.position_name === newSelectedPositions[0]);
            let secondPosition = allData.find(pos => pos.position_name === newSelectedPositions[1]);
            let calculatedDistance = (secondPosition.harmstart - firstPosition.harmend) * 100; // Convert to cm

            if (calculatedDistance < 0) {
                newSelectedPositions.reverse();
                [firstPosition, secondPosition] = [secondPosition, firstPosition];
                calculatedDistance = (secondPosition.harmstart - firstPosition.harmend) * 100;
            }

            setSelectedPositions(newSelectedPositions);
            setDistance(calculatedDistance);

            // SweetAlert ile sonuç gösterimi
            Swal.fire({
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center; padding: 20px;">
                        <!-- İkon ve Başlık (Info İkonuna Efekt Eklendi) -->
                        <div style="
                            width: 60px; 
                            height: 60px; 
                            border-radius: 50%; 
                            background-color: #1e88e5; 
                            display: flex; 
                            justify-content: center; 
                            align-items: center; 
                            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
                            animation: spinEffect 2s infinite ease-in-out;">
                            <!-- Döndürme efektini sadece dış div'e uyguladım, 'i' harfi etkilenmeyecek -->
                            <span style="font-size: 34px; color: #ffffff;">i</span>
                        </div>
            
                        <!-- Seçilen Pozisyonlar Başlığı -->
                        <p style="font-size: 20px; font-weight: 700; margin-bottom: 15px; color: #ffffff;">Selected Positions</p>
            
                        <!-- Seçilen Pozisyonlar Kartları -->
                        <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 20px; margin-bottom: 20px;">
                            ${newSelectedPositions
                              .map(
                                (position, index) => `
                                <div style="
                                    background-color: ${index === 0 ? '#66ff66' : '#ffd452'}; /* İlk seçim için açık yeşil, ikinci seçim için sarı */
                                    border-radius: 12px;
                                    padding: 15px 25px;
                                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                                    min-width: 80px;
                                    text-align: center;
                                    color: #333333; /* Metin rengi */
                                    font-weight: 700;
                                    font-size: 16px;
                                    transition: transform 0.2s;
                                    transform: scale(1.1);
                                    ">
                                    <span>${position}</span>
                                </div>
                            `
                              )
                              .join('')}
                        </div>
            
                        <!-- Mesafe Tablosu -->
                        <table style="width: 80%; margin-top: 20px; border: 1px solid #444; border-collapse: collapse; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);">
                            <thead>
                                <tr style="background-color: #444; color: #ffffff;">
                                    <th style="border: 1px solid #555; padding: 12px; font-size: 16px; font-weight: 600;">Unit</th>
                                    <th style="border: 1px solid #555; padding: 12px; font-size: 16px; font-weight: 600;">Distance</th>
                                </tr>
                            </thead>
                            <tbody style="font-size: 14px;">
                                <tr style="background-color: #222;">
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">cm</td>
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">${calculatedDistance.toFixed(2)} cm</td>
                                </tr>
                                <tr style="background-color: #333;">
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">m</td>
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">${(calculatedDistance / 100).toFixed(2)} m</td>
                                </tr>
                                <tr style="background-color: #222;">
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">inch</td>
                                    <td style="border: 1px solid #555; padding: 12px; color: #ffffff;">${(calculatedDistance / 2.54).toFixed(2)} inch</td>
                                </tr>
                            </tbody>
                        </table>
            
                        <!-- OK Butonu (Yeni Renk ve Stil) -->
                        <div style="margin-top: 25px;">
                            <button id="custom-ok-btn" style="
                                background-color: #1e88e5; /* Mavi ton */
                                color: #ffffff;
                                border: none;
                                border-radius: 30px;
                                padding: 14px 35px;
                                font-size: 18px;
                                font-weight: 700;
                                cursor: pointer;
                                transition: background-color 0.3s ease, transform 0.2s ease;
                                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                                transform: scale(1.1);
                                ">
                                OK
                            </button>
                        </div>
                    </div>
                `,
                background: '#333333', // Koyu arka plan rengi
                color: '#ffffff', // Metin rengi
                showConfirmButton: false, // SweetAlert'in kendi OK butonunu gizle
                customClass: {
                    popup: 'dark-popup', // Ekstra stil tanımlamak için class ekleme
                },
                didOpen: () => {
                    // SweetAlert açıldığında `custom-ok-btn` ID'sine sahip butona olay dinleyicisi ekleyin
                    const okButton = document.getElementById('custom-ok-btn');
                    if (okButton) {
                        okButton.addEventListener('click', () => {
                            Swal.close(); // SweetAlert'i kapat
                            clearSelections(); // Seçimleri temizle
                        });
                    }
                }
            });
            
            /* CSS ile animasyon efektleri */
            const styleSheet = document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.innerText = `
            @keyframes spinEffect {
                0% { transform: scale(1); }
                50% { transform: scale(1.1); }
                100% { transform: scale(1); }
            }
            `;
            document.head.appendChild(styleSheet);
            
                        
        } else {
            setSelectedPositions(newSelectedPositions);
        }
        onBoxClick(item);
    };

    const clearSelections = () => {
        setSelectedPositions([]);
        setDistance(null);
    };

    return (
        <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <Grid container spacing={2}>
            {/* First Column: Position Boxes */}
        
            <Grid item xs={12} md={12}>
           
                <Paper elevation={3} style={{ padding: '16px', position: 'relative', minHeight: '630px' }}>
                <div>
             {/* İlk Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data1.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '5px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* İkinci Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data2.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '55px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* Üçüncü Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data3.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '105px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* Dördüncü Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data4.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '155px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* Beşinci Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data5.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '200px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* Altıncı Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data6.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '250px' }),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

{/* Yedinci Sıra */}
<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    {data7.map(item => (
        <Box
            key={item.id}
            sx={{
                ...getBoxStyle({ ...item, top: '300px' }),
                display: 'flex',
                justifyContent: 'center',
         
                alignItems: 'center',
                margin: '5px',
            }}
            onClick={() => handleBoxClick(item)}
        >
            <Typography variant="subtitle2" align="center"><strong>{item.position_name}</strong></Typography>
        </Box>
    ))}
</div>

                    </div>
                </Paper>
           
            </Grid>
            
            {/* Second Column: Position Details */}
          
        </Grid>
        </ThemeProvider>
    );
};

export default DataHarm;

