import React, { useState } from 'react';

const data1 = [
    { id: 1, position_name: 'A', maxkg: 2826, harms: 0, harme: 223.4, harmstart: 14.648, harmend: 16.883, friends: "[AA],[AB],[ABR],[ABL]" },
    { id: 2, position_name: 'B', maxkg: 3123, harms: 226, harme: 449.6, harmstart: 16.908, harmend: 19.144, friends: "[AA],[AB],[ABR],[ABL],[BB],[BC],[BCR],[BCL]" },
    { id: 3, position_name: 'C', maxkg: 3391, harms: 452.1, harme: 675.6, harmstart: 19.169, harmend: 21.404, friends: "[BB],[CC],[BC],[CE],[CFR],[CFG],[BCR],[BCL],[CER],[CEL]" },
    { id: 4, position_name: 'D', maxkg: 3391, harms: 678.2, harme: 901.7, harmstart: 21.430, harmend: 23.665, friends: "[CC],[DD],[CFR],[CFG],[CE],[CER],[CEL]" },
    { id: 5, position_name: 'E', maxkg: 4687, harms: 904.2, harme: 1127.7, harmstart: 23.690, harmend: 25.925, friends: "[DD],[EE],[CFR],[CFG],[CE],[EF],[CER],[CEL],[EFR],[EFL]" },
    { id: 6, position_name: 'F', maxkg: 6033, harms: 1130.3, harme: 1353.8, harmstart: 25.951, harmend: 28.186, friends: "[EE],[FF],[CFR],[CFG],[FJR],[FJG],[EF],[FH],[EFR],[EFL],[FHR],[FHL]" },
    { id: 7, position_name: 'G', maxkg: 6033, harms: 1356.3, harme: 1579.9, harmstart: 28.211, harmend: 30.447, friends: "[FF],[GG],[FJR],[FJG],[FH],[FHR],[FHL]" },
    { id: 8, position_name: 'H', maxkg: 6033, harms: 1582.4, harme: 1805.9, harmstart: 30.472, harmend: 32.707, friends: "[GG],[HH],[FH],[HJ],[FJR],[FJG],[JLR],[HJ],[HJR],[HJL],[FHR],[FHL]" },
    { id: 9, position_name: 'J', maxkg: 6033, harms: 1808.5, harme: 2032, harmstart: 32.733, harmend: 34.968, friends: "[HH],[JJ],[JLR],[JLG],[HJ],[JK],[HJR],[HJL],[JKR],[JKL]" },
    { id: 10, position_name: 'K', maxkg: 5945, harms: 2034.5, harme: 2258, harmstart: 34.993, harmend: 37.228, friends: "[JJ],[KK],[JLR],[JLG],[JK],[KM],[JKR],[JKL],[KMR],[KML]" },
    { id: 11, position_name: 'L', maxkg: 4037, harms: 2260.6, harme: 2484.1, harmstart: 37.254, harmend: 39.489, friends: "[KK],[LL],[LPR],[JLG],[KM],[KMR],[KML],[JLR]" },
    { id: 12, position_name: 'M', maxkg: 4037, harms: 2486.6, harme: 2710.2, harmstart: 39.514, harmend: 41.750, friends: "[LL],[LPR],[KM],[MP],[KMR],[KML],[MPR],[MPL]" },
    { id: 13, position_name: 'P', maxkg: 3725, harms: 2712.7, harme: 2936.2, harmstart: 41.775, harmend: 44.010, friends: "[MM],[LPR],[MP],[MPR],[MPL],[PRR],[PRL]" },
    { id: 14, position_name: 'R', maxkg: 3714, harms: 2979.4, harme: 3202.9, harmstart: 44.042, harmend: 46.677, friends: "[PP],[PRR],[PRL]" },
    { id: 15, position_name: 'S', maxkg: 3714, harms: 3225.8, harme: 3449.3, harmstart: 46.703, harmend: 49.141, friends: "[RR]" },
    { id: 16, position_name: 'T', maxkg: 3059, harms: 3472.2, harme: 3695.7, harmstart: 49.166, harmend: 51.605, friends: "[SS]" },
    { id: 17, position_name: 'U', maxkg: 2541, harms: 3718.5, harme: 3942.1, harmstart: 51.630, harmend: 54.069, friends: "[TT]" }
];

const data2 = [
    { id: 18, position_name: 'AA', maxkg: 3080, harms: 0, harme: 243.8, harmstart: 14.648, harmend: 17.086, friends: "[A],[B],[AB],[ABR],[ABL]" },
    { id: 19, position_name: 'BB', maxkg: 3428, harms: 246.4, harme: 490.2, harmstart: 17.112, harmend: 19.550, friends: "[B],[C],[AB],[BC],[BCR],[BCL]" },
    { id: 20, position_name: 'CC', maxkg: 3696, harms: 492.7, harme: 736.6, harmstart: 19.575, harmend: 22.014, friends: "[C],[D],[BC],[CE],[CFR],[CFG],[BCR],[BCL],[CER],[CEL]" },
    { id: 21, position_name: 'DD', maxkg: 3696, harms: 739.1, harme: 983, harmstart: 22.039, harmend: 24.478, friends: "[D],[E],[CFR],[CFG],[CE],[CER],[CEL]" },
    { id: 22, position_name: 'EE', maxkg: 5670, harms: 985.5, harme: 1229.3, harmstart: 24.503, harmend: 26.941, friends: "[E],[F],[CFR],[CFG],[CE],[EF],[CER],[CEL],[EFR],[EFL]" },
    { id: 23, position_name: 'FF', maxkg: 5670, harms: 1231.9, harme: 1475.7, harmstart: 26.966, harmend: 29.403, friends: "[F],[G],[CFR],[CFG],[FJR],[FJG],[EF],[FH],[EFR],[EFL],[FHR],[FHL]" },
    { id: 24, position_name: 'GG', maxkg: 5670, harms: 1478.3, harme: 1722.1, harmstart: 29.431, harmend: 31.869, friends: "[G],[H],[FJR],[FJG],[FH],[FHR],[FHL]" },
    { id: 25, position_name: 'HH', maxkg: 5670, harms: 1724.6, harme: 1968.5, harmstart: 31.894, harmend: 34.332, friends: "[H],[J],[FH],[HJ],[FJR],[FJG],[JLR],[HJ],[HJR],[HJL],[FHR],[FHL]" },
    { id: 26, position_name: 'JJ', maxkg: 5670, harms: 1971, harme: 2214.9, harmstart: 34.358, harmend: 36.797, friends: "[J],[K],[JLR],[JLG],[HJ],[JK],[HJR],[HJL],[JKR],[JKL]" },
    { id: 27, position_name: 'KK', maxkg: 4822, harms: 2219.9, harme: 2463.8, harmstart: 36.847, harmend: 39.286, friends: "[K],[L],[LPR],[JLG],[KM],[KMR],[KML],[JLR]" },
    { id: 28, position_name: 'LL', maxkg: 4400, harms: 2466.3, harme: 2710.2, harmstart: 39.311, harmend: 41.750, friends: "[L],[M],[LPR],[KM],[MP],[KMR],[KML],[MPR],[MPL]" },
    { id: 29, position_name: 'MM', maxkg: 4091, harms: 2712.7, harme: 2956.5, harmstart: 41.775, harmend: 44.213, friends: "[P],[LPR],[MP],[MPR],[MPL],[PRR],[PRL]" },
    { id: 30, position_name: 'PP', maxkg: 4048, harms: 2959.1, harme: 3202.9, harmstart: 44.239, harmend: 46.677, friends: "[R],[PRR],[PRL]" },
    { id: 31, position_name: 'RR', maxkg: 4048, harms: 3205.5, harme: 3449.3, harmstart: 46.703, harmend: 49.141, friends: "[S]" },
    { id: 32, position_name: 'SS', maxkg: 3389, harms: 3451.8, harme: 3695.7, harmstart: 49.166, harmend: 51.605, friends: "[T]" },
    { id: 33, position_name: 'TT', maxkg: 2809, harms: 3698.2, harme: 3942.1, harmstart: 51.630, harmend: 54.069, friends: "[U]" }
];


const data3 = [
    { id: 34, position_name: 'AB', maxkg: 3429, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[B],[AA],[BB],[ABR],[ABL]" },
    { id: 35, position_name: 'BC', maxkg: 3941, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[C],[BB],[CC],[BCR],[BCL]" },
    { id: 36, position_name: 'CE', maxkg: 4001, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CER],[CEL]" },
    { id: 37, position_name: 'EF', maxkg: 6804, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[CFR],[CFG],[EFR],[EFL],[FJR],[FJG]" },
    { id: 38, position_name: 'FH', maxkg: 6804, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[FJR],[FJG],[FHR],[FHL]" },
    { id: 39, position_name: 'HJ', maxkg: 6804, harms: 1600.2, harme: 1917.7, harmstart: 30.65, harmend: 33.825, friends: "[H],[J],[GG],[HH],[FJR],[FJG],[JLR],[JLG],[HJR],[HJL]" },
    { id: 40, position_name: 'JK', maxkg: 6804, harms: 1920.2, harme: 2237.7, harmstart: 33.85, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JLG],[JKR],[JKL]" },
    { id: 41, position_name: 'KM', maxkg: 4868, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[JLG],[LPR],[KMR],[KML]" },
    { id: 42, position_name: 'MP', maxkg: 4445, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MPR],[MPL]" }
];

const data4 = [
    { id: 43, position_name: 'CFR', maxkg: 7729, harms: 647.1, harme: 1144.9, harmstart: 21.119, harmend: 26.097, friends: "[C],[D],[E],[DD],[EE],[FF],[CE],[CFG],[CER],[CEL]" },
    { id: 44, position_name: 'FJR', maxkg: 10668, harms: 1265.5, harme: 1763.4, harmstart: 27.303, harmend: 32.282, friends: "[F],[G],[H],[FF],[GG],[HH],[EE],[EF],[EFR],[EFL],[FH],[FHR],[FHL]" },
    { id: 45, position_name: 'JLR', maxkg: 10668, harms: 1776.8, harme: 2274.6, harmstart: 32.416, harmend: 37.394, friends: "[H],[J],[K],[HH],[JJ],[KK],[JLG],[JK],[HJR],[HJL],[JKR],[JKL]" },
    { id: 46, position_name: 'LPR', maxkg: 6236, harms: 2287.9, harme: 2785.7, harmstart: 37.527, harmend: 42.505, friends: "[K],[L],[M],[LL],[MM],[KK],[L],[KM],[MP],[KMR],[KML]" }
];

const data5 = [
    { id: 47, position_name: 'CFG', maxkg: 10602, harms: 644.1, harme: 1250, harmstart: 21.089, harmend: 27.148, friends: "[C],[D],[E],[DD],[EE],[FF],[CE],[CFR],[CER],[CEL]" },
    { id: 48, position_name: 'FJG', maxkg: 11340, harms: 1262.7, harme: 1868.5, harmstart: 27.275, harmend: 33.333, friends: "[F],[G],[H],[FF],[GG],[HH],[EE],[EF],[EFR],[EFL],[FH],[FHR],[FHL]" },
    { id: 49, position_name: 'JLG', maxkg: 11340, harms: 1881.1, harme: 2486.9, harmstart: 33.459, harmend: 39.517, friends: "[H],[J],[K],[HH],[JJ],[KK],[JLR],[JK],[HJR],[HJL],[JKR],[JKL]" }
];

const data6 = [
    { id: 63, position_name: 'ABL', maxkg: 2000, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[AA],[AB],[ABR]" },
    { id: 65, position_name: 'BCL', maxkg: 2358, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[BB],[C],[CC],[BC],[BCR]" },
    { id: 67, position_name: 'CEL', maxkg: 2400, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CER]" },
    { id: 69, position_name: 'EFL', maxkg: 3825, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[EFR],[EFL],[FH]" },
    { id: 71, position_name: 'FHL', maxkg: 4321, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[HH],[FHR],[FHL]" },
    { id: 73, position_name: 'HJL', maxkg: 4321, harms: 1600.2, harme: 1917.7, harmstart: 30.650, harmend: 33.825, friends: "[H],[J],[GG],[HH],[JLR],[JLG],[HJR]" },
    { id: 75, position_name: 'JKL', maxkg: 4321, harms: 1920.2, harme: 2237.7, harmstart: 33.850, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JK],[HJR],[HJL],[JKR]" },
    { id: 77, position_name: 'KML', maxkg: 2964, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[LPR],[KMR]" },
    { id: 79, position_name: 'MPL', maxkg: 2756, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MPR]" },
    { id: 81, position_name: 'PRL', maxkg: 2629, harms: 2885.4, harme: 3202.9, harmstart: 43.502, harmend: 46.677, friends: "[P],[R],[MM],[LPR],[PRR]" }
];

const data7 = [
    { id: 64, position_name: 'ABR', maxkg: 2000, harms: 0, harme: 317.5, harmstart: 14.648, harmend: 17.823, friends: "[A],[B],[AA],[BB],[AB]" },
    { id: 66, position_name: 'BCR', maxkg: 2358, harms: 320, harme: 637.5, harmstart: 17.848, harmend: 21.023, friends: "[B],[C],[BB],[CC],[BC]" },
    { id: 68, position_name: 'CER', maxkg: 2400, harms: 640.1, harme: 957.6, harmstart: 21.049, harmend: 24.224, friends: "[C],[D],[E],[CC],[DD],[CFR],[CFG],[CE]" },
    { id: 70, position_name: 'EFR', maxkg: 3825, harms: 960.1, harme: 1277.6, harmstart: 24.249, harmend: 27.424, friends: "[E],[F],[DD],[EE],[FF],[CFR],[CFG],[EF]" },
    { id: 72, position_name: 'FHR', maxkg: 4321, harms: 1280.1, harme: 1597.6, harmstart: 27.449, harmend: 30.624, friends: "[F],[G],[H],[FF],[GG],[FJR],[FJG],[FH]" },
    { id: 74, position_name: 'HJR', maxkg: 4321, harms: 1600.2, harme: 1917.7, harmstart: 30.65, harmend: 33.825, friends: "[H],[J],[GG],[HH],[FJR],[FJG],[JLR],[JLG],[HJ]" },
    { id: 76, position_name: 'JKR', maxkg: 4321, harms: 1920.2, harme: 2237.7, harmstart: 33.85, harmend: 37.025, friends: "[J],[K],[HH],[JJ],[KK],[JLR],[JLG],[JK]" },
    { id: 78, position_name: 'KMR', maxkg: 2964, harms: 2240.3, harme: 2557.8, harmstart: 37.051, harmend: 40.226, friends: "[K],[L],[M],[KK],[LL],[JLR],[JLG],[LPR],[KM]" },
    { id: 80, position_name: 'MPR', maxkg: 2756, harms: 2562.8, harme: 2880.3, harmstart: 40.276, harmend: 43.451, friends: "[M],[P],[LL],[MM],[LPR],[MP]" },
    { id: 82, position_name: 'PRR', maxkg: 2629, harms: 2885.4, harme: 3202.9, harmstart: 43.502, harmend: 46.677, friends: "[P],[R],[MM],[PP]" }
];





const containerWidth = 1000; // Set the container width in pixels

const DataHarm = ({ onBoxClick }) => {
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [distance, setDistance] = useState(null);

    const allData = [...data1, ...data2, ...data3, ...data4, ...data5, ...data6, ...data7];
    const maxHarme = Math.max(...allData.map(item => item.harme));
    const maxHarms = Math.max(...allData.map(item => item.harms));

    const scaleHarme = containerWidth / maxHarme;
    const scaleHarms = containerWidth / maxHarms;

    const getBoxStyle = (item) => {
        let backgroundColor = 'transparent';
        const itemFriends = parseFriends(item.friends); // friends dizisini parse eder
    
        if (selectedPositions.includes(item.position_name)) {
            const index = selectedPositions.indexOf(item.position_name);
            backgroundColor = index === 0 ? '#adffad' : '#ffd452';
        } else if (selectedPositions.length > 0 && selectedPositions.some(pos => itemFriends.includes(pos))) {
            backgroundColor = '#ddd';
        }
    
        return {
            position: 'absolute',
            top: item.top,
            left: `${item.harms * scaleHarms}px`,
            width: `${(item.harme - item.harms) * scaleHarme}px`,
            textAlign: 'center',
            border: '1px solid black',
            padding: '10px',
            boxSizing: 'border-box',
            zIndex: 3 ,
            backgroundColor
        };
    };

    const parseFriends = (friends) => {
        return friends
            .replace(/[\[\]]/g, '') // Köşeli parantezleri kaldır
            .split(',') // Virgül ile ayır
            .map(friend => friend.trim()); // Her bir öğeyi temizle
    }

    const handleBoxClick = (item) => {
        const itemFriends = parseFriends(item.friends); // friends dizisini parse eder
    
        // Eğer üçüncü bir seçim yapılıyorsa seçimleri temizle ve yeni seçimi ekle
        if (selectedPositions.length === 2) {
            setSelectedPositions([item.position_name]);
            setDistance(null);
            onBoxClick(item);
            return;
        }
    
        // Eğer zaten seçiliyse veya friends dizisinde yer alıyorsa işlem yapma
        if (selectedPositions.includes(item.position_name) || selectedPositions.some(pos => itemFriends.includes(pos))) {
            return;
        }
    
        // Yeni seçimi ekle
        const newSelectedPositions = [...selectedPositions, item.position_name];
    
        // İkinci seçim yapıldığında mesafeyi hesapla
        if (newSelectedPositions.length === 2) {
            let firstPosition = allData.find(pos => pos.position_name === newSelectedPositions[0]);
            let secondPosition = allData.find(pos => pos.position_name === newSelectedPositions[1]);
            let calculatedDistance = (secondPosition.harmstart - firstPosition.harmend) * 100; // Convert to cm
    
            // Eğer mesafe negatifse pozisyonları yer değiştir
            if (calculatedDistance < 0) {
                newSelectedPositions.reverse(); // Seçimleri yer değiştir
                [firstPosition, secondPosition] = [secondPosition, firstPosition];
                calculatedDistance = (secondPosition.harmstart - firstPosition.harmend) * 100; // Yeniden hesapla
            }
    
            setSelectedPositions(newSelectedPositions);
            setDistance(calculatedDistance);
        } else {
            setSelectedPositions(newSelectedPositions);
        }
        onBoxClick(item);
    };
    
    
    

    const clearSelections = () => {
        setSelectedPositions([]);
        setDistance(null);
    };

    const getBoxCoordinates = (item) => {
        const left = item.harms * scaleHarms;
        const width = (item.harme - item.harms) * scaleHarme;
        return { left, width };
    };
    
    const getDistanceStyle = () => {
        if (selectedPositions.length === 2) {
            const firstPosition = allData.find(pos => pos.position_name === selectedPositions[0]);
            const secondPosition = allData.find(pos => pos.position_name === selectedPositions[1]);
            
            const firstBox = getBoxCoordinates(firstPosition);
            const secondBox = getBoxCoordinates(secondPosition);
            
            const left = `${firstBox.left + firstBox.width}px`; // İlk kutunun bitiş noktası
            const width = `${secondBox.left - (firstBox.left + firstBox.width)}px`; // İkinci kutunun başlangıç noktasından itibaren
    
            return {
                position: 'absolute',
                top: 30,
                left,
                width,
                height: '457px', // Tüm satırları kapsayacak şekilde yüksekliği ayarlayın.
                backgroundColor: '#f18686',
                opacity: 0.3, // Biraz saydamlık ekleyin.
                zIndex: 2 // Diğer kutuların altında kalmaması için zIndex'i ayarlayın.
            };
        }
        return {};
    };
    

    return (
        <div>
            <div style={{ position: 'relative', height: '500px' }}>
                {selectedPositions.length === 2 && <div style={getDistanceStyle()} />}
                {data1.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '50px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data2.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '110px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data3.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '170px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data4.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '230px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data5.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '290px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data6.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '350px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
                {data7.map(item => (
                    <div
                        key={item.id}
                        style={getBoxStyle({ ...item, top: '410px' })}
                        onClick={() => handleBoxClick(item)}
                    >
                        <p><strong>{item.position_name}</strong></p>
                    </div>
                ))}
            </div>
            {distance && (
                <div>
                    <center>
                        <h3>Selected Positions: {selectedPositions.join(', ')}</h3>
                        <h3>Distance: {distance.toFixed(2)} cm</h3>
                    </center>
                </div>
            )}
            <div style={{ position: 'absolute', top: '745px', left: '69%', transform: 'translateX(-50%)', width: '400px', height: '50px' }}>
                <p style={{ margin: 0 }}>
                    <span style={{ backgroundColor: '#adffad', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }}></span>
                    First Selection
                </p>
                <p style={{ margin: 0 }}>
                    <span style={{ backgroundColor: '#ffd452', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }}></span>
                    Second Selection
                </p>
                <p style={{ margin: 0 }}>
                    <span style={{ backgroundColor: '#ddd', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }}></span>
                    First or Second Selection Block This Position
                </p>
            </div>
            <div style={{ position: 'absolute', top: '702px', left: '63%' }}>
            <button 
                    onClick={clearSelections}
                    className="btn btn-sm btn-secondary"
                    disabled={selectedPositions.length === 0}
                >
                    Clear Selections
                </button>
            </div>
        </div>
    );
};

export default DataHarm;