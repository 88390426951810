const data30 = [
    { "height": 0, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.15, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.15, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.15, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.15, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.15, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.15, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.15, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.15, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.15, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.15, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.15, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.15, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.15, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.15, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.15, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.15, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.15, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.15, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.15, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.15, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.15, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.3, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.3, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.3, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.3, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.3, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.3, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.3, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.3, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.3, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.3, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.3, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.3, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.3, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.3, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.3, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.3, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.3, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.3, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.3, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.3, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.3, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.46, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.46, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.46, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.46, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.46, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.46, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.46, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.46, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.46, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.46, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.46, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.46, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.46, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.46, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.46, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.46, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.46, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.46, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.46, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.46, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.46, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.61, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.61, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.61, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.61, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.61, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.61, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.61, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.61, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.61, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.61, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.61, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.61, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.61, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.61, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.61, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.61, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.61, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.61, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.61, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.61, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.61, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.76, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.76, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.76, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.76, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.76, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.76, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.76, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.76, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.76, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.76, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.76, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.76, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.76, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.76, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.76, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.76, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.76, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.76, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.76, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.76, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.76, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 0.91, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 0.91, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 0.91, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 0.91, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 0.91, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 0.91, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 0.91, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 0.91, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 0.91, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 0.91, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 0.91, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 0.91, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 0.91, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 0.91, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 0.91, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 0.91, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 0.91, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 0.91, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 0.91, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 0.91, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 0.91, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 1.07, "width": 0.15, "length": 29.64, "loadType": 1 },
    { "height": 1.07, "width": 0.3, "length": 24.45, "loadType": 1 },
    { "height": 1.07, "width": 0.46, "length": 20.3, "loadType": 1 },
    { "height": 1.07, "width": 0.61, "length": 17.37, "loadType": 1 },
    { "height": 1.07, "width": 0.76, "length": 15.19, "loadType": 1 },
    { "height": 1.07, "width": 0.91, "length": 13.5, "loadType": 1 },
    { "height": 1.07, "width": 1.07, "length": 12.15, "loadType": 1 },
    { "height": 1.07, "width": 1.22, "length": 11.06, "loadType": 1 },
    { "height": 1.07, "width": 1.37, "length": 10.15, "loadType": 1 },
    { "height": 1.07, "width": 1.52, "length": 9.38, "loadType": 1 },
    { "height": 1.07, "width": 1.68, "length": 8.73, "loadType": 1 },
    { "height": 1.07, "width": 1.83, "length": 8.16, "loadType": 1 },
    { "height": 1.07, "width": 1.98, "length": 7.67, "loadType": 1 },
    { "height": 1.07, "width": 2.13, "length": 7.27, "loadType": 1 },
    { "height": 1.07, "width": 2.29, "length": 6.86, "loadType": 1 },
    { "height": 1.07, "width": 2.44, "length": 6.52, "loadType": 1 },
    { "height": 1.07, "width": 2.59, "length": 6.21, "loadType": 1 },
    { "height": 1.07, "width": 2.74, "length": 5.94, "loadType": 1 },
    { "height": 1.07, "width": 2.9, "length": 4.78, "loadType": 2 },
    { "height": 1.07, "width": 3.05, "length": 4.78, "loadType": 2 },
    { "height": 1.07, "width": 3.18, "length": 4.78, "loadType": 2 },
    { "height": 1.22, "width": 0.15, "length": 28.82, "loadType": 1 },
    { "height": 1.22, "width": 0.3, "length": 23.19, "loadType": 1 },
    { "height": 1.22, "width": 0.46, "length": 19.41, "loadType": 1 },
    { "height": 1.22, "width": 0.61, "length": 16.7, "loadType": 1 },
    { "height": 1.22, "width": 0.76, "length": 14.67, "loadType": 1 },
    { "height": 1.22, "width": 0.91, "length": 13.08, "loadType": 1 },
    { "height": 1.22, "width": 1.07, "length": 11.81, "loadType": 1 },
    { "height": 1.22, "width": 1.22, "length": 10.77, "loadType": 1 },
    { "height": 1.22, "width": 1.37, "length": 9.91, "loadType": 1 },
    { "height": 1.22, "width": 1.52, "length": 9.17, "loadType": 1 },
    { "height": 1.22, "width": 1.68, "length": 8.55, "loadType": 1 },
    { "height": 1.22, "width": 1.83, "length": 8, "loadType": 1 },
    { "height": 1.22, "width": 1.98, "length": 7.53, "loadType": 1 },
    { "height": 1.22, "width": 2.13, "length": 7.11, "loadType": 1 },
    { "height": 1.22, "width": 2.29, "length": 6.74, "loadType": 1 },
    { "height": 1.22, "width": 2.44, "length": 6.42, "loadType": 1 },
    { "height": 1.22, "width": 2.59, "length": 6.12, "loadType": 1 },
    { "height": 1.22, "width": 2.74, "length": 5.86, "loadType": 2 },
    { "height": 1.22, "width": 2.9, "length": 4.7, "loadType": 2 },
    { "height": 1.22, "width": 3.05, "length": 4.7, "loadType": 2 },
    { "height": 1.22, "width": 3.18, "length": 4.7, "loadType": 2 }

];


export { data30 };