import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaCalculator, FaRuler, FaTools } from 'react-icons/fa';
import { TfiLayoutGrid2Alt } from "react-icons/tfi";
import { MdOutlineTurnRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import MyDeparture from '../svg/MyDeparture';

const IconListGroup = () => {
  return (
    <div className="container mt-4 maa10">
      <ListGroup className="icon-list-group">
        <ListGroup.Item className="list-item">
          <Link to="/A330_Floating" className="link-back">
            <FaCalculator className="icon mr-2" /> Floating Pallet Calculator
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/loading330" className="link-back">
            <FaRuler className="icon mr-2" /> H-Arm Table
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/lmd" className="link-back">
            <FaTools className="icon mr-2" /> Latches Malfunctions [Main Deck]
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/tt" className="link-back">
            <MdOutlineTurnRight className="icon mr-2" /> Main Deck Maximum Loadability
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/afsr" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> Special Loads Segregation
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/cao" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> CAO Access
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/un" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> UN Scanner
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/vehicle" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> Vehicle Transportation
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className="list-item">
          <Link to="/dryice" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> Dry Ice Limitation
          </Link>
        </ListGroup.Item>
        <ListGroup.Item className="list-item">
          <Link to="/liveanimal" className="link-back">
            <TfiLayoutGrid2Alt className="icon mr-2" /> Restrictions on Live Animal Transportation
          </Link>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

const Main = () => {
  return (
    <div className="services segments-page">
      <div className="container-pd">
        <div className="row align-items-stretch">
          <div className="col-12 col-md-6 px-2 py-2">
            <div className="content b-shadow d-flex flex-column h-100 text-center main-content">
              <center>
                <MyDeparture height="120px" width="100px" className="mx-auto my-auto airplane-icon" />
              </center>
              <h4 className="main-title">A330F</h4>
              <p className="main-description">You can access the A330F application and documentation here.</p>
              <IconListGroup />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
