import React, { useState } from 'react';

// JSON data
const compatibilityTable = {
  "RCX": {"RCX": "This table is used as the Special Loads Segregation Table...", "REX": "The minimum segregation distance is used as per IATA rules...", "RGX": "As a Company rule...", "RXC": "As a Company rule...", "RXD": "As a Company rule...", "RXE": "As a Company rule...", "RXG": "As a Company rule..."},
  "REX": {"RCX": "The minimum segregation distance is used as per IATA rules...", "REX": "This table is used as the Special Loads Segregation Table...", "RGX": "As a Company rule...", "RXC": "As a Company rule...", "RXD": "As a Company rule...", "RXE": "As a Company rule...", "RXG": "As a Company rule..."},
  "RGX": {"RCX": "As a Company rule...", "REX": "As a Company rule...", "RGX": "This table is used as the Special Loads Segregation Table...", "RXC": "As a Company rule...", "RXD": "As a Company rule...", "RXE": "As a Company rule...", "RXG": "As a Company rule..."},
  "RXC": {"RCX": "As a Company rule...", "REX": "As a Company rule...", "RGX": "As a Company rule...", "RXC": "This table is used as the Special Loads Segregation Table...", "RXD": "As a Company rule...", "RXE": "As a Company rule...", "RXG": "As a Company rule..."},
  "RXD": {"RCX": "As a Company rule...", "REX": "As a Company rule...", "RGX": "As a Company rule...", "RXC": "As a Company rule...", "RXD": "This table is used as the Special Loads Segregation Table...", "RXE": "As a Company rule...", "RXG": "As a Company rule..."},
  "RXE": {"RCX": "As a Company rule...", "REX": "As a Company rule...", "RGX": "As a Company rule...", "RXC": "As a Company rule...", "RXD": "As a Company rule...", "RXE": "This table is used as the Special Loads Segregation Table...", "RXG": "As a Company rule..."},
  "RXG": {"RCX": "As a Company rule...", "REX": "As a Company rule...", "RGX": "As a Company rule...", "RXC": "As a Company rule...", "RXD": "As a Company rule...", "RXE": "As a Company rule...", "RXG": "This table is used as the Special Loads Segregation Table..."},
  "RXB": {"RXB": "The minimum segregation distance is used as per IATA rules..."},
  "RBI": {"RBI": "The minimum segregation distance is used as per IATA rules..."},
  "RBM": {"RBM": "The minimum segregation distance is used as per IATA rules..."},
  "RXS": {"RXS": "The minimum segregation distance is used as per IATA rules..."},
  "RFG": {"RFG": "The minimum segregation distance is used as per IATA rules..."},
  "RNG": {"RNG": "The minimum segregation distance is used as per IATA rules..."},
  "RCL": {"RCL": "The minimum segregation distance is used as per IATA rules..."},
  "RPG": {"RPG": "The minimum segregation distance is used as per IATA rules..."},
  "RFL": {"RFL": "The minimum segregation distance is used as per IATA rules..."},
  "RFS": {"RFS": "The minimum segregation distance is used as per IATA rules..."},
  "RSC": {"RSC": "The minimum segregation distance is used as per IATA rules..."},
  "RFW": {"RFW": "The minimum segregation distance is used as per IATA rules..."},
  "ROX": {"ROX": "The minimum segregation distance is used as per IATA rules..."},
  "ROP": {"ROP": "The minimum segregation distance is used as per IATA rules..."},
  "RPB": {"RPB": "The minimum segregation distance is used as per IATA rules..."},
  "RIS": {"RIS": "The minimum segregation distance is used as per IATA rules..."},
  "RRY": {"RRY": "The minimum segregation distance is used as per IATA rules..."},
  "RCM": {"RCM": "The minimum segregation distance is used as per IATA rules..."},
  "ICE": {"ICE": "The minimum segregation distance is used as per IATA rules..."},
  "FIL": {"FIL": "The minimum segregation distance is used as per IATA rules..."},
  "HUM": {"HUM": "The minimum segregation distance is used as per IATA rules..."},
  "EAT": {"PEM": "The minimum segregation distance is used as per IATA rules...", "PES": "The minimum segregation distance is used as per IATA rules...", "PEP": "The minimum segregation distance is used as per IATA rules..."},
  "PEM": {"EAT": "The minimum segregation distance is used as per IATA rules...", "PES": "The minimum segregation distance is used as per IATA rules...", "PEP": "The minimum segregation distance is used as per IATA rules..."},
  "PES": {"EAT": "The minimum segregation distance is used as per IATA rules...", "PEM": "The minimum segregation distance is used as per IATA rules...", "PEP": "The minimum segregation distance is used as per IATA rules..."},
  "PEP": {"EAT": "The minimum segregation distance is used as per IATA rules...", "PEM": "The minimum segregation distance is used as per IATA rules...", "PES": "The minimum segregation distance is used as per IATA rules..."},
  "HEG": {"HEG": "The minimum segregation distance is used as per IATA rules..."},
  "AVI": {"AVI": "The minimum segregation distance is used as per IATA rules..."},
  "LHO": {"LHO": "The minimum segregation distance is used as per IATA rules..."}
};

const App = () => {
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [result, setResult] = useState('');

  const checkCompatibility = () => {
    if (code1.length !== 3 || code2.length !== 3) {
      setResult('Kodlar 3 harfli olmalıdır.');
      return;
    }
    if (compatibilityTable[code1] && compatibilityTable[code1][code2]) {
      setResult(compatibilityTable[code1][code2]);
    } else if (!compatibilityTable[code1] || !compatibilityTable[code2]) {
      setResult('Tabloda yer almamaktadır.');
    } else {
      setResult('Bu iki madde yan yana yüklenebilir.');
    }
  };

  return (
    <div className="table-page segments-page">
      <div className="container">
        <div className="wrap-title">
          <div className="contents left"></div>
        </div>
        <div className="wrap-content b-shadow table-responsive">
          <h5>Special Loads Segregation Table</h5><br />
          <div>
            <label>DG 1: </label>
            <input
              type="text"
              value={code1}
              onChange={(e) => setCode1(e.target.value.toUpperCase().slice(0, 3))}
              maxLength="3"
              style={{ width: '80px' }}
            />
            <label> DG 2: </label>
            <input
              type="text"
              value={code2}
              onChange={(e) => setCode2(e.target.value.toUpperCase().slice(0, 3))}
              maxLength="3"
              style={{ width: '80px' }}
            />
            <button onClick={checkCompatibility} style={{ height: '30px' }}>Check</button>
          </div>
          <br />
          <div className="table table-hover table-bordered mb-0"></div>
          <div>
            <h5>Result: </h5>
            <p>{result}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
